import { useState, useEffect, useMemo, useRef } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Switch, Tab, TablePagination, Tabs, TextField, Typography } from "@mui/material"
import axios, { axiosPrivate } from "../../../../../apis/axios"
import tableStyles from "../../../../../components/Table/Table.module.scss"
import { useTable, usePagination, useAsyncDebounce, useGlobalFilter, useFilters, useSortBy } from "react-table"
import { Button, Input, Modal, SearchBox, Text } from "../../../../components"
import { Add, ArrowRightAlt, FilterAltOutlined, RestartAlt, SearchOutlined, SortByAlpha } from "@mui/icons-material"
import { GeneralPriceListTemplate } from "./GeneralPriceListTemplate"
import { PDFHandler } from "../../../../../utils/PDFHandler"
import { downloadHeaders, downloadWithDataDirectories } from "../../../../../utils/ExcelHandler"
import AdvancedDropDown from "../../../../AdvancedDropDown/AdvancedDropDown"
import CloseButton from "../../../../Modal/CloseButton/CloseButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import moment from "moment"
import { toast } from "react-toastify"
import Loading from "../../../components/Loading/Loading"
import FileUpload from "../../../../FileUpload/FileUpload"
import AsyncSelect from "react-select/async"
import useAuth from "../../../../../hooks/useAuth"
import { saveAs } from "file-saver";


const GeneralPriceList = () => {
    const [data, setData] = useState([]) as any
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [searchVal, setSearchVal] = useState("")
    const [allLocations, setAllLocations] = useState<any>([])
    const [filterLocation, setFilterLocation] = useState<any>(1)

    ///////////////// CreateNew States //////////////////////
    const [showNewEmployeeModal, setShowNewEmployeeModal] = useState(false)
    const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false)
    const [barCode, setBarCode] = useState("")
    const [createNewLocation, setCreateNewLocation] = useState<any>(1)
    const createNewLocationRef = useRef<any>()
    const [serviceItemList, setServiceItemList] = useState<any>([])
    const [serviceItem, setServiceItem] = useState<any>("")
    const [serviceItemName, setServiceItemName] = useState<any>("")
    const createNewServiceItemRef = useRef<any>()
    const [petTypes, setPetTypes] = useState<any>([])
    const [petType, setPetType] = useState<any>("")
    const [petSizes, setPetSizes] = useState<any>([])
    const [petBreeds, setPetBreeds] = useState<any>([])
    const breedRef = useRef<any>()
    const [petBreed, setPetBreed] = useState<any>("")
    const [petSize, setPetSize] = useState<any>("")
    const [duration, setDuration] = useState<any>("")
    const [bitLocationSpecificAdjustment, setBitLocationSpecificAdjustment] = useState(false)
    const [bitDateSpecificAdjustment, setBitDateSpecificAdjustment] = useState(false)
    const [totalRecordsPerLocation, setTotalRecordsPerLocation] = useState<any>(0)
    const [costPrice, setCostPrice] = useState<any>("")
    const [salesPrice, setSalesPrice] = useState<any>("")
    const [effectiveDate, setEffectiveDate] = useState<any>(moment().format("YYYY-MM-DD"))
    const [expiryDate, setExpiryDate] = useState<any>("9999-12-31")

    const [locationSpecificAmount, setLocationSpecificAmount] = useState<any>("")
    const [locationSpecificPrecent, setLocationSpecificPrecent] = useState<any>("")
    const [locationSpecificEffectiveDate, setLocationSpecificEffectiveDate] = useState<any>("")
    const [locationSpecificExpiryDate, setLocationSpecificExpiryDate] = useState<any>("")

    const [dateSpecificAmount, setDateSpecificAmount] = useState<any>("")
    const [dateSpecificPrecent, setDateSpecificPrecent] = useState<any>("")
    const [dateSpecificEffectiveDate, setDateSpecificEffectiveDate] = useState<any>("")
    const [dateSpecificExpiryDate, setDateSpecificExpiryDate] = useState<any>("")

    const [bitTaxableItem, setBitTaxableItem] = useState(false)
    const [previousSalesPrice, setPreviousSalesPrice] = useState("")

    const [bitPromotionFlag, setBitPromotionFlag] = useState(false)
    const [varLinkToPromotionMaterial, setVarLinkToPromotionMaterial] = useState("")
    const [varLinkToGlobalCoupon, setVarLinkToGlobalCoupon] = useState("")
    const [varMessageToEmployee, setVarMessageToEmployee] = useState("")
    const [varPromotionalMessage, setVarPromotionalMessage] = useState("")

    const [salesCommissionPercent, setSalesCommissionPercent] = useState("")
    const [salesCommissionAmount, setSalesCommissionAmount] = useState("")
    const [isAdditional, setIsAdditional] = useState(false)
    const [isActive, setIsActive] = useState(false)

    /////////////////EDIT Sates //////////////////////////////////
    const [generalPriceListID, setGeneralPriceListID] = useState<any>("")
    const editLocationRef = useRef<any>()
    const [editLocation, setEditLocation] = useState<any>(1)
    const [editBarCode, setEditBarCode] = useState<any>("")
    const [editServiceItem, setEditServiceItem] = useState<any>("")
    const editServiceItemRef = useRef<any>()
    const [editServiceItemName, setEditServiceItemName] = useState<any>("")
    const [editDuration, setEditDuration] = useState<any>("")
    const [editCostPrice, setEditCostPrice] = useState<any>("")
    const [editSalesPrice, setEditSalesPrice] = useState<any>("")
    const [editSalesCommissionAmount, setEditSalesCommissionAmount] = useState<any>("")
    const [editSalesCommissionPercent, setEditSalesCommissionPercent] = useState<any>("")
    const [editPreviousSalesPrice, setEditPreviousSalesPrice] = useState<any>("")
    const [editBitTaxableItem, setEditBitTaxableItem] = useState(false)
    const [editIsAdditional, setEditIsAdditional] = useState(false)
    const [editEffectiveDate, setEditEffectiveDate] = useState<any>("")
    const [editExpiryDate, setEditExpiryDate] = useState<any>("")
    const [editBitLocationSpecificAdjustment, setEditBitLocationSpecificAdjustment] = useState(false)
    const [editLocationSpecificPrecent, setEditLocationSpecificPrecent] = useState<any>("")
    const [editLocationSpecificAmount, setEditLocationSpecificAmount] = useState<any>("")
    const [editLocationSpecificEffectiveDate, setEditLocationSpecificEffectiveDate] = useState<any>("")
    const [editLocationSpecificExpiryDate, setEditLocationSpecificExpiryDate] = useState<any>("")
    const [editBitDateSpecificAdjustment, setEditBitDateSpecificAdjustment] = useState<any>(false)
    const [editDateSpecificPrecent, setEditDateSpecificPrecent] = useState<any>("")
    const [editDateSpecificAmount, setEditDateSpecificAmount] = useState<any>("")
    const [editDateSpecificEffectiveDate, setEditDateSpecificEffectiveDate] = useState<any>("")
    const [editDateSpecificExpiryDate, setEditDateSpecificExpiryDate] = useState<any>("")
    const [editBitPromotionFlag, setEditBitPromotionFlag] = useState<any>(false)
    const [editVarLinkToPromotionMaterial, setEditVarLinkToPromotionMaterial] = useState<any>("")
    const [editVarLinkToGlobalCoupon, setEditVarLinkToGlobalCoupon] = useState<any>("")
    const [editVarMessageToEmployee, setEditVarMessageToEmployee] = useState<any>("")
    const [editVarPromotionalMessage, setEditVarPromotionalMessage] = useState<any>("")
    const [editIsActive, setEditIsActive] = useState<any>(false)

    //Filter////
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoadingLocation, setIsLoadingLocation] = useState(false);
    const [groomingLocation, setGroomingLocation] = useState([]) as any;
    const [selectAllLocations, setSelectAllLocations] = useState(false);
    const [selectAllServices, setSelectAllServices] = useState(false);
    const [selectAllTypes, setSelectAllTypes] = useState(false);
    const [selectAllSizes, setSelectAllSizes] = useState(false);
    const [selectAllBreeds, setSelectAllBreeds] = useState(false);
    const [serviceList, setServiceList] = useState([]) as any;
    const [serviceListSelected, setServiceListSelected] = useState([]) as any;
    const [typeList, setTypeList] = useState([]) as any;
    const [typeListSelected, setTypeListSelected] = useState(null) as any;
    const [bathSelected, setBathSelected] = useState(null) as any;
  
    const [breedList, setBreedList] = useState([]) as any;
    const [breedListSelected, setBreedListSelected] = useState([]) as any;
  
    const [sizeList, setSizeList] = useState([]) as any;
    const [sizeListSelected, setSizeListSelected] = useState([]) as any;
  
    const [downloadingData, setDownloadingData] = useState(false);
    const [isLoadingType, setIsLoadingType] = useState(false);
    const [isLoadingBreed, setIsLoadingBreed] = useState(false);
    const [isLoadingSize, setIsLoadingSize] = useState(false);
    const [isLoadingServices, setIsLoadingServices] = useState(false);
    const [openImportExportModal, setOpenImportExportModal] = useState(false)
    const [servicesByType, setServicesByType] = useState([]) as any;
    const { auth } = useAuth() as any

    useEffect(()=>{

        setGroomingLocation([{
          value: auth?.currentLocation?.value,
          label: auth?.currentLocation?.label,
        }])
      },[])

    async function getLocations(signal) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {
            signal: signal,
        })
        return result.data.location
    }

    async function getServiceItems(signal) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`GeneralPrice/GetAllServices`, {
            signal: signal,
        })
        return result.data.Services
    }

    async function getPetTypes(signal) {
        const result = await axiosPrivate.get(`PetType/GetAll`, {
            signal: signal,
        })
        return result.data.petType
    }

    async function getSizes(id) {
        const result = await axiosPrivate.get(`PetSize/GetAllByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    async function getBreedByPetType(id) {
        const result = await axiosPrivate.get(`Breed/GetAllByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    async function getServiceByPetType(id) {
        const result = await axiosPrivate.get(`ServiceItems/GetServiceItemsByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
      };

    useEffect(() => {
        getAdSearchData()
    }, [page, rowsPerPage, groomingLocation])

    useEffect(() => {
        const controller = new AbortController()
        fetchLocationData(controller.signal)
    }, [])

    const fetchLocationData = (signal) => {
        setIsLoadingLocation(true)
        setIsLoadingType(true)
        getLocations(signal)
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            }).finally(() => {
                setIsLoadingLocation(false);
              });
        getServiceItems(signal)
            .then((res) => {
                setServiceItemList(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
        getPetTypes(signal)
            .then((res) => {
                setPetTypes(res)
                setTypeList(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            }).finally(() => {
                setIsLoadingType(false);
              });
    }

    const getSizeData = (id) => {
        getSizes(id)
            .then((res) => {
                setPetSizes(res?.petSize)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getBreedData = (id) => {
        getBreedByPetType(id)
            .then((res) => {
                setPetBreeds(res?.breed)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const createNewItem = () => {
        let payload = {
            generalPriceListID: 0,
            varServiceItemBarCode: "0000",
            serviceItemID: serviceItem,
            locID: createNewLocation,
            dtEffectiveDate: moment(effectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            dtExpiryDate: moment(expiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            numServiceItemPreviousSalesPrice: previousSalesPrice !== "" ? parseFloat(previousSalesPrice) : 0,
            numServiceItemCostPrice: costPrice !== "" ? parseFloat(costPrice) : 0,
            numServiceItemSalesPrice: salesPrice !== "" ? parseFloat(salesPrice) : 0,
            bitTaxableItem: bitTaxableItem,
            bitActivePromotionFlag: bitPromotionFlag,
            varLinkToPromotionMaterial: varLinkToPromotionMaterial,
            varLinkToGlobalCoupon: varLinkToGlobalCoupon,
            varMessageToEmployee: varMessageToEmployee,
            varPromotionalMessage: varPromotionalMessage,
            bitLocationSpecificAdjustment: bitLocationSpecificAdjustment,
            dtLocationSpecificAdjustmentEffectiveDate: locationSpecificEffectiveDate === "" ? null : moment(locationSpecificEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            dtLocationSpecificAdjustmentExpiryDate: locationSpecificExpiryDate === "" ? null : moment(locationSpecificExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            btLocationSpecificAdjustmentPercent: parseFloat(locationSpecificPrecent) > 0 && locationSpecificPrecent !== "" ? true : false,
            numLocationSpecificAdjustmentPercent: locationSpecificPrecent !== "" ? parseFloat(locationSpecificPrecent) : 0,
            btLocationSpecificAdjustmentAmount: parseFloat(locationSpecificAmount) > 0 && locationSpecificAmount !== "" ? true : false,
            numLocationSpecificAdjustmentAmount: locationSpecificAmount !== "" ? parseFloat(locationSpecificAmount) : 0,
            btDateSpecificAdjustment: bitDateSpecificAdjustment,
            dtDateSpecificAdjustmentEffectiveDate: dateSpecificEffectiveDate === "" ? null : moment(dateSpecificEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            dtDateSpecificAdjustmentExpiryDate: dateSpecificExpiryDate === "" ? null : moment(dateSpecificExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            btDateSpecificAdjustmentPercent: parseFloat(dateSpecificPrecent) > 0 && dateSpecificPrecent !== "" ? true : false,
            numDateSpecificAdjustmentPercent: dateSpecificPrecent !== "" ? parseFloat(dateSpecificPrecent) : 0,
            btDateSpecificAdjustmentAmount: parseFloat(dateSpecificAmount) > 0 && dateSpecificAmount !== "" ? true : false,
            numDateSpecificAdjustmentAmount: dateSpecificAmount !== "" ? parseFloat(dateSpecificAmount) : 0,
            btSalesCommissionPercent: parseFloat(salesCommissionPercent) > 0 && salesCommissionPercent !== "" ? true : false,
            numSalesCommissionPercent: salesCommissionPercent !== "" ? parseFloat(salesCommissionPercent) : 0,
            btSalesCommissionAmount: parseFloat(salesCommissionAmount) > 0 && salesCommissionAmount !== "" ? true : false,
            numSalesCommissionAmount: salesCommissionAmount !== "" ? parseFloat(salesCommissionAmount) : 0,
            bitActive: isActive,
            numUserID: 1, //Logged user ID
            bitIsAdditional: isAdditional,
            generalPriceListName: "asdf",
            generalPriceListPetBreedID: petBreed !== "" ? parseFloat(petBreed) : 0,
            generalPriceListPetSizeID: petSize !== "" ? parseFloat(petSize) : 0,
            generalPriceListTime: duration !== "" ? parseFloat(duration) : 0,
            generalPriceListPetTypeID: petType !== "" ? parseFloat(petType) : 0,
        }
        console.log("Payload-sent", payload)
        AddEditItem(payload)
    }

    const EditItem = () => {
        let payload = {
            generalPriceListID: generalPriceListID,
            varServiceItemBarCode: editBarCode,
            serviceItemID: editServiceItem,
            locID: editLocation,
            dtEffectiveDate: moment(editEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            dtExpiryDate: moment(editExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            numServiceItemPreviousSalesPrice: editPreviousSalesPrice !== "" ? parseFloat(editPreviousSalesPrice) : 0,
            numServiceItemCostPrice: editCostPrice !== "" ? parseFloat(editCostPrice) : 0,
            numServiceItemSalesPrice: editSalesPrice !== "" ? parseFloat(editSalesPrice) : 0,
            bitTaxableItem: editBitTaxableItem,
            bitActivePromotionFlag: editBitPromotionFlag,
            varLinkToPromotionMaterial: editVarLinkToPromotionMaterial,
            varLinkToGlobalCoupon: editVarLinkToGlobalCoupon,
            varMessageToEmployee: editVarMessageToEmployee,
            varPromotionalMessage: editVarPromotionalMessage,
            bitLocationSpecificAdjustment: editBitLocationSpecificAdjustment,
            dtLocationSpecificAdjustmentEffectiveDate: editBitLocationSpecificAdjustment ? moment(editLocationSpecificEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            dtLocationSpecificAdjustmentExpiryDate: editBitLocationSpecificAdjustment ? moment(editLocationSpecificExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            btLocationSpecificAdjustmentPercent: editBitLocationSpecificAdjustment ? (parseFloat(editLocationSpecificPrecent) > 0 && editLocationSpecificPrecent !== "" ? true : false) : false,
            numLocationSpecificAdjustmentPercent: editBitLocationSpecificAdjustment ? (editLocationSpecificPrecent !== "" ? parseFloat(editLocationSpecificPrecent) : 0) : 0,
            btLocationSpecificAdjustmentAmount: editBitLocationSpecificAdjustment ? (parseFloat(editLocationSpecificAmount) > 0 && editLocationSpecificAmount !== "" ? true : false) : false,
            numLocationSpecificAdjustmentAmount: editBitLocationSpecificAdjustment ? (editLocationSpecificAmount !== "" ? parseFloat(editLocationSpecificAmount) : 0) : 0,
            btDateSpecificAdjustment: editBitDateSpecificAdjustment,
            dtDateSpecificAdjustmentEffectiveDate: editBitDateSpecificAdjustment ? moment(editDateSpecificEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            dtDateSpecificAdjustmentExpiryDate: editBitDateSpecificAdjustment ? moment(editDateSpecificExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            btDateSpecificAdjustmentPercent: editBitDateSpecificAdjustment ? (parseFloat(editDateSpecificPrecent) > 0 && editDateSpecificPrecent !== "" ? true : false) : false,
            numDateSpecificAdjustmentPercent: editBitDateSpecificAdjustment ? (editDateSpecificPrecent !== "" ? parseFloat(editDateSpecificPrecent) : 0) : 0,
            btDateSpecificAdjustmentAmount: editBitDateSpecificAdjustment ? (parseFloat(editDateSpecificAmount) > 0 && editDateSpecificAmount !== "" ? true : false) : false,
            numDateSpecificAdjustmentAmount: editBitDateSpecificAdjustment ? (editDateSpecificAmount !== "" ? parseFloat(editDateSpecificAmount) : 0) : 0,
            btSalesCommissionPercent: parseFloat(editSalesCommissionPercent) > 0 && editSalesCommissionPercent !== "" ? true : false,
            numSalesCommissionPercent: editSalesCommissionPercent !== "" ? parseFloat(editSalesCommissionPercent) : 0,
            btSalesCommissionAmount: parseFloat(editSalesCommissionAmount) > 0 && editSalesCommissionAmount !== "" ? true : false,
            numSalesCommissionAmount: editSalesCommissionAmount !== "" ? parseFloat(editSalesCommissionAmount) : 0,
            bitActive: editIsActive,
            numUserID: 1, //Logged user ID
            bitIsAdditional: editIsAdditional,
            generalPriceListName: "asdfg",
            generalPriceListPetBreedID: petBreed !== "" ? parseFloat(petBreed) : 0,
            generalPriceListPetSizeID: petSize !== "" ? parseFloat(petSize) : 0,
            generalPriceListTime: editDuration !== "" ? parseFloat(editDuration) : 0,
            generalPriceListPetTypeID: petType !== "" ? parseFloat(petType) : 0,
        }
        console.log("Payload-edit-sent", payload)
        AddEditItem(payload)
    }

    const onBathSelected = () => {
        let payload = {
            generalPriceListID: generalPriceListID,
            varServiceItemBarCode: editBarCode,
            serviceItemID: editServiceItem,
            locID: editLocation,
            dtEffectiveDate: moment(editEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            dtExpiryDate: moment(editExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            numServiceItemPreviousSalesPrice: editPreviousSalesPrice !== "" ? parseFloat(editPreviousSalesPrice) : 0,
            numServiceItemCostPrice: editCostPrice !== "" ? parseFloat(editCostPrice) : 0,
            numServiceItemSalesPrice: editSalesPrice !== "" ? parseFloat(editSalesPrice) : 0,
            bitTaxableItem: editBitTaxableItem,
            bitActivePromotionFlag: editBitPromotionFlag,
            varLinkToPromotionMaterial: editVarLinkToPromotionMaterial,
            varLinkToGlobalCoupon: editVarLinkToGlobalCoupon,
            varMessageToEmployee: editVarMessageToEmployee,
            varPromotionalMessage: editVarPromotionalMessage,
            bitLocationSpecificAdjustment: editBitLocationSpecificAdjustment,
            dtLocationSpecificAdjustmentEffectiveDate: editBitLocationSpecificAdjustment ? moment(editLocationSpecificEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            dtLocationSpecificAdjustmentExpiryDate: editBitLocationSpecificAdjustment ? moment(editLocationSpecificExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            btLocationSpecificAdjustmentPercent: editBitLocationSpecificAdjustment ? (parseFloat(editLocationSpecificPrecent) > 0 && editLocationSpecificPrecent !== "" ? true : false) : false,
            numLocationSpecificAdjustmentPercent: editBitLocationSpecificAdjustment ? (editLocationSpecificPrecent !== "" ? parseFloat(editLocationSpecificPrecent) : 0) : 0,
            btLocationSpecificAdjustmentAmount: editBitLocationSpecificAdjustment ? (parseFloat(editLocationSpecificAmount) > 0 && editLocationSpecificAmount !== "" ? true : false) : false,
            numLocationSpecificAdjustmentAmount: editBitLocationSpecificAdjustment ? (editLocationSpecificAmount !== "" ? parseFloat(editLocationSpecificAmount) : 0) : 0,
            btDateSpecificAdjustment: editBitDateSpecificAdjustment,
            dtDateSpecificAdjustmentEffectiveDate: editBitDateSpecificAdjustment ? moment(editDateSpecificEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            dtDateSpecificAdjustmentExpiryDate: editBitDateSpecificAdjustment ? moment(editDateSpecificExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            btDateSpecificAdjustmentPercent: editBitDateSpecificAdjustment ? (parseFloat(editDateSpecificPrecent) > 0 && editDateSpecificPrecent !== "" ? true : false) : false,
            numDateSpecificAdjustmentPercent: editBitDateSpecificAdjustment ? (editDateSpecificPrecent !== "" ? parseFloat(editDateSpecificPrecent) : 0) : 0,
            btDateSpecificAdjustmentAmount: editBitDateSpecificAdjustment ? (parseFloat(editDateSpecificAmount) > 0 && editDateSpecificAmount !== "" ? true : false) : false,
            numDateSpecificAdjustmentAmount: editBitDateSpecificAdjustment ? (editDateSpecificAmount !== "" ? parseFloat(editDateSpecificAmount) : 0) : 0,
            btSalesCommissionPercent: parseFloat(editSalesCommissionPercent) > 0 && editSalesCommissionPercent !== "" ? true : false,
            numSalesCommissionPercent: editSalesCommissionPercent !== "" ? parseFloat(editSalesCommissionPercent) : 0,
            btSalesCommissionAmount: parseFloat(editSalesCommissionAmount) > 0 && editSalesCommissionAmount !== "" ? true : false,
            numSalesCommissionAmount: editSalesCommissionAmount !== "" ? parseFloat(editSalesCommissionAmount) : 0,
            bitActive: editIsActive,
            numUserID: 1, //Logged user ID
            bitIsAdditional: editIsAdditional,
            generalPriceListName: "asdfg",
            generalPriceListPetBreedID: petBreed !== "" ? parseFloat(petBreed) : 0,
            generalPriceListPetSizeID: petSize !== "" ? parseFloat(petSize) : 0,
            generalPriceListTime: editDuration !== "" ? parseFloat(editDuration) : 0,
            generalPriceListPetTypeID: petType !== "" ? parseFloat(petType) : 0,
        }
        console.log("Payload-bath selected-sent", payload)
 
    }

    const clearAllStates = () => {
        setShowNewEmployeeModal(false)
        setShowEditEmployeeModal(false)
        setBarCode("")
        setServiceItemName("")
        setDuration("")
        setBitLocationSpecificAdjustment(false)
        setBitDateSpecificAdjustment(false)
        setTotalRecordsPerLocation(0)
        setCostPrice("")
        setSalesPrice("")
        setEffectiveDate(moment().format("YYYY-MM-DD"))
        setExpiryDate("9999-12-31")

        setLocationSpecificAmount("")
        setLocationSpecificPrecent("")
        setLocationSpecificEffectiveDate("")
        setLocationSpecificExpiryDate("")

        setDateSpecificAmount("")
        setDateSpecificPrecent("")
        setDateSpecificEffectiveDate("")
        setDateSpecificExpiryDate("")

        setBitTaxableItem(false)
        setPreviousSalesPrice("")

        setBitPromotionFlag(false)
        setVarLinkToPromotionMaterial("")
        setVarLinkToGlobalCoupon("")
        setVarMessageToEmployee("")
        setVarPromotionalMessage("")

        setSalesCommissionPercent("")
        setSalesCommissionAmount("")
        setIsAdditional(false)
        setIsActive(false)

        setGeneralPriceListID("")
        editLocationRef.current?.handleClear()
        setEditLocation(1)
        setEditBarCode("")
        setEditServiceItem("")
        editServiceItemRef.current?.handleClear()
        setEditServiceItemName("")
        setEditDuration("")
        setEditCostPrice("")
        setEditSalesPrice("")
        setEditSalesCommissionAmount("")
        setEditSalesCommissionPercent("")
        setEditPreviousSalesPrice("")
        setEditBitTaxableItem(false)
        setEditIsAdditional(false)
        setEditEffectiveDate("")
        setEditExpiryDate("")
        setEditBitLocationSpecificAdjustment(false)
        setEditLocationSpecificPrecent("")
        setEditLocationSpecificAmount("")
        setEditLocationSpecificEffectiveDate("")
        setEditLocationSpecificExpiryDate("")
        setEditBitDateSpecificAdjustment(false)
        setEditDateSpecificPrecent("")
        setEditDateSpecificAmount("")
        setEditDateSpecificEffectiveDate("")
        setEditDateSpecificExpiryDate("")
        setEditBitPromotionFlag(false)
        setEditVarLinkToPromotionMaterial("")
        setEditVarLinkToGlobalCoupon("")
        setEditVarMessageToEmployee("")
        setEditVarPromotionalMessage("")
        setEditIsActive(false)
    }

    async function GetGeneralPriceListCombinationsPaginated(data) {
        console.log("passedData", data)
        await axios.post(`${process.env.REACT_APP_API_URL}GeneralPrice/GetGeneralPriceListCombinationsPaginated?pageNumber=${ page + 1}&pageSize=${rowsPerPage}`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((result) => {
            if (result.status === 200) {
                console.log(result)
              if (Array.isArray(result?.data) && result?.data?.length === 0) {
                console.log("servicesList is empty");
                setData([])
                setTotalCount(0);
                setTotalRecordsPerLocation(0);
            } else {
                console.log("servicesList is not empty", result?.data?.data);
                setData(result?.data?.data)
                setTotalCount(result?.data?.data?.length);
                setTotalRecordsPerLocation(result?.data?.totalRecords);
            }
                setIsLoading(false);
            }
        })
        .catch((error) => {
            console.log("Error:", error.message);
        });
    }

    const getAdSearchData = () => {
        const data = {
          locationsList: convertToLocationIDFormat(groomingLocation),
          servicesList: convertToServiceIDFormat(serviceListSelected),
          petTypesList: (typeListSelected?.value !== undefined)? [{"typeID": typeListSelected?.value}] : [],
          petBreedsList: convertToBreedIDFormat(breedListSelected),
          petSizesList: convertToSizeIDFormat(sizeListSelected),
          isBathOnly: (bathSelected === null)? null : bathSelected?.value

      };
  
      GetGeneralPriceListCombinationsPaginated(data);
    }

    async function AddEditItem(data) {
        await axiosPrivate
            .post(`GeneralPrice/PopulateGeneralPriceList`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status == 200) {
                    console.log(result)
                    toast.success("Changes successfully made")
                    setShowEditEmployeeModal(false)
                    setShowNewEmployeeModal(false)
                    clearAllStates()
                    getAdSearchData()
                }
            })
            .catch((error: any) => {
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message) 
                } else {
                    console.log("Error:", error.message)
                    toast.error("An error occurred. Please try again later.")
                }
            })
    }

    const ResetFilters = () => {
        setPetType("")
        setPetBreed("")
        setPetSize("")
        setServiceItem("")
        breedRef.current?.handleClear()
        setPetBreeds([])
        setPetSizes([])
        setRowsPerPage(10)
        setPage(0)
        resetAdvFilters()
        setBathSelected(null)

    }

    const editItemTrigger = (index) => {
        console.log("ind", data[index])
        setGeneralPriceListID(data[index]?.GeneralPriceListID)
        setEditBarCode(data[index]?.varServiceItemBarCode)
        setEditServiceItem(data[index]?.ServiceItemID)
        setEditServiceItemName(data[index]?.GeneralPriceListName)
        setEditDuration(data[index]?.GeneralPriceListTime)
        console.log("time", data[index]?.GeneralPriceListTime)
        setEditCostPrice(data[index]?.numServiceItemCostPrice)
        setEditSalesPrice(data[index]?.numServiceItemSalesPrice)
        setEditSalesCommissionAmount(data[index]?.numSalesCommissionAmount)
        setEditSalesCommissionPercent(data[index]?.numSalesCommissionPercent)
        setEditPreviousSalesPrice(data[index]?.numServiceItemPreviousSalesPrice)
        setEditBitTaxableItem(data[index]?.bitTaxableItem)
        setEditIsAdditional(data[index]?.bitIsAdditional)
        setEditDateSpecificEffectiveDate(data[index]?.dtEffectiveDate)

        setEditBitLocationSpecificAdjustment(data[index]?.bitLocationSpecificAdjustment)
        setEditLocationSpecificAmount(data[index]?.numLocationSpecificAdjustmentAmount)
        setEditLocationSpecificPrecent(data[index]?.numLocationSpecificAdjustmentPercent)
        setEditLocationSpecificEffectiveDate(data[index]?.dtLocationSpecificAdjustmentEffectiveDate !== "" ? moment(data[index]?.dtLocationSpecificAdjustmentEffectiveDate).format("YYYY-MM-DD") : null)
        setEditLocationSpecificExpiryDate(data[index]?.dtLocationSpecificAdjustmentExpiryDate !== "" ? moment(data[index]?.dtLocationSpecificAdjustmentExpiryDate).format("YYYY-MM-DD") : null)

        setEditBitDateSpecificAdjustment(data[index]?.btDateSpecificAdjustment)
        setEditDateSpecificAmount(data[index]?.numDateSpecificAdjustmentAmount)
        setEditDateSpecificPrecent(data[index]?.numDateSpecificAdjustmentPercent)
        setEditDateSpecificEffectiveDate(data[index]?.dtDateSpecificAdjustmentEffectiveDate !== "" ? moment(data[index]?.dtDateSpecificAdjustmentEffectiveDate).format("YYYY-MM-DD") : null)
        setEditDateSpecificExpiryDate(data[index]?.dtDateSpecificAdjustmentExpiryDate !== "" ? moment(data[index]?.dtDateSpecificAdjustmentExpiryDate).format("YYYY-MM-DD") : null)

        setEditBitPromotionFlag(data[index]?.bitActivePromotionFlag)
        setEditVarLinkToPromotionMaterial(data[index]?.varLinkToPromotionMaterial)
        setEditVarLinkToGlobalCoupon(data[index]?.varLinkToGlobalCoupon)
        setEditVarMessageToEmployee(data[index]?.varMessageToEmployee)
        setEditVarPromotionalMessage(data[index]?.varPromotionalMessage)
        setEditIsActive(data[index]?.bitActive)

        setPetType(data[index]?.GeneralPriceListPetTypeID)
        setPetBreed(data[index]?.GeneralPriceListPetBreedID)
        setPetSize(data[index]?.GeneralPriceListPetSizeID)

        getSizeData(data[index]?.GeneralPriceListPetTypeID)
        getBreedData(data[index]?.GeneralPriceListPetTypeID)

        setEditEffectiveDate(data[index]?.dtEffectiveDate !== "" ? moment(data[index]?.dtEffectiveDate).format("YYYY-MM-DD") : null)
        setEditExpiryDate(data[index]?.dtExpiryDate !== "" ? moment(data[index]?.dtExpiryDate).format("YYYY-MM-DD") : null)

        setShowEditEmployeeModal(true)
    }

       ////////////Filter

       const filterLocations = (inputValue) => {
        return allLocations.filter((i) =>
          i.varLocationName.toLowerCase().includes(inputValue.toLowerCase())
        );
      };
    
      const loadOptionsLocation = (inputValue, callback) => {
        setTimeout(() => {
          callback(
            filterLocations(inputValue).map((loc) => ({
              label: loc.varLocationName,
              value: loc.numLocationID,
            }))
          );
        }, 1000);
      };
    
      const handleLocationChange = (selectedOptions) => {
        const formattedOptions = selectedOptions.map((loc) => ({
          value: loc.value,
          label: loc.label,
        }));
        setGroomingLocation(formattedOptions);
      };
    
      const handleSelectAllLocationChange = (event) => {
        const selectAll = event.target.checked;
        setSelectAllLocations(selectAll);
    
        if (selectAll) {
          const allOptions = allLocations.map((loc) => ({
            value: loc.numLocationID,
            label: loc.varLocationName,
          }));
          setGroomingLocation(allOptions);
        } else {
          setGroomingLocation([]);
        }
      };

      //Services
      const filterServices = (inputValue) => {
        return servicesByType.filter((i) =>
          i.varServiceItemName.toLowerCase().includes(inputValue.toLowerCase())
        );
      };
    
      const loadOptionsServices = (inputValue, callback) => {
        setTimeout(() => {
          callback(
            filterServices(inputValue).map((loc) => ({
              label: loc.varServiceItemName,
              value: loc.numServiceItemID,
            }))
          );
        }, 1000);
      };
    
      const handleServiceChange = (selectedOptions) => {
        const formattedOptions = selectedOptions.map((loc) => ({
          value: loc.value,
          label: loc.label,
        }));
        setServiceListSelected(formattedOptions);
      };
    
      const handleSelectAllServiceChange = (event) => {
        const selectAll = event.target.checked;
        setSelectAllServices(selectAll);
        if (selectAll) {
          const allOptions = servicesByType.map((loc) => ({
            value: loc.numServiceItemID,
            label: loc.varServiceItemName,
          }));
         
          setServiceListSelected(allOptions);
        } else {
          setServiceListSelected([]);
        }
      };

        const filterTypes = (inputValue) => {
            return typeList.filter((i) =>
              i.varPetTypeName.toLowerCase().includes(inputValue.toLowerCase())
            );
          };

    let isBath = [
        {value: true, label:"Yes"},
        {value: false, label:"No"},
        {value: null, label:"All"}
    ]

        const filterIsBath = (inputValue) => {
        return isBath.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        };

        const loadOptionsBath = (inputValue, callback) => {
            setTimeout(() => {
              callback(
                filterIsBath(inputValue).map((loc) => ({
                  label: loc.label,
                  value: loc.value,
                }))
              );
            }, 1000);
          };
          const handleBathChange = (selectedOption) => {
            if (selectedOption) {
              const formattedOption = {
                value: selectedOption.value,
                label: selectedOption.label,
              };
              setBathSelected(formattedOption);
            } else {
              setBathSelected(null);
            }
          };
          
          const loadOptionsTypes = (inputValue, callback) => {
            setTimeout(() => {
              callback(
                filterTypes(inputValue).map((loc) => ({
                  label: loc.varPetTypeName,
                  value: loc.numPetTypeID,
                }))
              );
            }, 1000);
          };
          
          const handleTypeChange = (selectedOption) => {
            if (selectedOption) {
              const formattedOption = {
                value: selectedOption.value,
                label: selectedOption.label,
              };
              setTypeListSelected(formattedOption);
            } else {
              setTypeListSelected(null);
            }
          };
          

          /////////////////////SIZE////////////////////////////
        const filterSize = (inputValue) => {
            return sizeList.filter((i) =>
            i.varPetSizeName.toLowerCase().includes(inputValue.toLowerCase())
            );
        };

        const loadOptionsSize = (inputValue, callback) => {
            setTimeout(() => {
            callback(
                filterSize(inputValue).map((loc) => ({
                label: loc.varPetSizeName,
                value: loc.numPetSizeID,
                }))
            );
            }, 1000);
        };

        const handleSizeChange = (selectedOptions) => {
            const formattedOptions = selectedOptions.map((loc) => ({
            value: loc.value,
            label: loc.label,
            }));
            setSizeListSelected(formattedOptions);
        };

        const handleSelectAllSizeChange = (event) => {
            const selectAll = event.target.checked;
            setSelectAllSizes(selectAll);

            if (selectAll) {
            const allOptions = sizeList.map((loc) => ({
                value: loc.numPetSizeID,
                label: loc.varPetSizeName,
            }));
            setSizeListSelected(allOptions);
            } else {
            setSizeListSelected([]);
            }
        };

        /////////////////////Breed////////////////////////////
        const filterBreed = (inputValue) => {
            return breedList.filter((i) =>
            i.varBreedName.toLowerCase().includes(inputValue.toLowerCase())
            );
        };

        const loadOptionsBreed = (inputValue, callback) => {
            setTimeout(() => {
            callback(
                filterBreed(inputValue).map((loc) => ({
                label: loc.varBreedName,
                value: loc.numBreedID,
                }))
            );
            }, 1000);
        };

        const handleBreedChange = (selectedOptions) => {
            const formattedOptions = selectedOptions.map((loc) => ({
            value: loc.value,
            label: loc.label,
            }));
            setBreedListSelected(formattedOptions);
        };

        const handleSelectAllBreedChange = (event) => {
            const selectAll = event.target.checked;
            setSelectAllBreeds(selectAll);

            if (selectAll) {
            const allOptions = breedList.map((loc) => ({
                value: loc.numBreedID,
                label: loc.varBreedName,
            }));
            setBreedListSelected(allOptions);
            } else {
            setBreedListSelected([]);
            }
        };
        const customStyles = {
            control: (provided) => ({
              ...provided,
              fontSize: '14px',
            }),
            menu: (provided) => ({
              ...provided,
              fontSize: '14px',
            }),
            option: (provided) => ({
              ...provided,
              fontSize: '14px',
            }),
            multiValue: (provided) => ({
              ...provided,
              fontSize: '14px',
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: '14px',
            }),
          };

          useEffect(() => {
            if (typeListSelected) {
                setSelectAllBreeds(false);
                setBreedListSelected([]);
                setSelectAllSizes(false);
                setSizeListSelected([]);
                setSelectAllServices(false);
                setServiceListSelected([]);
                setIsLoadingBreed(true)
                setIsLoadingSize(true)
                setIsLoadingServices(true)
              getSizes(typeListSelected.value)
                .then((res) => {
                    if(res?.petSize.length > 0){
                        setSizeList(res?.petSize)
                    }else{
                        setSizeList([])
                    }

                    setIsLoadingSize(false)
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoadingSize(false)
                });
          
              getBreedByPetType(typeListSelected.value)
                .then((res) => {
                  if(res?.breed.length > 0){
                    setBreedList(res?.breed)
                }else{
                    setBreedList([])
                }
                setIsLoadingBreed(false)
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoadingBreed(false)
                });
                getServiceByPetType(typeListSelected.value)
                .then((res) => {
                  if(res?.serviceItemsLibrary.length > 0){
                    setServicesByType(res?.serviceItemsLibrary)
                }else{
                    setServicesByType([])
                }
                setIsLoadingServices(false)
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoadingServices(false)
                });
            }
          }, [typeListSelected]);

          const currentDate = new Date();
          const formattedDate = currentDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          });

          const resetAdvFilters = () => {
            setSelectAllBreeds(false);
            setBreedListSelected([]);
            setSelectAllSizes(false);
            setSizeListSelected([]);
            setSelectAllTypes(false);
            setTypeListSelected(null);
            setSelectAllServices(false);
            setServiceListSelected([]);
            setSelectAllLocations(false);
            setGroomingLocation([]);
            setGroomingLocation([{
              value: auth?.currentLocation?.value,
              label: auth?.currentLocation?.label,
            }])
            getAdSearchData()
          };

          async function getFile(data) {
            try {
              const result = await axiosPrivate.post(
                `GeneralPrice/ExportAllCombinationsWithFilters`,
                JSON.stringify(data),
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                  responseType: "blob",
                }
              );
        
              return result.data;
            } catch (error) {
              console.log("Error:", error);
            }
          }

          const convertToLocationIDFormat = (data) => {
             return data.map((item) => ({ locationID: item.value }));
           };
         
           const convertToServiceIDFormat = (data) => {
             return data.map((item) => ({ serviceID: item.value }));
           };
           const convertToSizeIDFormat = (data) => {
             return data.map((item) => ({ sizeID: item.value }));
           };
           const convertToBreedIDFormat = (data) => {
             return data.map((item) => ({ breedID: item.value }));
           };

          const triggerDataDownloadBaseData = () => {
            setDownloadingData(true);
            if (typeListSelected === "") {
              toast.warning("Please select Pet Type to export the data");
              setDownloadingData(false);
            } else {
              let payload = {
                locationsList: convertToLocationIDFormat(groomingLocation),
                servicesList: convertToServiceIDFormat(serviceListSelected),
                petTypesList: [{"typeID": typeListSelected?.value}],
                petBreedsList: convertToBreedIDFormat(breedListSelected),
                petSizesList: convertToSizeIDFormat(sizeListSelected),
                isBathOnly: (bathSelected === null)? null : bathSelected?.value
              };
              console.log("payload", payload)
              setDownloadingData(false);
              getFile(payload)
                .then((res) => {
                  const fileName = `EsyPet Admin Export - ${formattedDate} - 5.6 - General Price List Export.xlsx`;
                  saveAs(res, fileName);
                  setDownloadingData(false);
                })
                .catch((err) => {
                  toast.error("Something went wrong!");
                  setDownloadingData(false);
                  console.log(err);
                });
            }
          };

          async function getHeaders(objectName) {
                const result = await axiosPrivate.get(`${objectName}/GetHeaders`)
                return result.data
        }

          const triggerHeadersDownload = (objectName) => {
            getHeaders(objectName)
                .then((res) => {
                    const createHeaders: {}[] = []

                        const values: any = Object.values(res[objectName.charAt(0).toLowerCase() + objectName.slice(1)])
                        values.map((value: any) => {
                            createHeaders.push({ [value]: "" })
                        })
                    downloadHeaders('5.5 - General Price List', createHeaders)
                })
                .catch((err) => {
                    toast.error("Something went wrong!")
                    console.log(err)
                })
        }

        async function getBaseData(objectName) {
                const result = await axiosPrivate.get(`${objectName}/ExportAllCombinations?LocationID=${filterLocation}`, {
                    responseType: "blob",
                })
                return result.data
            
        }

        async function getData(objectName) {
                const result = await axiosPrivate.get(`${objectName}/ExportAll?LocationID=${filterLocation}`, {
                    responseType: "blob",
                })
                return result.data
        }

        const triggerDataDownloadBaseDataBulk = (objectName) => {
            setDownloadingData(true)
            getBaseData(objectName)
                .then((res) => {
                        const fileName = `EsyPet Admin Export - ${formattedDate} - 5.5 - General Price List All combinations Export.xlsx`
                        saveAs(res, fileName)
                        setDownloadingData(false)
                })
                .catch((err) => {
                    toast.error("Something went wrong!")
                    setDownloadingData(false)
                    console.log(err)
                })
        }

        const triggerDataDownload = (objectName) => {

            console.log("objectName", objectName)
            setDownloadingData(true)
            getData(objectName)
                .then((res) => {
                        const fileName = `EsyPet Admin Export - ${formattedDate} - 5.5 - General Price List Export.xlsx.xlsx`
                        saveAs(res, fileName)
                        setDownloadingData(false)
                    
                })
                .catch((err) => {
                    toast.error("Something went wrong!")
                    setDownloadingData(false)
                    console.log(err)
                })
        }

    const RenderTable = ({
        columns,
        data,
        tableName,
        hiddenColumns,
        addNew,
        downloadPDF,
        newAction,
        loadingData,
        objectName,
        rowClicked,
        searchFilter,
        hideSort,
        pdfColumns,
        pageNumber,
        pageSize,
        locationChange,
        selectedLocation,
    }) => {
        const [showFiltering, setShowFiltering] = useState(false)
        const [searchVal, setSearchVal] = useState("")
        const [filteredRows, setFilteredRows] = useState([])
        const [location, setLocation] = useState(selectedLocation)
        const myRef = useRef<any>()


        let exportData: any = []

        const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
            return (
                <input
                    className={tableStyles.searchFilter}
                    value={filterValue || ""}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined)
                    }}
                    placeholder={`Search`}
                />
            )
        }

        const defaultColumn = useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        )

        const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, rows, setFilter, setGlobalFilter, setAllFilters } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: {
                    pageIndex: 0,
                    hiddenColumns: hiddenColumns,
                    pageSize: pageSize,
                },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )
        useEffect(() => {
            if (!showFiltering) {
                setAllFilters([])
            }
        }, [showFiltering])

        useEffect(() => {
            filterGlobalData(searchVal)
        }, [searchVal])

        const filterData = useAsyncDebounce((column, filter) => {
            setFilter(column, filter)
        }, 200)

        useEffect(() => {
            setFilteredRows(rows)
        }, [rows])

        useEffect(() => {
            filterGlobalData(searchFilter)
        }, [searchFilter])

        const filterGlobalData = useAsyncDebounce((filter) => {
            setGlobalFilter(filter)
        }, 200)

        const appendForExport = () => {
            exportData = []
            let appended = new Promise((resolve, reject) => {
                filteredRows.map((row: any) => {
                    let obj = {}
                    Object.keys(row.original).map((header) => {
                        if (pdfColumns.includes(header.toString())) {
                            obj[header] = row.original[header]
                        }
                    })
                    exportData.push(obj)
                })
                console.log("EX", exportData)
                resolve(true)
            })

            appended
                .then((result) => {
                    if (result) {
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        const exportExcel = () => {
            appendForExport()
            downloadWithDataDirectories(tableName, exportData)
        }

        const exportPDF = () => {
            appendForExport()
            PDFHandler(exportData, tableName)
        }

        useEffect(() => {
            if (selectedLocation !== "") {
                setLocation(selectedLocation)
            }
        }, [selectedLocation])

        const [isGrid, setIsGrid] = useState(false)


        return loadingData ? (
            <Loading isGrid={isGrid} activateGrid={false} downloadPDF={downloadPDF} headerGroups={headerGroups} hideSort={hideSort} newAction={newAction} objectName={objectName} rowHeight={50} />
        ) : (
            <>
                <div className={tableStyles.headerContainer}>
                    <div className={tableStyles.actionContainer}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <div style={{ width: "30%" }}>
    
                            </div>
                            <div className={tableStyles.optionsContainer}>
                                <Button color="default" variant="outlined" onClick={() => exportExcel()}>
                                    Download XLSX
                                </Button>
                                {downloadPDF ? (
                                    <Button color="default" variant="outlined" onClick={() => exportPDF()}>
                                        Download PDF
                                    </Button>
                                ) : null}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    // onClick={() => exportExcel()}
                                    onClick={() => setOpenImportExportModal(!openImportExportModal)}
                                >
                                    Import/Export
                                </Button>
                            </div>
                        </div>

                        <div className={tableStyles.filtersContainer}>
                            <div>
                                <Text color="p_900" weight="fw_400">
                                    {`${rows.length} ${rows.length > 1 ? "results" : "result"}`} found
                                </Text>
                            </div>
                            <div className={tableStyles.searchContainer}>
                                <SearchBox onChange={(e) => setSearchVal(e)} value={searchVal} />
                                <Button color="primary" variant="contained" onClick={() => setShowFiltering(!showFiltering)}>
                                    <FilterAltOutlined />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <table className={`${tableStyles.table} ${hideSort ? tableStyles.hideSort : null}`} {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                {column.render("Header")}
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <SortByAlpha {...column.getHeaderProps(column.getSortByToggleProps())} style={{ height: "16px", cursor: "pointer", color: "#005df1" }} />
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <ArrowRightAlt className={tableStyles.sortDesc} />
                                                        ) : (
                                                            <ArrowRightAlt className={tableStyles.sortAsc} />
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {showFiltering ? <div style={{ padding: ".5rem 0" }}>{column.canFilter ? column.render("Filter") : null}</div> : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr className={tableStyles.tableRow} {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <>
                                                {row.cells.length === index + 1 ? (
                                                    <td onClick={() => {}} className={tableStyles.tableData} {...cell.getCellProps()}>
                                                        {cell.render("Cell")}{" "}
                                                    </td>
                                                ) : (
                                                    <td className={tableStyles.tableData} {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                )}
                                            </>
                                        )
                                    })}
                                    <td>
                                        <Button
                                            color="transparent"
                                            variant="outlined"
                                            onClick={() => {
                                                rowClicked(i)
                                            }}
                                            type={"submit"}
                                        >
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    component="div"
                    count={totalRecordsPerLocation} // Assuming there are 100 items
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />



                {/* <Modal open={openImportExportModal}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column" }}>
                        <CloseButton onClick={() => setOpenImportExportModal(!openImportExportModal)} />
                        <div style={{ backgroundColor: "#fff", width: "100%" }}>
                            <ImportExportHandler
                                objectName={"GeneralPrice"}
                                requestType="Custom"
                                customFilter={filterLocation}
                                type={"Custom"}
                                isSuccess={(e) => {
                                    if (e === true) {
                                        fetchData()
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal> */}
            </>
        )
    }

    return (
        <div>
                <Modal open={openImportExportModal}>
                        <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                            flexDirection: "column",
                            width: "1600px",
                        }}
                        >
                        <div
                            style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderRadius: "10px",
                            }}
                        >
                            <div>
                            <div
                                style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                }}
                            >
                                <CloseButton onClick={() => setOpenImportExportModal(false)} />
                            </div>
                            </div>
                            <div
                            style={{
                                padding: "20px",
                                backgroundColor: "#F9FAFB",
                                maxHeight: "750px",
                                minHeight: "400px",
                                overflowY: "scroll",
                            }}
                            >
                            <Box sx={{ width: "100%" }}>
                                <Tabs
                                value={tabIndex}
                                onChange={handleChange}
                                aria-label="export import tabs"
                                >
                                <Tab label="Export" />
                                <Tab label="Import" />
                                </Tabs>
                                {tabIndex === 0 && (
                                <Box sx={{ p: 3 }}>
                                    <p
                                    style={{
                                        color: "#050576",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        marginBottom: "20px",
                                        borderBottom: "1px solid #cdcdcd",
                                    }}
                                    >
                                    {" "}
                                    Download template data with advanced filters
                                    </p>

                                    <div style={{display:"flex"}}>
                                    <p style={{marginLeft:"673px", fontWeight:"600"}}>Select all</p>
                                    </div>
                                    <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                    >
                                    <div style={{ width: "50%" }}>
                                        {allLocations !== undefined && allLocations !== null ? (
                                        <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                        }}
                                        >
                                        {isLoadingLocation ? (
                                            <div>...</div>
                                        ) : (
                                            <div style={{ width: "90%" }}>
                                            <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsLocation}
                                                onChange={handleLocationChange}
                                                defaultOptions
                                                value={groomingLocation}
                                                placeholder={"Select Locations"}
                                                styles={customStyles}
                                            />
                                            </div>
                                        )}
                                        <Checkbox
                                            checked={selectAllLocations}
                                            onChange={handleSelectAllLocationChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                        />
                                        </div>
                                        ) : null}
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        {typeList !== undefined && typeList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingType ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadOptionsTypes}
                                                    onChange={handleTypeChange}
                                                    defaultOptions
                                                    value={typeListSelected}
                                                    placeholder={"Select Types"}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            )}
                                         <div></div>
                                        </div>
                                        ) : null}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        marginBottom: "10px",
                                    }}
                                    >
                                  <div style={{ width: "50%" }}>
                                        {sizeList !== undefined && sizeList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingSize ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsSize}
                                                onChange={handleSizeChange}
                                                defaultOptions
                                                value={sizeListSelected}
                                                placeholder={"Select Sizes"}
                                                styles={customStyles}
                                                />
                                            </div>
                                            )}
                                            <Checkbox
                                            checked={selectAllSizes}
                                            onChange={handleSelectAllSizeChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </div>
                                        ) : null}
                                    </div>                                                        
                                    <div style={{ width: "50%" }}>
                                        {breedList !== undefined && breedList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingBreed ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsBreed}
                                                onChange={handleBreedChange}
                                                defaultOptions
                                                value={breedListSelected}
                                                placeholder={"Select Breeds"}
                                                styles={customStyles}
                                                />
                                            </div>
                                            )}
                                            <Checkbox
                                            checked={selectAllBreeds}
                                            onChange={handleSelectAllBreedChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </div>
                                        ) : null}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        marginBottom: "10px",
                                    }}
                                    >
                                <div style={{ width: "50%" }}>
                                        {serviceList !== undefined && serviceList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingServices ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsServices}
                                                onChange={handleServiceChange}
                                                defaultOptions
                                                value={serviceListSelected}
                                                placeholder={"Select Services"}
                                                styles={customStyles}
                                                />
                                            </div>
                                            )}
                                            <Checkbox
                                            checked={selectAllServices}
                                            onChange={handleSelectAllServiceChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </div>
                                        ) : null}
                                    </div> 
                                    <div style={{ width: "50%" }}>
                                        {typeList !== undefined && typeList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingType ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadOptionsBath}
                                                    onChange={handleBathChange}
                                                    defaultOptions
                                                    value={bathSelected}
                                                    placeholder={"Is Bath Pets"}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            )}
                                         <div></div>
                                        </div>
                                        ) : null}
                                    </div>
                                    </div>
                                    <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        justifyContent: "end",
                                    }}
                                    >
                                    <Button
                                        loading={downloadingData}
                                        onClick={() => resetAdvFilters()

                                        }
                                        variant="outlined"
                                        color="default"
                                    >
                                        Reset Filters
                                    </Button>
                                    <Button
                                        loading={downloadingData}
                                        onClick={() => 
                                            triggerDataDownloadBaseData()
                                        }
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Download file
                                    </Button>
                                    </div>

                                    <p
                                    style={{
                                        color: "#050576",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        marginBottom: "20px",
                                        marginTop: "10px",
                                        borderBottom: "1px solid #cdcdcd",
                                    }}
                                    >
                                    {" "}
                                    
                                    </p>

                                    <div style={{display:"flex", gap:"10px"}}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                        triggerHeadersDownload("GeneralPrice");
                                        }}
                                        loading={downloadingData}
                                    >
                                        Download headers
                                    </Button>
                                    <Button loading={downloadingData} onClick={() => triggerDataDownloadBaseDataBulk("GeneralPrice")} variant="outlined" color="default">
                                        Download template with All Combinations
                                    </Button>
                                    <Button loading={downloadingData} onClick={() => {
                                        triggerDataDownload("GeneralPrice")
                                    }} variant="contained" color="secondary">
                                        Download template with System Data
                                    </Button>
                                    </div>
                                </Box>
                                )}
                                {tabIndex === 1 && (
                                <Box sx={{ p: 3 }}>
                                    <div
                                    style={{
                                    height:"300px"
                                    }}
                                    >
                                    <FileUpload
                                        requestType={"Custom"}
                                        type="upload"
                                        objectType={"GeneralPrice"}
                                        text="Upload Data File"
                                        isSuccess={(e) => {
                                        if (e === true) {
                                            getAdSearchData()
                                        }
                                        }}
                                    />
                                    </div>
                                </Box>
                                )}
                            </Box>
                            </div>
                        </div>
                        </div>
                    </Modal>

            <Modal open={showNewEmployeeModal}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "850px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Add New Item</p>
                        <CloseButton onClick={() => setShowNewEmployeeModal(false)} />
                    </div>

                    <div style={{ backgroundColor: "#fff", width: "100%", padding: "10px", display: "flex", flexDirection: "column", gap: "10px", height: "700px", overflowY: "scroll" }}>
                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            {allLocations !== undefined && allLocations !== null ? (
                                <div style={{ width: "50%" }}>
                                    {" "}
                                    {/* Adjust width as needed */}
                                    <AdvancedDropDown
                                        data={allLocations.sort((a, b) => a.numLocationID - b.numLocationID).map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                        onChange={(e) => {
                                            setCreateNewLocation(e?.value)
                                        }}
                                        ref={createNewLocationRef}
                                        placeHolder={"Location"}
                                        passByID={true}
                                        value={createNewLocation}
                                    />
                                </div>
                            ) : null}
                            {/* <div style={{ width: "50%" }}> 
                                        <TextField
                                            variant="filled"
                                            style={{ width: "100%" }}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {"Bar Code"}
                                                </div>
                                            }
                                            required={true}
                                            onChange={(e) => {
                                                e.stopPropagation(); 
                                                setBarCode(e.target.value);
                                            }}
                                            type={"advanced"}
                                            value={barCode}
                                            disabled={true}
                                        />
                                    </div> */}
                            {serviceItemList !== undefined && serviceItemList !== null ? (
                                <div style={{ width: "50%" }}>
                                    <AdvancedDropDown
                                        data={serviceItemList.map((i) => ({ label: i.varServiceItemName, value: i.numServiceItemID }))}
                                        onChange={(e) => {
                                            setServiceItem(e?.value)
                                        }}
                                        ref={createNewServiceItemRef}
                                        placeHolder={"Service Items"}
                                        passByID={true}
                                        value={serviceItem}
                                    />
                                </div>
                            ) : null}
                        </div>

                        {/* <div style={{width:"100%", justifyContent:"space-between", display:"flex", gap:"10px", alignItems:"center"}}>
                                    {serviceItemList !== undefined && serviceItemList !== null ? (
                                            <div style={{ width: "50%" }}>
                                                <AdvancedDropDown
                                                    data={serviceItemList.map((i) => ({ label: i.varServiceItemName, value: i.numServiceItemID }))}
                                                    onChange={(e) => {
                                                        setServiceItem(e?.value)
                                                    }}
                                                    ref={createNewServiceItemRef}
                                                    placeHolder={"Service Items"}
                                                    passByID={true}
                                                    value={serviceItem}
                                                />
                                            </div>
                                            ) : null} 
                                        <div style={{ width: "50%" }}>
                                        <TextField
                                            variant="filled"
                                            style={{ width: "100%" }}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {"Item Name"}
                                                    <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                        **
                                                    </span>
                                                </div>
                                            }
                                            required={true}
                                            onChange={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                                setServiceItemName(e.target.value);
                                            }}
                                            type={"advanced"}
                                            value={serviceItemName}
                                        />
                                    </div>
                                </div> */}

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            {petTypes !== undefined && petTypes !== null ? (
                                <div style={{ width: "50%" }}>
                                    <Input
                                        value={petType}
                                        label={"Pets Type"}
                                        items={petTypes.map((i) => ({ text: i.varPetTypeName, value: i.numPetTypeID }))}
                                        onChange={(e) => {                              
                                                setPetType(e)
                                                getSizeData(e)
                                                getBreedData(e)                       
                                                setPetBreeds([])
                                                setPetSizes([])                          
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div style={{ width: "50%" }}>
                                {petBreeds !== undefined && petBreeds !== null ? (
                                    <AdvancedDropDown
                                        data={petBreeds.map((i) => ({ label: i.varBreedName, value: i.numPetBreedID }))}
                                        onChange={(e) => {
                                            setPetBreed(e?.value)
                                        }}
                                        ref={breedRef}
                                        placeHolder={"Breeds"}
                                        isDisabled={petBreeds.length > 0 ? undefined : true}
                                        passByID={petBreed}
                                    />
                                ) : null}
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            {petSizes !== undefined && petSizes !== null ? (
                                <div style={{ width: "50%" }}>
                                    <Input
                                        value={petSize}
                                        label={"Pets Size"}
                                        disabled={petSizes.length > 0 ? undefined : true}
                                        items={petSizes.map((i) => ({ text: i.varPetSizeName, value: i.numPetSizeID }))}
                                        onChange={(e) => {
                                            setPetSize(e)
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Duration"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setDuration(e.target.value)
                                    }}
                                    type={"number"}
                                    value={duration}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Cost Price"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setCostPrice(e.target.value)
                                    }}
                                    type={"number"}
                                    value={costPrice}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Sales Price"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setSalesPrice(e.target.value)
                                    }}
                                    type={"number"}
                                    value={salesPrice}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Sales Commission Amount"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setSalesCommissionAmount(e.target.value)
                                    }}
                                    type={"number"}
                                    value={salesCommissionAmount}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Sales Commission Percent"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setSalesCommissionPercent(e.target.value)
                                    }}
                                    type={"number"}
                                    value={salesCommissionPercent}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Previous Sales Price"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setPreviousSalesPrice(e.target.value)
                                    }}
                                    type={"number"}
                                    value={previousSalesPrice}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <FormControlLabel
                                    control={<Switch checked={bitTaxableItem} onChange={(e) => setBitTaxableItem(e.target.checked)} />}
                                    labelPlacement="end" // Adjust label placement
                                    label={
                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Taxable Item</span> // Customize label style
                                    }
                                />
                                <FormControlLabel
                                    control={<Switch checked={isAdditional} onChange={(e) => setIsAdditional(e.target.checked)} />}
                                    labelPlacement="end" // Adjust label placement
                                    label={
                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Is Additional</span> // Customize label style
                                    }
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Effective Date"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEffectiveDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={effectiveDate}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Expiry Date"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setExpiryDate(e.target.value)
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    type={"date"}
                                    value={expiryDate}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={bitLocationSpecificAdjustment} onChange={(e) => setBitLocationSpecificAdjustment(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Location Specific Adjustment</span> // Customize label style
                                }
                            />
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Percentage"}
                                            {bitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitLocationSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setLocationSpecificPrecent(e.target.value)
                                    }}
                                    type={"number"}
                                    value={locationSpecificPrecent}
                                    disabled={!bitLocationSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Amount"}
                                            {bitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitLocationSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setLocationSpecificAmount(e.target.value)
                                    }}
                                    type={"number"}
                                    value={locationSpecificAmount}
                                    disabled={!bitLocationSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Effective Date"}
                                            {bitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitLocationSpecificAdjustment}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setLocationSpecificEffectiveDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={locationSpecificEffectiveDate}
                                    disabled={!bitLocationSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Expiry Date"}
                                            {bitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitLocationSpecificAdjustment}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setLocationSpecificExpiryDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={locationSpecificExpiryDate}
                                    disabled={!bitLocationSpecificAdjustment}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={bitDateSpecificAdjustment} onChange={(e) => setBitDateSpecificAdjustment(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Date Specific Adjustment</span> // Customize label style
                                }
                            />
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Percentage"}
                                            {bitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitDateSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setDateSpecificPrecent(e.target.value)
                                    }}
                                    type={"number"}
                                    value={dateSpecificPrecent}
                                    disabled={!bitDateSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Amount"}
                                            {bitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitDateSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setDateSpecificAmount(e.target.value)
                                    }}
                                    type={"number"}
                                    value={dateSpecificAmount}
                                    disabled={!bitDateSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Effective Date"}
                                            {bitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitDateSpecificAdjustment}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setDateSpecificEffectiveDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={dateSpecificEffectiveDate}
                                    disabled={!bitDateSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    InputLabelProps={{ shrink: true }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Expiry Date"}
                                            {bitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitDateSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setDateSpecificExpiryDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={dateSpecificExpiryDate}
                                    disabled={!bitDateSpecificAdjustment}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={bitPromotionFlag} onChange={(e) => setBitPromotionFlag(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Promotion Flag</span> // Customize label style
                                }
                            />
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Link To Promotion Material"}
                                            {bitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setVarLinkToPromotionMaterial(e.target.value)
                                    }}
                                    type={"text"}
                                    value={varLinkToPromotionMaterial}
                                    disabled={!bitPromotionFlag}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Link To Global Coupon"}
                                            {bitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setVarLinkToGlobalCoupon(e.target.value)
                                    }}
                                    type={"text"}
                                    value={varLinkToGlobalCoupon}
                                    disabled={!bitPromotionFlag}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Message To Employee"}
                                            {bitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setVarMessageToEmployee(e.target.value)
                                    }}
                                    type={"text"}
                                    value={varMessageToEmployee}
                                    disabled={!bitPromotionFlag}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Promotional Message"}
                                            {bitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={bitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setVarPromotionalMessage(e.target.value)
                                    }}
                                    type={"text"}
                                    value={varPromotionalMessage}
                                    disabled={!bitPromotionFlag}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Is Active</span> // Customize label style
                                }
                            />
                        </div>
                    </div>

                    <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                        <Button variant="contained" color="primary" onClick={() => createNewItem()} text="Create New" />
                    </div>
                </div>
            </Modal>

            <Modal open={showEditEmployeeModal}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "850px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Edit Item</p>
                        <CloseButton onClick={() => setShowEditEmployeeModal(false)} />
                    </div>

                    <div style={{ backgroundColor: "#fff", width: "100%", padding: "10px", display: "flex", flexDirection: "column", gap: "10px", height: "700px", overflowY: "scroll" }}>
                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            {allLocations !== undefined && allLocations !== null ? (
                                <div style={{ width: "50%" }}>
                                    {" "}
                                    {/* Adjust width as needed */}
                                    <AdvancedDropDown
                                        data={allLocations.sort((a, b) => a.numLocationID - b.numLocationID).map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                        onChange={(e) => {
                                            setEditLocation(e?.value)
                                        }}
                                        ref={editLocationRef}
                                        placeHolder={"Location"}
                                        passByID={true}
                                        value={editLocation}
                                    />
                                </div>
                            ) : null}
                            {/* <div style={{ width: "50%" }}>
                                        <TextField
                                            variant="filled"
                                            style={{ width: "100%" }}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {"Bar Code"}
                                                    <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                        **
                                                    </span>
                                                </div>
                                            }
                                            required={true}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setEditBarCode(e.target.value);
                                            }}
                                            type={"advanced"}
                                            value={editBarCode}
                                        />
                                    </div> */}
                            {serviceItemList !== undefined && serviceItemList !== null ? (
                                <div style={{ width: "50%" }}>
                                    <AdvancedDropDown
                                        data={serviceItemList.map((i) => ({ label: i.varServiceItemName, value: i.numServiceItemID }))}
                                        onChange={(e) => {
                                            setEditServiceItem(e?.value)
                                        }}
                                        ref={editServiceItemRef}
                                        placeHolder={"Service Items"}
                                        passByID={true}
                                        value={editServiceItem}
                                        isDisabled={true}
                                    />
                                </div>
                            ) : null}
                        </div>

                        {/* <div style={{width:"100%", justifyContent:"space-between", display:"flex", gap:"10px", alignItems:"center"}}>
                                    {serviceItemList !== undefined && serviceItemList !== null ? (
                                            <div style={{ width: "50%" }}>
                                                <AdvancedDropDown
                                                    data={serviceItemList.map((i) => ({ label: i.varServiceItemName, value: i.numServiceItemID }))}
                                                    onChange={(e) => {
                                                        setEditServiceItem(e?.value)
                                                    }}
                                                    ref={editServiceItemRef}
                                                    placeHolder={"Service Items"}
                                                    passByID={true}
                                                    value={editServiceItem}
                                                    isDisabled={true}
                                                />
                                            </div>
                                            ) : null} 
                                        <div style={{ width: "50%" }}>
                                        <TextField
                                            variant="filled"
                                            style={{ width: "100%" }}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {"Item Name"}
                                                    <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                        **
                                                    </span>
                                                </div>
                                            }
                                            required={true}
                                            onChange={(e) => {
                                                e.stopPropagation(); // Stop event propagation
                                                setEditServiceItemName(e.target.value);
                                            }}
                                            type={"advanced"}
                                            value={editServiceItemName}
                                        />
                                    </div>
                                </div> */}

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            {petTypes !== undefined && petTypes !== null ? (
                                <div style={{ width: "50%" }}>
                                    <Input
                                        value={petType}
                                        label={"Pets Type"}
                                        items={petTypes.map((i) => ({ text: i.varPetTypeName, value: i.numPetTypeID }))}
                                        disabled={true}
                                        onChange={(e) => {
                                                setPetType(e)
                                                getSizeData(e)
                                                getBreedData(e)
                                                setPetBreeds([])
                                                setPetSizes([])
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div style={{ width: "50%" }}>
                                {petBreeds !== undefined && petBreeds !== null ? (
                                    <AdvancedDropDown
                                        data={petBreeds.map((i) => ({ label: i.varBreedName, value: i.numPetBreedID }))}
                                        onChange={(e) => {
                                            setPetBreed(e?.value)
                                        }}
                                        ref={breedRef}
                                        placeHolder={"Breeds"}  
                                        isDisabled={true}
                                        passByID={true}
                                        value={petBreed}
                                    />
                                ) : null}
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            {petSizes !== undefined && petSizes !== null ? (
                                <div style={{ width: "50%" }}>
                                    <Input
                                        value={petSize}
                                        label={"Pets Size"}
                                        disabled={true}
                                        items={petSizes.map((i) => ({ text: i.varPetSizeName, value: i.numPetSizeID }))}
                                        onChange={(e) => {
                                            setPetSize(e)
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Duration"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditDuration(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editDuration}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Cost Price"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditCostPrice(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editCostPrice}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Sales Price"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditSalesPrice(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editSalesPrice}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Sales Commission Amount"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditSalesCommissionAmount(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editSalesCommissionAmount}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Sales Commission Percent"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditSalesCommissionPercent(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editSalesCommissionPercent}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Previous Sales Price"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditPreviousSalesPrice(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editPreviousSalesPrice}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <FormControlLabel
                                    control={<Switch checked={editBitTaxableItem} onChange={(e) => setEditBitTaxableItem(e.target.checked)} />}
                                    labelPlacement="end" // Adjust label placement
                                    label={
                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Taxable Item</span> // Customize label style
                                    }
                                />
                                <FormControlLabel
                                    control={<Switch checked={editIsAdditional} onChange={(e) => setEditIsAdditional(e.target.checked)} />}
                                    labelPlacement="end" // Adjust label placement
                                    label={
                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Is Additional</span> // Customize label style
                                    }
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Effective Date"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditEffectiveDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={editEffectiveDate}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Expiry Date"}
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                        </div>
                                    }
                                    required={true}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditExpiryDate(e.target.value)
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    type={"date"}
                                    value={editExpiryDate}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={editBitLocationSpecificAdjustment} onChange={(e) => setEditBitLocationSpecificAdjustment(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Location Specific Adjustment</span> // Customize label style
                                }
                            />
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Percentage"}
                                            {editBitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitLocationSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditLocationSpecificPrecent(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editLocationSpecificPrecent}
                                    disabled={!editBitLocationSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Amount"}
                                            {editBitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitLocationSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditLocationSpecificAmount(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editLocationSpecificAmount}
                                    disabled={!editBitLocationSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Effective Date"}
                                            {editBitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitLocationSpecificAdjustment}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditLocationSpecificEffectiveDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={editLocationSpecificEffectiveDate}
                                    disabled={!editBitLocationSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Expiry Date"}
                                            {editBitLocationSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitLocationSpecificAdjustment}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditLocationSpecificExpiryDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={editLocationSpecificExpiryDate}
                                    disabled={!editBitLocationSpecificAdjustment}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={editBitDateSpecificAdjustment} onChange={(e) => setEditBitDateSpecificAdjustment(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Date Specific Adjustment</span> // Customize label style
                                }
                            />
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Percentage"}
                                            {editBitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitDateSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditDateSpecificPrecent(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editDateSpecificPrecent}
                                    disabled={!editBitDateSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Adjustment Amount"}
                                            {editBitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitDateSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditDateSpecificAmount(e.target.value)
                                    }}
                                    type={"number"}
                                    value={editDateSpecificAmount}
                                    disabled={!editBitDateSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Effective Date"}
                                            {editBitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitDateSpecificAdjustment}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditDateSpecificEffectiveDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={editDateSpecificEffectiveDate}
                                    disabled={!editBitDateSpecificAdjustment}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    InputLabelProps={{ shrink: true }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Expiry Date"}
                                            {editBitDateSpecificAdjustment ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitDateSpecificAdjustment}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditDateSpecificExpiryDate(e.target.value)
                                    }}
                                    type={"date"}
                                    value={editDateSpecificExpiryDate}
                                    disabled={!editBitDateSpecificAdjustment}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={editBitPromotionFlag} onChange={(e) => setEditBitPromotionFlag(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Promotion Flag</span> // Customize label style
                                }
                            />
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Link To Promotion Material"}
                                            {editBitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditVarLinkToPromotionMaterial(e.target.value)
                                    }}
                                    type={"text"}
                                    value={editVarLinkToPromotionMaterial}
                                    disabled={!editBitPromotionFlag}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Link To Global Coupon"}
                                            {editBitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditVarLinkToGlobalCoupon(e.target.value)
                                    }}
                                    type={"text"}
                                    value={editVarLinkToGlobalCoupon}
                                    disabled={!editBitPromotionFlag}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", justifyContent: "space-between", display: "flex", gap: "10px", alignItems: "center" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Message To Employee"}
                                            {editBitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditVarMessageToEmployee(e.target.value)
                                    }}
                                    type={"text"}
                                    value={editVarMessageToEmployee}
                                    disabled={!editBitPromotionFlag}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                <TextField
                                    variant="filled"
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            {"Promotional Message"}
                                            {editBitPromotionFlag ? (
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>**</span>
                                            ) : null}
                                        </div>
                                    }
                                    required={editBitPromotionFlag}
                                    onChange={(e) => {
                                        e.stopPropagation() // Stop event propagation
                                        setEditVarPromotionalMessage(e.target.value)
                                    }}
                                    type={"text"}
                                    value={editVarPromotionalMessage}
                                    disabled={!editBitPromotionFlag}
                                />
                            </div>
                        </div>

                        <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                            <FormControlLabel
                                control={<Switch checked={editIsActive} onChange={(e) => setEditIsActive(e.target.checked)} />}
                                labelPlacement="end" // Adjust label placement
                                label={
                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "black" }}>Is Active</span> // Customize label style
                                }
                            />
                        </div>
                    </div>

                    <div style={{ textAlign: "end", width: "100%", backgroundColor: "#EFF5FF", padding: "1rem" }}>
                        <Button variant="contained" color="primary" onClick={() => EditItem()} text="Save Changes" />
                    </div>
                </div>
            </Modal>

            <Accordion expanded={true} style={{ marginBottom: "10px", background: "#f3f3f3", borderRadius: "8px" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                    <Typography style={{ fontWeight: "600" }}>Advanced Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>


                                    <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                    >
                                    <div style={{ width: "50%" }}>
                                        {allLocations !== undefined && allLocations !== null ? (
                                        <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                        }}
                                        >
                                        {isLoadingLocation ? (
                                            <div>...</div>
                                        ) : (
                                            <div style={{ width: "90%" }}>
                                            <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsLocation}
                                                onChange={handleLocationChange}
                                                defaultOptions
                                                value={groomingLocation}
                                                placeholder={"Select Locations"}
                                                styles={customStyles}
                                            />
                                            </div>
                                        )}
                                        <Checkbox
                                            checked={selectAllLocations}
                                            onChange={handleSelectAllLocationChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                        />
                                        </div>
                                        ) : null}
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        {typeList !== undefined && typeList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingType ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadOptionsTypes}
                                                    onChange={handleTypeChange}
                                                    defaultOptions
                                                    value={typeListSelected}
                                                    placeholder={"Select Types"}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            )}
                                         <div></div>
                                        </div>
                                        ) : null}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        marginBottom: "10px",
                                    }}
                                    >
                                  <div style={{ width: "50%" }}>
                                        {sizeList !== undefined && sizeList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingSize ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsSize}
                                                onChange={handleSizeChange}
                                                defaultOptions
                                                value={sizeListSelected}
                                                placeholder={"Select Sizes"}
                                                styles={customStyles}
                                                />
                                            </div>
                                            )}
                                            <Checkbox
                                            checked={selectAllSizes}
                                            onChange={handleSelectAllSizeChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </div>
                                        ) : null}
                                    </div>                                                        
                                    <div style={{ width: "50%" }}>
                                        {breedList !== undefined && breedList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingBreed ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsBreed}
                                                onChange={handleBreedChange}
                                                defaultOptions
                                                value={breedListSelected}
                                                placeholder={"Select Breeds"}
                                                styles={customStyles}
                                                />
                                            </div>
                                            )}
                                            <Checkbox
                                            checked={selectAllBreeds}
                                            onChange={handleSelectAllBreedChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </div>
                                        ) : null}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        marginBottom: "10px",
                                    }}
                                    >
                                <div style={{ width: "50%" }}>
                                        {serviceList !== undefined && serviceList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingServices ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={loadOptionsServices}
                                                onChange={handleServiceChange}
                                                defaultOptions
                                                value={serviceListSelected}
                                                placeholder={"Select Services"}
                                                styles={customStyles}
                                                />
                                            </div>
                                            )}
                                            <Checkbox
                                            checked={selectAllServices}
                                            onChange={handleSelectAllServiceChange}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </div>
                                        ) : null}
                                    </div> 
                                    <div style={{ width: "50%" }}>
                                        {typeList !== undefined && typeList !== null ? (
                                        <div
                                            style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            }}
                                        >
                                            {isLoadingType ? (
                                                <div>...</div>
                                            ) : (
                                            <div style={{ width: "90%" }}>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadOptionsBath}
                                                    onChange={handleBathChange}
                                                    defaultOptions
                                                    value={bathSelected}
                                                    placeholder={"Is Bath Pets"}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            )}
                                         <div></div>
                                        </div>
                                        ) : null}
                                    </div>
                                    </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", gap: "10px" }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                getAdSearchData()
                            }}
                            text="Search"
                            iconRight={<SearchOutlined />}
                        />
                        <Button variant="outlined" color="default" onClick={() => ResetFilters()} text="Reset Filters" iconRight={<RestartAlt />} />
                    </div>
                </AccordionDetails>
            </Accordion>

            <div style={{ marginBottom: "10px" }}>
                <Button color="default" variant="outlined" onClick={() => setShowNewEmployeeModal(true)}>
                    <Add style={{ fontSize: "20px" }} />
                    New Price List Item
                </Button>
            </div>

            <RenderTable
                addNew={true}
                downloadPDF={true}
                tableName={GeneralPriceListTemplate?.tableName}
                hiddenColumns={GeneralPriceListTemplate?.hiddenColumns}
                columns={GeneralPriceListTemplate?.template}
                data={data}
                loadingData={isLoading}
                newAction={""}
                objectName={"General Price List"}
                rowClicked={(clickedId) => {
                    editItemTrigger(clickedId)
                }}
                searchFilter={searchVal}
                hideSort={GeneralPriceListTemplate?.hideSort}
                pdfColumns={GeneralPriceListTemplate.pdfTemplate}
                pageNumber={page}
                pageSize={rowsPerPage}
                locationChange={(locID) => {
                        setFilterLocation(locID)
                        setCreateNewLocation(locID)
                }}
                selectedLocation={filterLocation}
            />
        </div>
    )
}

export default GeneralPriceList
