/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "numPetColorID": 1,
    "varPetColorName": "Mix",
    "varPetColorDescription": null,
    "bitPetColorActive": null
*/

export const PetColor: {} = {
    tableName: "1.4 - Pet Colors",
    hiddenColumns: ["numPetColorID"],
    pdfTemplate: ["varPetColorName", "varPetColorDescription", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varPetColorName",
        },
        {
            Header: "Description",
            accessor: "varPetColorDescription",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numPetColorID",
            accessor: "numPetColorID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numPetColorID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varPetColorName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetColorDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
