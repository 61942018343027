import React, { useCallback, useEffect, useState } from "react"
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import {
    Eventcalendar,
    getJson,
    setOptions,
    CalendarNav,
    momentTimezone,
    SegmentedGroup,
    SegmentedItem,
    CalendarPrev,
    CalendarToday,
    CalendarNext,
    Popup,
    Button,
    MbscCalendarEvent,
} from "@mobiscroll/react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import moment from "moment-timezone"
import { addMinutes, calculateWeeklyTiming, formatDateDisplay } from "../../../../../utils/TimeHandler"
import axios from "axios"
import { axiosPrivate } from "../../../../../apis/axios"
import { Box, ButtonGroup, Grid, IconButton, Stack, Tooltip } from "@mui/material"
import useAuth from "../../../../../hooks/useAuth"
import { ArrowBack, ArrowForward, ArrowForwardIos, CheckCircle, ContentCut, DarkMode, LightMode, Person, Pets, Replay, ReportProblem, Shower } from "@mui/icons-material"
import { Avatar, Chip } from "../../../../../components"
import CancelModal from "../../../../../components/ExtModals/CancelModal"
import CheckOutModal from "../../../../../components/ExtModals/CheckOutModal"
import GroomerNote from "../../../../../components/ExtModals/GroomerNote"
import CheckInModal from "../../../../../components/ExtModals/CheckInModal"
import VaccineModal from "../../../../../components/ExtModals/VaccineModal"
import Steps from "../../../../../TabletView/pages/Components/Steps/Steps"
import ViewSummary from "./ViewSummary"
import { Modal } from "../../../../../components/components"
import CloseButton from "../../../../../components/Modal/CloseButton/CloseButton"

momentTimezone.moment = moment

const AllCalendarBooking = (props: { slotList: any; viewSummary: CallableFunction; location: any; customer: any; pet: any; employee: any; intervals: number; toDate: any; fromDate: any }) => {
    setOptions({
        theme: "ios",
        themeVariant: "light",
    })

    const { auth } = useAuth() as any

    const [view, setView] = React.useState("month")
    // const [myEvents, setEvents] = React.useState([]) as any
    const [myEvents, setEvents] = useState([]) as any
    const [colorsList, setColorsList] = React.useState([]) as any

    const [resources, setResources] = useState([]) as any
    const [viewSummaryModal, setViewSummaryModal] = useState(false)

    const [timeInterval, setTimeInterval] = useState(10)

    const [eventSelectionList, setEventSelectionList] = useState([]) as any

    const [calView, setCalView] = React.useState({
        calendar: { labels: true },
    })

    async function getEventsByTemplate(employeeID) {
        const result = await axiosPrivate.get(`Schedule/GetTimeSlotsByEmployeeID?LocationID=${props?.location === 0 ? "" : props?.location}&EmployeeID=${employeeID === 0 ? "" : employeeID}`)
        console.log("tempaltes list", result.data)
        return result.data
    }

    async function getAvailableEvent(employeeID, locationID) {
        const result = await axiosPrivate.get(`Schedule/GetAllEventsByLocationAndEmployee?LocationID=${locationID === 0 ? "" : locationID}&EmployeeID=${employeeID}`)
        console.log("available events", result.data)

        return result.data
    }

    const getSelectedTemplateEvents = (employeeID) => {
        return getEventsByTemplate(employeeID)
            .then((response) => {
                let res = response

                const createEvents = res.map((event, index) => {
                    return {
                        start: event?.StartTime,
                        end: event?.EndTime,
                        title: event?.EventName,
                        background: event?.EventColor,
                        resource: event?.EmployeeID,
                    }
                })

                console.log("colors", createEvents)

                return {
                    colors: createEvents,
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })

        // getAvailableEvent(employeeID, props?.location)
        //     .then((response) => {
        //         let res = response
        //         console.log("response events", response)

        //         setEventSelectionList(res)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         toast.error("Something went wrong!")
        //     })
    }

    const getEvents = async (employee) => {
        const allResults = await Promise.all(employee.map((emp) => getSelectedTemplateEvents(emp?.employeeID)))
        console.log("all", allResults)
        const allColors = allResults.flatMap((result) => result?.colors || [])
        setColorsList(allColors)
    }

    useEffect(() => {
        if (props?.slotList.length > 0) {
            getEvents(props?.slotList)

            const tempEmp = [] as any
            props?.slotList.map((emp) => {
                tempEmp.push({ id: emp.employeeID, name: emp.name })
            })
            console.log("resources list", tempEmp)
            setResources(tempEmp)
        } else {
            setResources([])
        }

        console.log("all slots in cal", props?.slotList)
        getAllSlots(props?.slotList)
    }, [props?.slotList])

    useEffect(() => {
        console.log("ressss", resources)
    }, [resources])

    async function getAllSlots(allTimes) {
        let allAppointments = [] as any

        if (allTimes.length > 0) {
            console.log("fulllist appointments", allTimes)

            await axios
                .get(`${process.env.REACT_APP_API_URL}Appointment/GetAllScheduleAppointmnets`)
                .then((result) => {
                    if (result.status === 200) {
                        console.log("All-Appoinments - RAW", result)

                        result?.data.Appointments.map((app) => {
                            let startTming = calculateWeeklyTiming(app.dtApptStartTime)
                            let endTming = addMinutes(startTming, app.dtApptEndTime)

                            let objPure = {
                                start: app.dtApptStartTime,
                                end: app.dtApptEndTime,
                                title: `#${app.bookingId}: ${app.PetName} - ${app.varCustomerFirstName + " " + app.varCustomerLastName} - ${app.Services[0].varServiceItemName} | ${formatDateDisplay(
                                    app.dtApptStartTime
                                )} - ${formatDateDisplay(app.dtApptEndTime)} `,
                                color: "#f40000",
                                id: app.numAppointmentID,
                                resource: app.EmployeeID,
                                resourceData: {
                                    status: "booked",
                                },
                            }
                            allAppointments.push(objPure)
                        })

                        allTimes.map((emps) => {
                            emps?.dates.map((dateOne) => {
                                dateOne?.slots.map((days, index) => {
                                    console.log("debug c", days)

                                    const earliestSlot = days?.slots?.reduce((earliest, slot) => (new Date(slot.start) < new Date(earliest.start) ? slot : earliest), days?.slots[0])
                                    const latestSlot = days?.slots?.reduce((latest, slot) => (new Date(slot.end) > new Date(latest.end) ? slot : latest), days?.slots[0])

                                    let employeeEvent = {
                                        start: calculateWeeklyTiming(earliestSlot.start),
                                        end: calculateWeeklyTiming(latestSlot.end),
                                        title: `Available`,
                                        color: "#0df400",
                                        id: `${index + 1}=${emps?.employeeID}=${days?.date}`,
                                        resource: emps?.employeeID,
                                        resourceData: {
                                            status: "available",
                                            date: days?.date,
                                            employee_id: emps?.employeeID,
                                            EmployeeStartTime: earliestSlot.start,
                                            EmployeeEndTime: latestSlot.end,
                                            slotDetails: days,
                                        },
                                    }

                                    allAppointments.push(employeeEvent)
                                })
                            })
                        })
                    }
                    console.log("last apps", allAppointments)
                    setEvents(allAppointments)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setEvents([])
        }
    }

    const [viewSummary, setViewSummary] = useState(false)
    const [paymentCustomerDetails, setPaymentCustomerDetails] = useState<any>({})
    const [summaryDetails, setSummaryDetails] = useState([]) as any

    async function getAppointmentInfo(bookingID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}Appointment/GetAppointmnetByID?AppointmentID=${bookingID}`)
            .then((result) => {
                if (result.status === 200) {
                    setSummaryDetails(result.data.Appointment)
                    console.log("summary", result.data.Appointment)
                    setPaymentCustomerDetails({
                        numCustomerID: result.data.Appointment.Customer.numCustomerID,
                        varSalutation: result.data.Appointment.Customer.varSalutation,
                        varCustomerFirstName: result.data.Appointment.Customer.varCustomerFirstName,
                        varCustomerLastName: result.data.Appointment.Customer.varCustomerLastName,
                    })
                    setViewSummary(true)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const getAppInfo = (bookingID) => {
        getAppointmentInfo(bookingID)
    }

    const deleteEvent = (id) => {
        console.log("Delete Evnt", { id: id, status: true })
        let ev = myEvents.find((appointment) => appointment.resourceData.numAppointmentID === id)
        console.log("Eventsss", ev)

        console.log("dealer id", ev?.resourceData?.DealerBookingID)

        let updateOriginalGroomer = [
            {
                bookingID: ev?.resourceData?.DealerBookingID,
                timeSlotID: ev?.resourceData?.EmployeeTimeSlotID,
                serviceID: ev?.resourceData?.numServiceItemID,
                startTime: ev?.resourceData?.DealerStartTime,
                endTime: ev?.resourceData?.DealerEndTime,
                appointmentID: ev?.resourceData?.numAppointmentID,
                isBather: true,
                userID: 1,
            },
        ]
        axios
            .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateBooking`, updateOriginalGroomer)
            .then(function (response) {
                console.log("app_success", response)

                if (response.status === 200) {
                    console.log("Appointment updated saved updateOriginalGroomer!")
                }
            })
            .catch(function (error) {
                console.log(error)
                console.log("Appointment updated failed!")
            })
    }

    const changeView = (event) => {
        let calView

        switch (event.target.value) {
            case "year":
                calView = {
                    calendar: { type: "year", timeCellStep: props?.intervals, timeLabelStep: props?.intervals },
                    schedule: {
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "month":
                calView = {
                    calendar: { labels: true, timeCellStep: props?.intervals, timeLabelStep: props?.intervals },
                    schedule: {
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "week":
                calView = {
                    schedule: { type: "week", timeCellStep: props?.intervals, timeLabelStep: props?.intervals, startTime: "06:00", endTime: "19:00" },
                }
                break
            case "day":
                calView = {
                    schedule: {
                        type: "day",
                        startTime: "06:00",
                        endTime: "19:00",
                        timeCellStep: props?.intervals,
                        timeLabelStep: props?.intervals,
                    },
                }
                break
        }

        setView(event.target.value)
        setCalView(calView)
    }
    const [darkMode, setDarkMode] = useState(false)

    const customWithNavButtons = () => {
        return (
            <React.Fragment>
                <CalendarNav className="cal-header-nav" />
                <div className="cal-header-picker">
                    <SegmentedGroup value={view} onChange={changeView}>
                        <SegmentedItem value="year">Year</SegmentedItem>
                        <SegmentedItem value="month">Month</SegmentedItem>
                        <SegmentedItem value="week">Week</SegmentedItem>
                        <SegmentedItem value="day">Day</SegmentedItem>
                    </SegmentedGroup>
                </div>
                <CalendarPrev className="cal-header-prev" />
                <CalendarToday className="cal-header-today" />
                <CalendarNext className="cal-header-next" />
                <IconButton color="primary" onClick={() => setDarkMode(!darkMode)}>
                    {darkMode ? <DarkMode style={{ fontSize: "20px" }} /> : <LightMode style={{ fontSize: "20px" }} />}
                </IconButton>
            </React.Fragment>
        )
    }

    const [isOpen, setOpen] = React.useState(false)
    const [anchor, setAnchor] = React.useState<any>(null)
    const [closeOnOverlay, setCloseOnOverlay] = React.useState(false)
    const [statusLoading, setStatusLoading] = React.useState(false)
    const [currentEvent, setCurrentEvent] = React.useState(null)
    const timerRef = React.useRef<any>(null)

    const [eventStatus, setEventStatus] = React.useState("") as any
    const [time, setTime] = React.useState("") as any
    const [serviceName, setServiceName] = React.useState("") as any
    const [petName, setPetName] = React.useState("") as any
    const [customer, setCustomer] = React.useState([]) as any
    const [services, setServices] = React.useState([]) as any
    const [getSlotDetails, setGetSlotDetails] = React.useState({}) as any
    const [appID, setAppID] = React.useState("") as any
    const [eventAppointmentID, setEventAppointmentID] = React.useState("") as any
    const [startTime, setStartTime] = React.useState("") as any
    const [endTime, setEndTime] = React.useState("") as any
    const [currGroomer, setCurrGroomer] = React.useState("") as any
    const [currBather, setCurrBather] = React.useState("") as any
    const [currentAppointmentStatus, setCurrentAppointmentStatus] = React.useState("") as any
    const [sameBatherGroomer, setSameBatherGroomer] = React.useState(false) as any

    const [selectedEvents, setSelectedEvents] = useState([]) as any

    useEffect(() => {
        console.log("selectedEvents", selectedEvents)
    }, [selectedEvents])

    const openTooltip = React.useCallback(
        (args, closeOption) => {
            const event = args.event

            const resource = myEvents.find((evt) => evt.id === event.id)
            // const time = formatDate('hh:mm A', new Date(event.start)) + ' - ' + formatDate('hh:mm A', new Date(event.end));

            setCurrentEvent(event)
            // setTime(resource?.resourceData?.date)
            // // console.log("timeee", resource?.resource?.serviceItemsLibraries[0])
            // setServiceName(resource?.resourceData?.serviceItemsLibraries.length > 0 ? resource?.resourceData?.serviceItemsLibraries[0]?.varServiceItemName : "Not Set")
            // setPetName(resource?.resourceData?.petName)
            // setCurrentAppointmentStatus(resource?.resourceData?.currentStatus)
            setStartTime(resource?.start)
            setEndTime(resource?.end)
            setEventStatus(resource?.resourceData?.status)
            setGetSlotDetails(resource?.resourceData?.slotDetails)
            // setCustomer(resource?.resourceData?.customerName)
            // setServices(resource?.resourceData?.serviceItemsLibraries)
            setAppID(resource?.id)
            // setEventAppointmentID(resource?.resourceData?.numAppointmentID)
            // setCurrGroomer(resource?.resourceData?.currentGroomer)
            // setCurrBather(resource?.resourceData?.currentBather)
            // setSameBatherGroomer(resource?.resourceData?.sameGroomerBather)

            // if (event.confirmed) {
            //     setStatus('Confirmed');
            //     setButtonText('Cancel appointment');
            //     setButtonType('warning');
            // } else {
            //     setStatus('Canceled');
            //     setButtonText('Confirm appointment');
            //     setButtonType('success');
            // }

            // setBgColor('resource.color');
            // setInfo('event.title' + ', Age: ' + 'event.age');

            // setReason('event.reason');
            // setLocation('event.location');

            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }

            setAnchor(args.domEvent.currentTarget || args.domEvent.target)
            setCloseOnOverlay(closeOption)
            setOpen(true)
        },
        [myEvents]
    )

    const eventDeleted = (e) => {
        console.log("eventtt", e)
    }

    const onEventClick = (e) => {
        console.log("eventtt", e)
    }

    const assignPrev = (event) => {
        let tempEmpArr = resources.map((e) => e.id)
        console.log("ress list", resources, tempEmpArr.indexOf(event?.resource))

        console.log(event?.id, myEvents)

        if (tempEmpArr.indexOf(event?.resource) === 0) {
            toast.warning("first element")
        } else {
            let tmpAllEvents = [...myEvents] as any

            tmpAllEvents?.map((evt, index) => {
                if (evt?.id === event?.id) {
                    tmpAllEvents[index].resource = resources[tempEmpArr.indexOf(event?.resource) - 1]?.id
                }
            })

            setEvents(tmpAllEvents)
        }
    }

    const assignNext = (event) => {
        let tempEmpArr = resources.map((e) => e.id)
        console.log("ress list", resources, tempEmpArr.indexOf(event?.resource))
        console.log(event?.id, myEvents)

        if (resources?.length - 1 === tempEmpArr.indexOf(event?.resource)) {
            toast.warning("last element")
        } else {
            let tmpAllEvents = [...myEvents] as any

            tmpAllEvents?.map((evt, index) => {
                if (evt?.id === event?.id) {
                    tmpAllEvents[index].resource = resources[tempEmpArr.indexOf(event?.resource) + 1]?.id
                }
            })

            setEvents(tmpAllEvents)
        }
    }

    const selfAssignBath = (id) => {
        let e = myEvents.find((appointment) => appointment.resourceData.numAppointmentID === id)
        console.log("authhh", auth)
        console.log("eventtt", e)

        let dtApptStartTime = e?.start
        let dtApptEndTime = e?.end

        let startTime = moment(dtApptStartTime)
        let endTime = moment(dtApptEndTime)

        let batherStartingTime = moment(e?.resourceData?.EmployeeStartTime)
        let batherEndingTime = moment(e?.resourceData?.EmployeeEndTime)

        let dealerStartingTime = moment(e?.resourceData?.DealerStartTime)
        let dealerEndingTime = moment(e?.resourceData?.DealerEndTime)

        console.log("batherStartingTime", batherStartingTime, batherEndingTime)
        console.log("dealerStartingTime", dealerStartingTime, dealerEndingTime)

        let batherAddPayload = [
            {
                bookingID: e?.resourceData?.DealerBookingID,
                timeSlotID: 29,
                serviceID: e?.resourceData?.numServiceItemID,
                startTime: dealerStartingTime.format("YYYY-MM-DDTHH:mm:ss"),
                endTime: dealerEndingTime.format("YYYY-MM-DDTHH:mm:ss"),
                appointmentID: e?.resourceData?.numAppointmentID,
                isBather: true,
                userID: 1,
            },
        ]

        let groomerAddPayload = [
            {
                bookingID: e?.resourceData?.EmployeeBookingID,
                timeSlotID: e?.resourceData?.EmployeeTimeSlotID,
                serviceID: e?.resourceData?.numServiceItemID,
                startTime: batherStartingTime.format("YYYY-MM-DDTHH:mm:ss"),
                endTime: batherEndingTime.format("YYYY-MM-DDTHH:mm:ss"),
                appointmentID: e?.resourceData?.numAppointmentID,
                isBather: false,
                userID: 1,
            },
        ]

        console.log("payload", groomerAddPayload, batherAddPayload)

        axios
            .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateBooking`, batherAddPayload)
            .then(function (response) {
                console.log("app_success", response)

                if (response.status === 200) {
                    console.log("Appointment updated saved batherAddPayload!")
                    axios
                        .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateBooking`, groomerAddPayload)
                        .then(function (response) {
                            console.log("app_success", response)
                            if (response.status === 200) {
                                console.log("Appointment updated saved groomerAddPayload!")
                                toast.success("Appointment updated saved!")
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            console.log("Appointment updated failed!")
                        })
                }
            })
            .catch(function (error) {
                console.log(error)
                console.log("Appointment updated failed!")
            })
    }

    const handleSelectedEventsChange = (selected) => {
        setSelectedEvents(selected.events)
    }

    const onEventHoverIn = React.useCallback(
        (args, closeOption) => {
            openTooltip(args, false)
        },
        [openTooltip]
    )

    const onEventHoverOut = React.useCallback(() => {
        timerRef.current = setTimeout(() => {
            setOpen(false)
        }, 200)
    }, [])

    const onMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
    }, [])

    const onMouseLeave = React.useCallback(() => {
        timerRef.current = setTimeout(() => {
            setOpen(false)
        }, 200)
    }, [])

    async function getAppStatus(appID) {
        const result = await axiosPrivate.get(`Appointment/GetAppointmentStatusByID?AppointmentID=${appID.split("-")[0]}`)
        return result.data
    }

    const getCurrentAppointmentStatus = (appID) => {
        setStatusLoading(true)
        getAppStatus(appID)
            .then((res) => {
                console.log("app curr status", res[0]?.apptStatus)
                setCurrentAppointmentStatus(res[0]?.apptStatus)
                setStatusLoading(false)
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Appointment status update failed!!")
                setStatusLoading(false)
            })
    }

    return (
        <div style={{ borderRadius: "10px" }}>
            <ToastContainer />
            <Eventcalendar
                themeVariant={darkMode ? "dark" : "light"}
                resources={resources}
                eventDelete={true}
                renderHeader={customWithNavButtons}
                height={800}
                view={calView}
                data={myEvents}
                cssClass="md-switching-view-cont"
                onEventHoverIn={onEventHoverIn}
                onEventHoverOut={onEventHoverOut}
                dataTimezone="America/Chicago"
                displayTimezone="America/Chicago"
                onEventDeleted={eventDeleted}
                timezonePlugin={momentTimezone}
                colors={colorsList}
                onEventClick={onEventClick}
                onSelectedEventsChange={handleSelectedEventsChange}
            />

            <Modal open={viewSummaryModal} borderless={true}>
                <CloseButton onClick={() => setViewSummaryModal(false)} />
                <ViewSummary id={appID} />
            </Modal>

            <Popup display="anchored" isOpen={isOpen} anchor={anchor} touchUi={false} showOverlay={false} contentPadding={false} closeOnOverlayClick={closeOnOverlay} width={400} cssClass="md-tooltip">
                <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <div className="md-tooltip-header" style={{ backgroundColor: "white", padding: "10px 15px", display: "flex", justifyContent: "space-between" }}>
                        <span className="md-tooltip-name-age" style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                            {services.map((service) => {
                                return <Chip variant="outlined" fontWeight={600} size="medium" fontColor={"#000"} label={service.varServiceItemName} />
                            })}
                        </span>
                        <span className="md-tooltip-time">
                            {eventStatus === "booked" ? (
                                <Chip fontWeight={600} size="medium" customBackgroundColor={"#3498db"} customBorder={"#3498db"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Booked"} />
                            ) : (
                                <Chip fontWeight={600} size="medium" customBackgroundColor={"#34db44"} customBorder={"#3498db"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Available"} />
                            )}
                        </span>
                    </div>
                    <div className="md-tooltip-info" style={{ padding: "0 15px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "5px 0" }}>
                            <p style={{ color: "#343434", fontWeight: "600" }}>Start: {formatDateDisplay(startTime)}</p>
                            <p style={{ color: "#343434", fontWeight: "600" }}>End: {formatDateDisplay(endTime)}</p>
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)", gridColumnGap: "2px" }}>
                            <div style={{ marginBottom: "5px", borderRadius: "5px 0 0 5px", padding: "5px", backgroundColor: "#fff", display: "flex", alignItems: "center", gap: "6px" }}>
                                <div>
                                    <p style={{ margin: 0, fontWeight: 600 }}>Services Included</p>
                                    <span>{customer}</span>
                                </div>
                            </div>
                        </div>

                        <ButtonGroup style={{ margin: "4px 0 8px 0" }}>
                            <Button color="primary" variant="standard">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {eventStatus === "booked" ? (
                                        <p
                                            style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff", padding: "6px 0" }}
                                            onClick={() => {
                                                setViewSummaryModal(true)
                                            }}
                                        >
                                            View
                                        </p>
                                    ) : (
                                        <p style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1em", color: "#fff", padding: "6px 0" }} onClick={() => console.log("slotdeets")}>
                                            Make Reservation
                                        </p>
                                    )}
                                </div>
                            </Button>
                        </ButtonGroup>

                        {/*TODO: Add condition for user type === bather the can undertake*/}
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default AllCalendarBooking
