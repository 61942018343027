
import React, { useEffect, useState } from "react"
import { Button, Modal } from "../components"
import CloseButton from "../Modal/CloseButton/CloseButton"
import { Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from "@mui/material"
import { AdvancedInput } from "../AdvancedInput/AdvancedInput"
import axios, { axiosPrivate } from "../../apis/axios"
import { toast } from "react-toastify"

import StepperNotes from "./StepperNotes"


function GroomerNote(props: { isOpenGroomerNoteModal; setIsOpenGroomerNoteModal: CallableFunction; bookingDetails; type; NoteDetails?; isSuccess: CallableFunction, isEdit? }) {

     const [noteData, setNoteData] = useState([]) as any


    useEffect(() => {
        if (props.isOpenGroomerNoteModal === true) {
            if (props.type === "copy") {
                GetGroomerNotesByNoteID(props.NoteDetails.numGroomerNoteID)
            } else {
                console.log("add")
            }
        }
    }, [props.isOpenGroomerNoteModal])

    async function GetGroomerNotesByNoteID(noteID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}Appointment/GetGroomerNotesByNoteID?NoteID=${noteID}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("app-note", result.data)
                    setNoteData(result.data)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    return (
        <div>
            <Modal open={props.isOpenGroomerNoteModal}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column", width: "700px" }}>
                    <div style={{ backgroundColor: "#fff", maxWidth: "800px", width: "100%", borderRadius: "10px" }}>
                        <div style={{ padding: "20px" }}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <p style={{ fontWeight: 600, fontSize: "20px", width: "100%" }}>Groomer Note</p>
                                <CloseButton onClick={() => props.setIsOpenGroomerNoteModal(false)} />      
                            </div>
                        </div>

                        <div style={{ backgroundColor: "#F9FAFB" }}>
                            {/* <div style={{ textAlign: "center", width: "100%" }}>
                                <ImageMapper src={outline} map={MAP} onClick={handleClick} width={550} />
                            </div> */}
                            <StepperNotes noteData={noteData} type={props?.type} bookingDetails={props.bookingDetails} listNote={props.NoteDetails} isEdit={props.isEdit}  
                            isSuccess={(e) => {
                                if (e === true) {
                                    props.isSuccess(true)
                                    props.setIsOpenGroomerNoteModal(false)
                                }
                            }} />
                           
                        </div>
             
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default GroomerNote
