import { Delete } from "@mui/icons-material"
import { Checkbox, IconButton } from "@mui/material"
import { useState } from "react"
import { axiosPrivate } from "../../apis/axios"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

const ImportExportReset = () => {
    const [libraryList, setLibraryList] = useState([
        {
            tableName: "All Libraries",
            label: "* - All Libraries",
        },
        {
            tableName: "PetType",
            label: "1.1 - Pet Type Data",
        },
        {
            tableName: "PetSize",
            label: "1.2 - Pet Size Data",
        },
        {
            tableName: "Personality",
            label: "1.3 - Personalities Data",
        },
        {
            tableName: "PetColor",
            label: "1.4 - Pet Colors",
        },
        {
            tableName: "MedicalCondition",
            label: "1.5 - Medical Conditions Data",
        },
        {
            tableName: "Style",
            label: "1.6 - Styles Data",
        },
        {
            tableName: "PetBreed",
            label: "1.7 - Breeds Data",
        },
        {
            tableName: "Breed",
            label: "1.8 - Breed Types Data",
        },
        {
            tableName: "divider",
            label: "divider",
        },
        {
            tableName: "AddressType",
            label: "2.1 - Address Type Data",
        },
        {
            tableName: "ContactType",
            label: "2.2 - Contact Type Data",
        },
        {
            tableName: "Salutation",
            label: "2.3 - Salutations Data",
        },
        {
            tableName: "ContactPerson",
            label: "2.4 - Contact Person Data",
        },
        {
            tableName: "UserQuestion",
            label: "2.5 - Security Questions Data",
        },
        {
            tableName: "ZipCode",
            label: "2.6 - Zip Codes Data",
        },
        {
            tableName: "divider",
            label: "divider",
        },
        {
            tableName: "Department",
            label: "3.1 - Departments Data",
        },
        {
            tableName: "Occupation",
            label: "3.2 - Job Roles Data",
        },
        {
            tableName: "Specialization",
            label: "3.3 - Specializations Data",
        },
        {
            tableName: "divider",
            label: "divider",
        },
        {
            tableName: "Brush",
            label: "4.1 - Brush Data",
        },
        {
            tableName: "Blade",
            label: "4.2 - Blades Data",
        },
        {
            tableName: "Comb",
            label: "4.3 - Comb Attachments Data",
        },
        {
            tableName: "Shampoo",
            label: "4.4 - Shampoo Data",
        },
        {
            tableName: "divider",
            label: "divider",
        },
        {
            tableName: "SalesItemsLibrary",
            label: "5.1 - Sales Items Library Data",
        },
        {
            tableName: "SalesItems",
            label: "5.2 - Sales Items Data",
        },
        {
            tableName: "ServiceItemsLibrary",
            label: "5.3 - Service Item Library Data",
        },
        {
            tableName: "SchTimeOfDayList",
            label: "5.4 - Times of Day Data",
        },
        {
            tableName: "Scheduling_GeneralPriceList",
            label: "5.5 - General Price List",
        },
        {
            tableName: "divider",
            label: "divider",
        },
        {
            tableName: "Vaccine",
            label: "6.1 - Vaccinations Data",
        },
        {
            tableName: "PetVet",
            label: "6.2 - Vets Data",
        },
        {
            tableName: "WaiverDocument",
            label: "6.3 - Waiver Documents Data",
        },
    ])

    const [directoriesList, setDirectoriesList] = useState([
        {
            tableName: "All Directories",
            label: "* - All Directories",
        },
        {
            tableName: "Location",
            label: "Salon Data",
        },
        {
            tableName: "Pet",
            label: "Pet Data",
        },
        {
            tableName: "Customer",
            label: "Customer Data",
        },
        {
            tableName: "Employee",
            label: "Employee Data",
        },
    ])

    async function deleteByTableName(tableName) {
        Swal.fire({
            title: "Are you sure?",
            text: "Your current changes will be lost!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, revert it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosPrivate
                    .post(`Library/TruncateByTableName?TableName=${tableName}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })
                    .then((result) => {
                        console.log(result)
                        if (result.status == 200) {
                            toast.success("Truncate Successful!")
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                    })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        })
    }

    async function deleteAllLibraries() {
        Swal.fire({
            title: "Are you sure?",
            text: "Your current changes will be lost!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, revert it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosPrivate
                    .post(`Library/TruncateAllLibraryTables`, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })
                    .then((result) => {
                        console.log(result)
                        if (result.status == 200) {
                            toast.success("Libraries - Truncate Successful!")
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                    })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        })
    }

    async function deleteAllDirectories() {
        Swal.fire({
            title: "Are you sure?",
            text: "Your current changes will be lost!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, revert it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosPrivate
                    .post(`Library/TruncateAllDirectoryTables`, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })
                    .then((result) => {
                        console.log(result)
                        if (result.status == 200) {
                            toast.success("Directories - Truncate Successful!")
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Something went wrong!")
                    })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        })
    }

    return (
        <div>
            {
                <div style={{ margin: "0.8rem 4rem" }}>
                    <div style={{ marginTop: "1.5rem", backgroundColor: "#fff", padding: "1.5rem", boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px" }}>
                        <p style={{ margin: "0 0 15px 0", fontWeight: 600, fontSize: "15px" }}>Directories</p>
                        <div>
                            {directoriesList.map((lib) =>
                                lib?.tableName === "All Directories" ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p style={{ margin: 0, fontSize: "14px" }}>{lib?.label}</p>
                                        <IconButton onClick={() => deleteAllDirectories()}>
                                            <Delete style={{ color: "red" }} />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p style={{ margin: 0, fontSize: "14px" }}>{lib?.label}</p>
                                        <IconButton onClick={() => deleteByTableName(lib?.tableName)}>
                                            <Delete style={{ color: "red" }} />
                                        </IconButton>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div style={{ marginTop: "1.5rem", backgroundColor: "#fff", padding: "1.5rem", boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px" }}>
                        <p style={{ margin: "0 0 15px 0", fontWeight: 600, fontSize: "15px" }}>Libraries</p>

                        <div>
                            {libraryList.map((lib) =>
                                lib?.label === "divider" ? (
                                    <div
                                        style={{
                                            borderBottom: "2px solid #e5e5e5",
                                            marginBottom: "15px",
                                            marginTop: "15px",
                                        }}
                                    ></div>
                                ) : lib?.tableName === "All Libraries" ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p style={{ margin: 0, fontSize: "14px" }}>{lib?.label}</p>
                                        <IconButton onClick={() => deleteAllLibraries()}>
                                            <Delete style={{ color: "red" }} />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <p style={{ margin: 0, fontSize: "14px" }}>{lib?.label}</p>
                                        <IconButton onClick={() => deleteByTableName(lib?.tableName)}>
                                            <Delete style={{ color: "red" }} />
                                        </IconButton>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ImportExportReset
