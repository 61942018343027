/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { shortDate } from "../../../../../utils/DateFormatter";

export const GeneralPriceListTemplate = {
    tableName: "5.5 - GeneralPriceList",
    hiddenColumns: [],
    pdfTemplate: ["GeneralPriceListID", "GeneralPriceListName", "varPetTypeName", "varBreedName", "varPetSizeName"],
    hideSort: true,
    template: [
        // {
        //     Header: 'ID',
        //     accessor: 'GeneralPriceListID'
        // },
        {
            Header: 'Location Name',
            accessor: 'LocationName'
        },
        {
            Header: 'Item Name',
            accessor: 'GeneralPriceListName'
        },
        {
            Header: 'Pet Type',
            accessor: 'varPetTypeName'
        },
        {
            Header: 'Breed',
            accessor: 'varBreedName'
        },
        {
            Header: 'Size',
            accessor: 'varPetSizeName'  
        },
        {
            Header: 'Duration (M)',
            accessor: 'GeneralPriceListTime'  
        },
        {
            Header: 'Sales Price ($)',
            accessor: 'numServiceItemSalesPrice'  
        },
        {
            Header: 'Bath Pet',
            accessor: 'IsBathOnly'  ,
            Cell: (props) => <p>{(props?.cell?.value === true)? "YES" : "NO"}</p>
        },
        // {
        //     Header: 'Effective Date',
        //     accessor: 'dtEffectiveDate',
        //     Cell: (props) => <p>{shortDate(props?.cell?.value)}</p>,
        // },
        {
            Header: 'Expiry Date',
            accessor: 'dtExpiryDate',
            Cell: (props) => <p>{shortDate(props?.cell?.value)}</p>,
        },
        {
            Header: "Status",
            accessor: "bitIsAdditional"
           
        },
    ]
}
