import { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Button } from "../../components/components"
import FileUpload from "../../components/FileUpload/FileUpload"
import { downloadWithData, downloadHeaders } from "../../utils/ExcelHandler"
import styles from "./ImportExportHandler.module.scss"
import { axiosPrivate } from "../../apis/axios"
import { saveAs } from "file-saver"

export const ImportExportHandler = (props: { objectName: string; requestType: string; uploadComponent?: any; customFilter?; type?; isSuccess? }) => {
    const [downloadingHeaders, setDownloadingHeaders] = useState(false)
    const [downloadingData, setDownloadingData] = useState(false)

    const properNames = {
        PetType: "1.1 - Pet Types",
        PetSize: "1.2 - Pet Sizes",
        Personality: "1.3 - Pet Behaviors",
        PetColor: "1.4 - Pet Colors",
        MedicalCondition: "1.5 - Pet Health Concerns",
        Style: "1.6 - Pet Grooming Styles",
        Breed: "1.7 - Pure Breeds",
        PetBreed: "1.8 - All Breeds (Pure + Mixed)",
        AddressType: "2.1 - Address Types",
        ContactType: "2.2 - Contact Types",
        Salutation: "2.3 - Salutations",
        ContactPerson: "2.4 - Contact Person Type",
        UserQuestion: "2.5 - Security Questions",
        ZipCode: "2.6 - Zip Codes",
        Department: "3.1 - Departments",
        Occupation: "3.2 - Job Roles",
        Specialization: "3.3 - Groomer Specializations",
        Brush: "4.1 - Brush Types",
        Blade: "4.2 - Blade Sizes",
        Comb: "4.3 - Comb Attachment Sizes",
        Shampoo: "4.4 - Shampoo Types",  
        SalesItemsLibrary: "5.1 - Sales Items Library",
        SalesItems: "5.2 - Sales Items",
        ServiceItemsLibrary: "5.3 - Service Items Library",
        TimeOfDay: "5.4 - Time of Day",
        GeneralPrice: "5.5 - General Price List",
        Vaccine: "6.1 - Vaccinations",
        PetVet: "6.2 - Vets", 
        WaiverDocument: "6.3 - Waiver Documents",   
        ServiceGroup: "1 - ServiceGroup",
        ServiceItems: "1 - ServiceItems",
        ScheduleEvents: "1 - AddressType",
    }

    async function getHeaders(objectName) {
        if (props?.requestType === "ImportExport") {
            const result = await axiosPrivate.get(`${objectName}/GetHeaders`)
            return result.data
        } else if (props?.requestType === "Library") {
            const result = await axiosPrivate.get(`Library/${objectName}/GetHeaders`)
            return result.data
        } else if (props?.requestType === "Custom") {
            const result = await axiosPrivate.get(`${objectName}/GetHeaders`)
            return result.data
        }
    }

    async function getBaseData(objectName) {
        if (props?.requestType === "Custom") {
            const result = await axiosPrivate.get(`${objectName}/ExportAllCombinations?LocationID=${props?.customFilter}`, {
                responseType: "blob",
            })
            return result.data
        }
    }

    async function getData(objectName) {
        if (props?.requestType === "ImportExport") {
            const result = await axiosPrivate.get(`${objectName}/ExportAll`)
            return result.data
        } else if (props?.requestType === "Library") {
            const result = await axiosPrivate.get(`Library/${objectName}/ExportAll`)
            return result.data
        } else if (props?.requestType === "Custom") {
            const result = await axiosPrivate.get(`${objectName}/ExportAll?LocationID=${props?.customFilter}`, {
                responseType: "blob",
            })
            return result.data
        }
    }

    const triggerHeadersDownload = (objectName) => {
        setDownloadingHeaders(true)
        getHeaders(objectName)
            .then((res) => {
                const createHeaders: {}[] = []
                if (props?.requestType === "Library") {
                    Object.values<string>(res?.[objectName.charAt(0).toLowerCase() + objectName.slice(1)]).map((header) => {
                        createHeaders.push({ [header]: "" })
                    })
                } else if (props?.requestType === "Custom") {
                    const values: any = Object.values(res[objectName.charAt(0).toLowerCase() + objectName.slice(1)])
                    values.map((value: any) => {
                        createHeaders.push({ [value]: "" })
                    })
                } else {
                    Object.values<string>(res?.[objectName.toLowerCase()]).map((header) => {
                        createHeaders.push({ [header]: "" })
                    })
                }

                downloadHeaders(properNames[objectName], createHeaders)
                setDownloadingHeaders(false)
            })
            .catch((err) => {
                toast.error("Something went wrong!")
                setDownloadingHeaders(false)
                console.log(err)
            })
    }

    const triggerDataDownload = (objectName) => {
        console.log("objectName", objectName)
        setDownloadingData(true)
        getData(objectName)
            .then((res) => {
                if (props.type !== "Custom") {
                    const initalHeaders = Object.values(res?.header)
                    const initalData = res?.data?.[0]
                    const arrangedHeaders = {}

                    if (res?.data?.length > 0) {
                        Object.keys(initalData).map((header, index) => {
                            arrangedHeaders[header] = initalHeaders[index]
                        })
                        res?.data.unshift(arrangedHeaders)
                        downloadWithData(properNames[objectName], res?.data)
                    } else {
                        toast.info(`No ${objectName}'s data found to export`)
                    }
                    setDownloadingData(false)
                } else {
                    const fileName = `EsyPet Admin Export - ${formattedDate} - 5.5 - General Price List Export.xlsx.xlsx`
                    saveAs(res, fileName)
                    setDownloadingData(false)
                }
            })
            .catch((err) => {
                toast.error("Something went wrong!")
                setDownloadingData(false)
                console.log(err)
            })
    }

    const currentDate = new Date()
    const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
    })

    const triggerDataDownloadBaseData = (objectName) => {
        setDownloadingData(true)
        getBaseData(objectName)
            .then((res) => {
                if (props.type !== "Custom") {
                    const initalHeaders = Object.values(res?.header)
                    const initalData = res?.data?.[0]
                    const arrangedHeaders = {}

                    if (res?.data?.length > 0) {
                        Object.keys(initalData).map((header, index) => {
                            arrangedHeaders[header] = initalHeaders[index]
                        })
                        res?.data.unshift(arrangedHeaders)
                        downloadWithData(properNames[objectName], res?.data)
                    } else {
                        toast.info(`No ${objectName}'s data found to export`)
                    }
                    setDownloadingData(false)
                } else {
                    const fileName = `EsyPet Admin Export - ${formattedDate} - 5.5 - General Price List All combinations Export.xlsx`
                    saveAs(res, fileName)
                    setDownloadingData(false)
                }
            })
            .catch((err) => {
                toast.error("Something went wrong!")
                setDownloadingData(false)
                console.log(err)
            })
    }

    return (
        <>
            <ToastContainer />
            <div style={{ display: "flex", justifyContent: "space-between", gap: "3rem" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem", width: "500px" }}>
                    <Button loading={downloadingHeaders} onClick={() => triggerHeadersDownload(props?.objectName)} variant="contained" color="primary" className={styles.buttonText}>
                        Download template with Headers
                    </Button>
                    {props.requestType === "Custom" ? (
                        <Button loading={downloadingData} onClick={() => triggerDataDownloadBaseData(props?.objectName)} variant="outlined" color="default" className={styles.buttonText}>
                            Download template with All Combinations
                        </Button>
                    ) : null}
                    <Button loading={downloadingData} onClick={() => triggerDataDownload(props?.objectName)} variant="contained" color="secondary" className={styles.buttonText}>
                        Download template with System Data
                    </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", width: "100%" }}>
                    <FileUpload
                        requestType={props?.requestType}
                        type="upload"
                        objectType={props?.objectName}
                        text="Upload Data File"
                        isSuccess={(e) => {
                            if (e === true) {
                                props.isSuccess(true)
                            }
                        }}
                    />
                    {props?.uploadComponent}
                </div>
            </div>
        </>
    )
}

export default ImportExportHandler
