/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

export const Vaccine: {} = {
    tableName: "6.1 - Vaccinations",
    hiddenColumns: ["numVaccineID"],
    pdfTemplate: ["varVaccineName", "varVaccineDescription", "bitActive"],
    template: [
        {
            Header: "Vaccine Name",
            accessor: "varVaccineName",
        },
        {
            Header: "Description",
            accessor: "varVaccineDescription",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
            disableFilters: true,
        },
        {
            Header: "numVaccineID",
            accessor: "numVaccineID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numVaccineID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varVaccineName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varVaccineDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
