import axios from "axios"
import { useEffect, useState, useRef, Fragment } from "react"
import { Button, CloseButton } from "../../components"
import { Modal } from "../../components/components"
import Payment from "./Payment"
import styles from "./Invoice.module.scss"
import { Checkbox, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material"
import { Add, Delete, ExpandLess, ExpandMore, InfoOutlined, PaidOutlined, Save, TuneOutlined } from "@mui/icons-material"
import { shortDate } from "../../utils/DateFormatter"
import { toast } from "react-toastify"
import { useReactToPrint } from "react-to-print"
import { daysPassedOrInFuture } from "../../utils/Validation"
import Tip from "./Tip"
import html2pdf from "html2pdf.js"
import moment from "moment"
import Swal from "sweetalert2"
import { sendOutNotification } from "../../utils/Messaging"
import useAuth from "../../hooks/useAuth"
import { axiosPrivate } from "../../apis/axios"

const CustomerInvoices = (props: {
    isOpenCustomerInvoicesModal
    setIsOpenCustomerInvoicesModal: CallableFunction
    isPassedInvoice
    appointmentDetails?
    isSuccess: CallableFunction
    isSuccessFullPayment?
    Refresh?
}) => {
    const [isLoading, setIsLoading] = useState<any>(false)
    const [chargesList, setChargesList] = useState<any>([])
    const [billingAddress, setBillingAddress] = useState<any>("")
    const [addedDate, setAddedDate] = useState<any>("")
    const [dueDate, setDueDate] = useState<any>("")
    const [selectedCharge, setSelectedCharge] = useState<any>([])
    const [remainingTotal, setRemainingTotal] = useState(0)
    const [paymentItemList, setPaymentItemList] = useState<any>([])
    const [totRemain, setTotRemain] = useState<any>(0)
    const [totPaid, setTotPaid] = useState<any>(0)
    const [totTax, setTotTax] = useState(0) as any
    const [selectAllCharges, setSelectAllCharges] = useState(false)
    const [isOpenPaymentsModal, setIsOpenPaymentsModal] = useState(false)
    const [payCustomerID, setPayCustomerID] = useState(0)

    const [isTipModalOpen, setIsTipModalOpen] = useState(false)
    const [isOpenDiscount, setIsOpenDiscount] = useState<any>(false)

    const { auth } = useAuth() as any

    const GetInvoiceDetails = async (id, type) => {
        let para: any = {}
        if (type === "inv") {
            para = {
                numInvoiceID: id,
            }
        } else {
            para = {
                numAppointmentID: id,
            }
        }
        console.log("sent params to fetch-", para)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetInvoiceDetails`

        try {
            const response = await axios.get(apiUrl, {
                params: para,
            })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const getAppointmentInfo = async (appID) => {
        const apiUrl = `${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/GetAppointmnetDetailsByAppintmentID?AppointmentID=${appID}`

        try {
            const response = await axios.get(apiUrl, {})
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    useEffect(() => {
        if (props.isOpenCustomerInvoicesModal === true) {
            if (props.isPassedInvoice === true) {
                fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
            } else {
                fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
            }
        }
    }, [props.isOpenCustomerInvoicesModal])

    const fetchInvoiceData = (id, type) => {
        console.log("called", id, type)
        setIsLoading(true)
        if (id !== null && id !== undefined) {
            GetInvoiceDetails(id, type)
                .then((data) => {
                    console.log("res - inv list", data)
                    setChargesList(data)
                    setTotPaid(data[0].totalPaid)
                    setTotTax(data[0].taxTotal)
                    let billingAddress1 = data[0].varBillToAddress1 !== null ? data[0].varBillToAddress1 : ""
                    let varBillToAddress2 = data[0].varBillToAddress2 !== null ? data[0].varBillToAddress2 : ""
                    let varBillToCity = data[0].varBillToCity !== null ? data[0].varBillToCity : ""
                    let varBillToState = data[0].varBillToState !== null ? data[0].varBillToState : ""
                    let varBillToZip = data[0].varBillToZip !== null ? data[0].varBillToZip : ""
                    setBillingAddress(billingAddress1 + " " + varBillToAddress2 + " " + varBillToCity + ", " + varBillToState + ", " + varBillToZip)
                    setAddedDate(data[0].dtInvoiceCreatedDate)
                    setDueDate(data[0].dtInvoiceDueDate)
                    setPayCustomerID(data[0].numCustomerID)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error)
                })
        } else {
            console.log("cancelled")
        }
    }

    const [discountCharge, setDiscountCharge] = useState<any>([])

    useEffect(() => {
        setSelectAllCharges(true);
        const selectedCh: any = [];
        chargesList.map((item, index) => {
            if (item.remaining !== 0) { 
                selectedCh?.push(index);
            }
        });
        setSelectedCharge(selectedCh);

        const filteredAppointments = chargesList.filter(appointment => appointment.varServiceOrProductName === "Discount");
        if(filteredAppointments.length > 0){
            setDiscountCharge(filteredAppointments)
        }else{
            setDiscountCharge([])
        }
    }, [chargesList]);

    useEffect(()=>{console.log("dissCharge", discountCharge)},[discountCharge])

    useEffect(() => {
        let amt = 0
        if (selectedCharge.length > 0) {
            selectedCharge.map((index) => {
                amt = amt + parseFloat(chargesList[index].remaining)
            })
            setRemainingTotal(amt)
        } else {
            let amt = 0
            chargesList.map((charge) => {
                amt = amt + parseFloat(charge.remaining)
            })
            setRemainingTotal(amt)
        }
    }, [selectedCharge])

    useEffect(() => {
        if (chargesList.length > 0) {
            let amt = 0
            let totRemainAmt = 0
            chargesList.map((charge) => {
                amt = amt + parseFloat(charge.remaining)
                totRemainAmt = totRemainAmt + parseFloat(charge.remaining)
            })
            setRemainingTotal(amt)
            setTotRemain(totRemainAmt)
        }
    }, [chargesList])

    const makePayment = () => {
        if (selectedCharge.length > 0) {
            let selectedArr: any = []
            selectedCharge.map((item, index) => {
                selectedArr.push({
                    numChargeID: chargesList[item].numChargeID,
                    remaining: chargesList[item].remaining,
                    varTitle: chargesList[0].numMainInvoiceID,
                    numInvoiceID: chargesList[0].numInvoiceID,
                    type: "Tvp",
                    numOrderID: chargesList[item].numOrderID,
                    remTotal: totRemain,
                })
            })
            setPaymentItemList(selectedArr)
            setIsOpenPaymentsModal(true)
        } else {
            toast.info("Please select orders to proceed to payments")
        }
    }

    const handlePayment = (index) => {
        setPaymentItemList([
            {
                numChargeID: chargesList[index].numChargeID,
                varTitle: chargesList[index].varServiceProductDescription,
                remaining: chargesList[index].remaining,
                type: "Order",
                numInvoiceID: chargesList[0].numInvoiceID,
                numOrderID: chargesList[index].numOrderID,
                remTotal: totRemain,
            },
        ])
        setIsOpenPaymentsModal(true)
    }

    const selectItem = (index) => {
        const selectedCh = [...selectedCharge]
        const isIn = selectedCh.includes(index)
        if (!isIn) {
            selectedCh?.push(index)
            setSelectedCharge(selectedCh)
        } else {
            var carIndex = selectedCh.indexOf(index)
            const afterRemove = [...selectedCharge]
            afterRemove.splice(carIndex, 1)
            setSelectedCharge(afterRemove)
        }
    }

    const printInvoice = () => {
        setSelectedCharge([])
        setSelectAllCharges(!selectAllCharges)
        handlePrint()
    }

    const componentRef = useRef() as any
    const componentRefReceipt = useRef() as any
    const componentRefSimplified = useRef() as any

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const handlePrintReceipt = useReactToPrint({
        content: () => componentRefReceipt.current,
    })

    const handlePrintReceiptSimplified = useReactToPrint({
        content: () => componentRefSimplified.current,
    })

    useEffect(() => {
        if (selectAllCharges === true) {
            setSelectedCharge([])
            const selectedCh: any = []
            chargesList.map((item, index) => {
                if (item.remaining !== 0) {
                    selectedCh?.push(index)
                }
            })
            setSelectedCharge(selectedCh)
        } else {
            setSelectedCharge([])
        }
    }, [selectAllCharges])

    //////////////////////////////////////////////////////

    const [groomerTip, setGroomerTip] = useState(0)
    const [batherTip, setBatherTip] = useState(0)

    const groomerName = "John Doe" // Replace with actual groomer name
    const batherName = "Jane Smith" // Replace with actual bather name
    const globalGroomerPercentage = 75 // Example percentage
    const globalBatherPercentage = 25 // Example percentage

    const handleAddTip = () => {
        setIsTipModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsTipModalOpen(false)
    }

    const handleSaveTip = (groomerTip, batherTip) => {
        setGroomerTip(groomerTip)
        setBatherTip(batherTip)
        if (props.isPassedInvoice === true) {
            fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
        } else {
            fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
        }
        setSelectedCharge([])
        setSelectAllCharges(false)
    }

    // const generatePDF = () => {
    //     const element = document.getElementById('printReceipt');

    //     html2pdf().from(element).set({
    //       margin: 1,
    //       filename: 'invoice.pdf',
    //       html2canvas: { scale: 2 },
    //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    //     }).save();
    //   };

    const generatePDF = () => {
        const element = document.getElementById("printReceipt")

        html2pdf()
            .from(element)
            .set({
                margin: 1,
                filename: "invoice.pdf",
                html2canvas: { scale: 2 },
                jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            })
            .outputPdf("blob")
            .then((pdfBlob) => {
                sendEmail(pdfBlob)
            })
    }

    const sendEmail = (pdfBlob) => {
        const formData = new FormData()
        formData.append("folderpath", "email_attachments/customer_receipt")
        formData.append("method", "N")
        formData.append("deletingFileName", "-")
        formData.append("renameFileNames", "0")
        const timestamp = moment().format("YYYYMMDD_HHmmss")

        const blobname = `Invoice_receipt_${chargesList[0].numMainInvoiceID}_${timestamp}`
        const renamedFile = new File([pdfBlob], `${blobname}.pdf`, { type: pdfBlob.type })
        formData.append("files", renamedFile)

        axios
            .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
            .then((response) => {
                console.log("Upload-Res", response.data.files[0])
                //Send Out Message
                let sendFiles = [
                    {
                        messageID: 1,
                        fileName: response.data.files[0]?.renamedFileName + ".pdf",
                        filePath: `${process.env.REACT_APP_MEDIA_URL}email_attachments/customer_receipt/${response.data.files[0]?.renamedFileName}.pdf`,
                    },
                ]
                let emailBody = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><title>Payment Confirmation and Receipt</title></head><body><p>Payment Confirmation and Receipt - ${
                    props.isPassedInvoice ? "Invoice#" : "App#"
                } ${props.isPassedInvoice ? (chargesList[0] !== undefined ? chargesList[0].numMainInvoiceID : "--") : props?.appointmentDetails?.numAppointmentID}</p><p>Dear ${
                    chargesList[0] !== undefined ? chargesList[0].varSalutationName : chargesList[0] !== undefined ? chargesList[0].varCustomerLastName : null
                } ${
                    chargesList[0] !== undefined ? chargesList[0].varCustomerFirstName : null
                },</p><p>We are pleased to inform you that we have received your payment. Please find your receipt attached for your reference.</p></body></html>`

                let payload = {
                    messageBody: [
                        {
                            messageID: 1,
                            recipientID: chargesList[0]?.numCustomerID,
                            recipientType: "C",
                            senderID: 1,
                            senderLocationID: chargesList[0]?.numLocationID,
                            subject: `Payment Confirmation and Receipt - Invoice #${chargesList[0].numMainInvoiceID}`,
                            smsBody: emailBody,
                            emailBody: emailBody,
                            communicationMethod: "E",
                            sender: chargesList[0]?.varLocationName,
                            senderLocation: chargesList[0]?.varLocationName,
                            contactNumber: chargesList[0]?.varCustomerMobilePhone,
                            emailAddress: chargesList[0]?.varCustomerEmail,
                            appointmentID: props.isPassedInvoice ? null : props?.appointmentDetails?.numAppointmentID,
                            option: chargesList[0]?.varCustomerEmail === null ? 1 : 2,
                            sendDate: new Date().toISOString().split("T")[0],
                            messageType: "I",
                            workPhoneNumber: chargesList[0]?.varCustomerWorkPhone,
                            homePhoneNumber: chargesList[0]?.varCustomerHomePhone,
                            bitCustomerNotificationEmail: chargesList[0]?.bitCustomerNotificationEmail,
                            numCustomerNotificationEmailSequence: chargesList[0]?.numCustomerNotificationEmailSequence,
                            bitCustomerNotificationHomePhone: chargesList[0]?.bitCustomerNotificationHomePhone,
                            numCustomerNotificationHomePhoneSequence: chargesList[0]?.numCustomerNotificationHomePhoneSequence,
                            bitCustomerNotificationMobilePhoneCall: chargesList[0]?.bitCustomerNotificationMobilePhoneCall,
                            numCustomerNotificationMobilePhoneCallSequence: chargesList[0]?.numCustomerNotificationMobilePhoneCallSequence,
                            bitCustomerNotificationMobilePhoneSMS: chargesList[0]?.bitCustomerNotificationMobilePhoneSMS,
                            numCustomerNotificationMobilePhoneSMSSequence: chargesList[0]?.numCustomerNotificationMobilePhoneSMSSequence,
                            bitCustomerNotificationWorkPhone: chargesList[0]?.bitCustomerNotificationWorkPhone,
                            numCustomerNotificationWorkPhoneSequence: chargesList[0]?.numCustomerNotificationWorkPhoneSequence,
                            messageTemplateID: null,
                            attachmentModel: sendFiles.length > 0 ? sendFiles : [],
                        },
                    ],
                }

                console.log("sent-payload", payload)

                sendMessagePost(payload)

                let fileAttachments = `<p>Click <a href="${process.env.REACT_APP_MEDIA_URL}email_attachments/customer_receipt/${response.data.files[0]?.renamedFileName}.pdf" download="appointment_receipt.ics">here</a> to download the receipt file for your payment.</p>`
                //Notification
                let notificationPostBody = {
                    notificationType: "success",
                    subject: "Payment Confirmation Ref#:" + chargesList[0].numMainInvoiceID,
                    notificationMessage: "Please find the payment confirmation receipt" + fileAttachments,
                    locationID: chargesList[0]?.numLocationID,
                    locationName: chargesList[0]?.varLocationName,
                    recipientType: "C",
                    recipientID: chargesList[0]?.numCustomerID,
                    recipientName: chargesList[0]?.varCustomerFirstName + " " + chargesList[0]?.varCustomerLastName,
                    isSeen: false,
                }
                sendOutNotification(notificationPostBody)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    async function sendMessagePost(data) {
        await axios
            .post(`${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log("Message sent to pool")
                    toast.success("Email sent successfully")
                }
            })
            .catch((err) => {
                console.log("Messaging -Err-", err)
            })
    }

    const onHandlePrintReceipt = () => {
        Swal.fire({
            title: "Choose an action",
            text: "Select an option to proceed:",
            icon: "question",
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            denyButtonColor: "#f39c12",
            confirmButtonText: "Print Invoice",
            denyButtonText: "Send Email",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                handlePrintReceipt()
            } else if (result.isDenied) {
                toast.info("Your email is being sent")
                generatePDF()
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        })
    }

    const handleChange = (event,index) => {
        const { value } = event.target;
        if (/^(\d*\.?\d*%?)$/.test(value)) {
            const cl = [...chargesList];
            cl[index].DiscountAmount = value;
            setChargesList(cl);
        }
    };

    const handleSave = (index) => {
        const discountAmount = chargesList[index]?.DiscountAmount;
        const chargeAmount = chargesList[index]?.remaining;
    
        if (discountAmount === undefined || discountAmount === "" || chargeAmount === undefined) {
            toast.info("Enter a dollar amount or a percentage to continue");
            return;
        }
    
        if (discountAmount === '0' || discountAmount === '0%') {
            toast.error("Discount amount cannot be zero");
            return;
        }
    
        let discount;
        let finalAmount;
    
        if (discountAmount.endsWith('%')) {
            const percentage = parseFloat(discountAmount.replace('%', ''));
    
            if (isNaN(percentage) || percentage < 1 || percentage > 100) {
                toast.error("Percentage must be between 1% and 100%");
                return;
            }
    
            discount = (chargeAmount * percentage) / 100;
            finalAmount = chargeAmount - discount;
            console.log(`Discount Percentage: ${percentage}%`);
        } else {
            const dollarAmount = parseFloat(discountAmount);
    
            if (isNaN(dollarAmount) || dollarAmount < 0) {
                toast.error("Invalid dollar amount");
                return;
            }
    
            if (dollarAmount > chargeAmount) {
                toast.error("Discount amount cannot be greater than the charge amount");
                return;
            }
    
            discount = dollarAmount;
            finalAmount = chargeAmount - discount;
            console.log(`Discount Dollar Amount: $${dollarAmount}`);
        }
    
        console.log(`Original Amount: $${chargeAmount}`);
        console.log(`Discount Amount: $${discount.toFixed(2)}`);
        console.log(`Final Amount: $${finalAmount.toFixed(2)}`);
        console.log(chargesList[index])

        let payload = {
            "numChargeID": chargesList[index]?.numChargeID,
            "numInvoiceID": chargesList[index]?.numInvoiceID,
            "adjustmentAmount": parseFloat(discount),
            "numLocationServiceOrProductID": (chargesList[index]?.varServiceOrProduct === "P")? chargesList[index]?.numLocationProductID : chargesList[index]?.numLocationServiceItemID,
            "numUserID": auth?.employee?.numEmployeeID
          }

          console.log("sent payload - ",payload)

        axios.post(`${process.env.REACT_APP_API_URL}Payment/PopulateAdjustments`, payload)
        .then((response) => {
            console.log("Upload-Res", response)

            if(response.status === 200){
                const cl = [...chargesList];
                cl[index].DiscountAmount = "";
                setChargesList(cl);

                toast.success("Discount successfully added to the selected item")
                if (props.isPassedInvoice) {
                    fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
                } else {
                    fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
                }

                setSelectedCharge([])
                setSelectAllCharges(false)
            }
        })
        .catch((error) => {
            console.error(error)
        })
    };
    const handleDeleteAdj = (index, adjIndex) => {
        Swal.fire({
            title: 'Caution!',
            text: 'Are you sure you want to remove the special discount?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(chargesList[index]?.Adjustments[adjIndex]?.numAdjustmentID)
                deleteAdjustment(chargesList[index]?.Adjustments[adjIndex]?.numAdjustmentID, index)
            } else {
                console.log("unchanged - selected NO");
            }
        });
    }

    async function deleteAdjustment(data, index) {
        await axiosPrivate
            .delete(`Payment/DeleteAdjustments?numAdjustmentID=${data}`)
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Discount removed successfully!`)
                    const cl = [...chargesList];
                    cl[index].DiscountAmount = "";
                    setChargesList(cl);
                    if (props.isPassedInvoice) {
                        fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
                    } else {
                        fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
                    }
                    setSelectedCharge([])
                    setSelectAllCharges(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function deleteTotalDiscount() {

        if(discountCharge.length > 0){
            await axiosPrivate
            .delete(`Payment/DeleteChargeForTotalDiscount?numChargeID=${discountCharge[0]?.numChargeID}`)
            .then((result) => {
                if (result.status === 200) {
                    toast.success("Discount deleted successfully!");
                    if (props.isPassedInvoice === true) {
                      fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
                  } else {
                      fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
                  }
                    setSelectedCharge([])
                    setSelectAllCharges(false)
                    setDollarValue(0)
                    setDiscountInput('')
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
        }else{
            toast.warning("Discount not found")
        }
  
    }

    const [discountInput, setDiscountInput] = useState('');
    const [dollarValue, setDollarValue] = useState(0) as any

    useEffect(() => {
        const input = discountInput.trim();
        if (input.endsWith('%')) {
            const percentage = parseFloat(input.slice(0, -1));
            if (!isNaN(percentage)) {
                setDollarValue((remainingTotal * (percentage / 100)).toFixed(2));
            } else {
                setDollarValue(0);
            }
        } else {
            const dollarAmount = parseFloat(input);
            if (!isNaN(dollarAmount)) {
                setDollarValue(dollarAmount.toFixed(2));
            } else {
                setDollarValue(0);
            }
        }
    }, [discountInput, remainingTotal]);

    const handleSaveDiscount = () => {
        const input = discountInput.trim();
        if (input.endsWith('%')) {
            const percentage = parseFloat(input.slice(0, -1));
            if (!isNaN(percentage)) {
                const discountAmount = (remainingTotal * (percentage / 100)).toFixed(2);
                console.log(`Discount Amount: -$${discountAmount}`);
                addDiscount(parseFloat(discountAmount));
            } else {
                console.log('Invalid percentage value');
            }
        } else {
            const dollarAmount = parseFloat(input);
            if (!isNaN(dollarAmount)) {
                console.log(`Dollar Amount: -$${dollarAmount}`);
                addDiscount(dollarAmount);
            } else {
                toast.error('Invalid dollar amount');
            }
        }
    };

    const getTipServiceDetails = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Tip/GetTipServiceItemIdByName`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
              serviceName: "Discount"
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      };

      async function saveServiceItemsLibrary(data) {
        await axiosPrivate
            .post(`ServiceItemsLibrary`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                console.log("result", result)
                if (result.status == 200) {
                    if (result.data?.bitSuccess === false) {
                        toast.info(result.data?.varResponseMessage)
                    } else {
                        toast.success(`New Service Item Discount successfully saved!`)
                        setIsOpenDiscount(false)
                        setDollarValue(0)
                        setDiscountInput('')
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const addDiscount = (amt) => {

        getTipServiceDetails()
        .then((data) => {
          if(data === "Service item not found."){
            Swal.fire({
              title: 'Caution',
              text: 'Service Item "Discount" not found, Do you wish to add the Tip to service item library to continue?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, Add It',
              cancelButtonText: 'No, Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                let TipObj = {
                  "serviceItemsLibrary": {
                      "numServiceItemID": 0,
                      "varServiceItemProviderID": "0000",
                      "varServiceItemBarCode": "0000",
                      "varServiceItemName": "Discount",
                      "varServiceItemDescription": "Discount for the invoice",
                      "numServiceItemCostPrice": 0,
                      "numServiceItemSalesPrice": 0,
                      "bitIsAdditional": true,
                      "btSalesCommissionPercent": false,
                      "numSalesCommissionPercent": 0,
                      "btSalesCommissionAmount": false,
                      "numSalesCommissionAmount": 0,
                      "dtEffectiveDate": "2023-01-01",
                      "dtExpiryDate": "2300-01-01",
                      "numPetTypeID": 1,
                      "varColour": "#c6c6c6",
                      "bitActive": true
                  }
              }
              saveServiceItemsLibrary(TipObj)
              }else if(result.dismiss){
                setIsOpenDiscount(false)
              } 
            });
          }else{

        let payload = [
            {
                "numChargeID": null,
                "numOrderID": 0,
                "numInvoiceID": chargesList[0]?.numInvoiceID,
                "numLocationID": chargesList[0]?.numLocationID,
                "numSchID": null,
                "numAppointmentID": chargesList[0]?.numAppointmentID,
                "numCustomerID": chargesList[0]?.numCustomerID,
                "numPetID": chargesList[0]?.numPetID,
                "numPetTypeID": chargesList[0]?.numPetTypeID,
                "numPetSizeID": chargesList[0]?.numPetSizeID,
                "numPetBreedID": chargesList[0]?.numPetBreedID,
                "bitAddOnService": null,
                "varServiceOrProduct": "S",
                "numServiceItemID": data?.serviceItemId,
                "numProductID": null,
                "varServiceProductDescription": data?.varServiceItemDescription,
                "bitTaxableItem": null,
                "numSalesTaxPercentage": null,
                "numGroomerID": null,
                "numBatherID": null,
                "numQuantity": 1,
                "decUnitPrice": 0,
                "decChargeAmount": parseFloat(amt),
                "decTaxAmt": 0,
                "dtBeginDateOfService": new Date().toISOString(),
                "dtEndDateOfService": new Date().toISOString(),
                "varStatus": "New",
                "dtZeroBalDate": null,
                "dtClosingDate": null,
                "bitActive": true,
                "numLocationServiceItemID": null,
                "dtUpdatedStartTime": null,
                "dtUpdatedEndTime": null,
                "isTimerStarted": null,
                "isSettled": (remainingTotal-parseFloat(amt) === 0)? 1 : 0,
                "numUserID": 1
              }
          ]
          console.log("sent Payload", payload)
          saveDiscount(payload)
            }
              })
              .catch((error) => {
                console.log("error", error);
              });

    }

    async function saveDiscount(data) {
        console.log("tips payload", data)
        await axiosPrivate.post(`Tip/PopulateChargesForTotalDiscount`, 
        JSON.stringify(data), 
        {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        ).then((result) => {
            if(result.status == 200){        
              console.log(result)
              toast.success("Discount added successfully!");
              if (props.isPassedInvoice === true) {
                fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
            } else {
                fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
            }
              setSelectedCharge([])
              setSelectAllCharges(false)
              setIsOpenDiscount(false)
  
            }
        }).catch((err)=>{
            console.log(err);
            toast.error('Something went wrong!');
        })        
    }

    const handleInputChange = (e) => {
        // Allow only numbers and '%' symbol
        const value = e.target.value;
        if (/^[0-9%]*$/.test(value)) {
            setDiscountInput(value);
        }
    };

    const deleteDiscount = () => {

        Swal.fire({
            title: 'Caution!',
            text: 'Are you sure you want to remove the discount?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTotalDiscount()
            } else {
                console.log("unchanged - selected NO");
            }
        });
    }
    
    return (
        <div>
            <Modal open={props.isOpenCustomerInvoicesModal}>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px", width: "1400px" }}>
                    <div
                        style={{
                            margin: "-15px -15px 0 -15px",
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "#f3f4f6",
                            padding: "1rem 1rem 1rem 25px",
                            borderTopLeftRadius: "12px",
                            borderTopRightRadius: "12px",
                        }}
                    >
                        <div>
                            <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
                                <div style={{ color: "#002867", fontSize: "24px", fontWeight: "600" }}>View Invoice</div>
                            </div>
                        </div>
                        <div style={{ color: "#F9FAFB", borderRadius: "8px" }}>
                            <div style={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <CloseButton onClick={() => props.setIsOpenCustomerInvoicesModal(false)} />
                            </div>
                        </div>
                    </div>

                    {isTipModalOpen && (
                        <Tip
                            groomerName={groomerName}
                            batherName={batherName}
                            onClose={handleCloseModal}
                            onSave={handleSaveTip}
                            globalGroomerPercentage={globalGroomerPercentage}
                            globalBatherPercentage={globalBatherPercentage}
                            totalAmount={parseFloat(totPaid) + parseFloat(totRemain)}
                            isOpenTipModal={isTipModalOpen}
                            setIsOpenTipModal={() => setIsTipModalOpen(!isTipModalOpen)}
                            appDetails={props.appointmentDetails}
                        />
                    )}

                    <div style={{ padding: "0 10px" }}>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "25px" }}>
                                <div>
                                    <p style={{ fontSize: "16px" }}>
                                        <span style={{ fontWeight: "600" }}>Invoice No: </span>
                                        {chargesList[0] !== undefined ? chargesList[0].numMainInvoiceID : "--"}
                                    </p>
                                    <p style={{ fontSize: "16px" }}>
                                        <span style={{ fontWeight: "600" }}>Customer:</span> {chargesList[0] !== undefined ? chargesList[0].varSalutationName : ""}
                                        {chargesList[0] !== undefined ? chargesList[0].varCustomerFirstName : ""} {chargesList[0] !== undefined ? chargesList[0].varCustomerLastName : ""}
                                    </p>
                                    <p style={{ fontSize: "16px" }}>
                                        <span style={{ fontWeight: "600" }}>Billing Address: </span>
                                        {billingAddress}
                                    </p>
                                </div>
                                <div>
                                    <p style={{ fontSize: "16px" }}>
                                        <span style={{ fontWeight: "600" }}>Date:</span> {shortDate(addedDate) + " (" + daysPassedOrInFuture(addedDate) + ")"}
                                    </p>
                                    <p style={{ fontSize: "16px" }}>
                                        <span style={{ fontWeight: "600" }}>Date Due:</span> {shortDate(dueDate) + " (" + daysPassedOrInFuture(dueDate) + ")"}
                                    </p>
                                </div>
                            </div>
                            <div style={{ overflowY: "scroll", minHeight: "100px", maxHeight: "400px" }}>
                                <table className={styles.invTable} style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Checkbox
                                                    checked={selectAllCharges}
                                                    onChange={() => setSelectAllCharges(!selectAllCharges)}
                                                />
                                            </th>
                                            <th style={{ whiteSpace: "nowrap" }}>Order ID</th>
                                            <th style={{ width: "100%", textAlign: "left" }}>Description</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Unit Chrg</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Qty.</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Charge</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Adjustments</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Tax</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Total</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Payments</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Balance</th>
                                            <th style={{ whiteSpace: "nowrap" }}>Discount</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chargesList.map((charge, index) => {
                                            const shouldHide = charge.varServiceOrProductName === "Discount";
                                            const selectedChargeItem = [...selectedCharge];
                                            const isIn = selectedChargeItem.includes(index);
                                            console.log("hide", shouldHide)
                                            return (
                                                <Fragment key={index}>
                                                    <tr
                                                        className={styles.mainRow}
                                                        style={{
                                                            borderBottom: "2px solid #dbdbdb",
                                                            display: shouldHide ? "none" : "table-row"
                                                        }}
                                                    >
                                                        <td>
                                                            <Checkbox
                                                                disabled={parseFloat(charge.remaining) > 0 ? false : true}
                                                                checked={isIn}
                                                                onChange={() => selectItem(index)}
                                                            ></Checkbox>
                                                        </td>
                                                        <td>
                                                            {charge.Adjustments.length > 0 || charge.Payments.length > 0 ? (
                                                                <IconButton
                                                                    onClick={() => {
                                                                        let collapseCharges = [...chargesList];
                                                                        collapseCharges[index].collapsed = !collapseCharges[index].collapsed;
                                                                        setChargesList(collapseCharges);
                                                                    }}
                                                                    style={{ padding: "0 2px 2px 1px" }}
                                                                >
                                                                    {chargesList[index].collapsed ? (
                                                                        <ExpandLess style={{ fontSize: "15px" }}></ExpandLess>
                                                                    ) : (
                                                                        <ExpandMore style={{ fontSize: "15px" }}></ExpandMore>
                                                                    )}
                                                                </IconButton>
                                                            ) : null}
                                                            <strong>{charge.numOrderID}</strong>
                                                        </td>
                                                        <td style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                                                <div style={charge.varServiceOrProduct === "S" ? { color: "#5178bb", fontWeight: "600" } : { color: "#88bb51", fontWeight: "600" }}>
                                                                    ({charge.varServiceOrProduct})
                                                                </div>
                                                                <p>
                                                                    {charge.varServiceOrProductName}{" "}
                                                                    {charge.numPetID === -1 ? null : charge.numPetID === null ? null : (
                                                                        <span>
                                                                            Pet: (<span>{charge.varPetName}</span>)
                                                                        </span>
                                                                    )}
                                                                    {charge.numAppointmentID !== null && charge.numAppointmentID !== 0 ? (
                                                                        <>
                                                                            <span style={{ fontWeight: "500" }}>{" App:" + charge.numAppointmentID}</span>
                                                                        </>
                                                                    ) : null}
                                                                </p>
                                                            </div>
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        <p style={{ fontSize: "14px", color: "#fff", fontWeight: "600" }}>{charge.varServiceOrProduct} - Description</p>
                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{charge.varServiceProductDescription}</p>
                                                                    </>
                                                                }
                                                            >
                                                                <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                            </Tooltip>
                                                        </td>
                                                        <td>${charge?.decChargeAmount}</td>
                                                        <td style={{ textAlign: "center" }}>{charge?.numQuantity}</td>
                                                        <td>${charge?.decChargeAmt}</td>
                                                        <td>(${charge?.totalAdjustments})</td>
                                                        <td>${charge?.decTaxAmt}</td>
                                                        <td>${(charge?.decChargeAmt - charge?.totalAdjustments + charge?.decTaxAmt).toFixed(2)}</td>
                                                        <td>(${charge?.totalPayments})</td>
                                                        <td>${parseFloat(charge?.remaining).toFixed(2)}</td>
                                                        <td>
                                                            {
                                                                (charge?.varServiceOrProductName !== "Groomer Tip" && charge?.varServiceOrProductName !== "Bather Tip") ?
                                                                    <div style={{ display: 'flex', alignItems: 'center', width: "100px", marginLeft: "2px" }}>
                                                                        <TextField
                                                                            value={charge?.DiscountAmount}
                                                                            onChange={(e) => { handleChange(e, index) }}
                                                                            disabled={parseFloat(charge?.remaining) > 0 ? false : true}
                                                                            type="text"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    style: {
                                                                                        fontSize: '14px',
                                                                                        fontWeight: "600"
                                                                                    },
                                                                                },
                                                                            }}
                                                                            variant="standard"
                                                                            size="small"
                                                                            style={{ flex: 1 }}
                                                                        />
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => { handleSave(index) }}
                                                                            aria-label="save"
                                                                            disabled={parseFloat(charge?.remaining) > 0 ? false : true}
                                                                        >
                                                                            <Save />
                                                                        </IconButton>
                                                                    </div> : null
                                                            }

                                                        </td>
                                                        <td>

                                                            {
                                                                (remainingTotal === 0) ?
                                                                    <Button
                                                                        disabled={true}
                                                                        color="info"
                                                                        variant="outlined"
                                                                        text="PAY"
                                                                        onClick={() => handlePayment(index)}
                                                                    ></Button>
                                                                    : <Button
                                                                        disabled={parseFloat(charge?.remaining) > 0 ? false : true}
                                                                        color="info"
                                                                        variant="outlined"
                                                                        text="PAY"
                                                                        onClick={() => handlePayment(index)}
                                                                    ></Button>
                                                            }

                                                        </td>
                                                    </tr>
                                                    {charge.Adjustments.length > 0
                                                        ? charge.Adjustments.map((adj, adjIndex) => (
                                                            <tr
                                                                style={{
                                                                    backgroundColor: "#f7dddd73",
                                                                    display: shouldHide ? "none" : "table-row"
                                                                }}
                                                                className={chargesList[index].collapsed ? undefined : styles.rowhidden}
                                                            >
                                                                <td></td>
                                                                <td></td>
                                                                <td>
                                                                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px", gap: "5px" }}>
                                                                        <TuneOutlined style={{ color: "orange", fontSize: "18px" }} />
                                                                        <p style={{ fontSize: "12px", margin: "0" }}>{adj.varPromotionDescription}</p>
                                                                    </div>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                                <td>
                                                                    <p style={{ paddingLeft: "15px", margin: "0" }}>${adj.decAdjustmentAmount}</p>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>                                     {
                                                                    (adj.varPromotionDescription === "Special Discount") ?
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => { handleDeleteAdj(index, adjIndex) }}
                                                                            aria-label="save"
                                                                            disabled={parseFloat(charge.remaining) > 0 ? false : true}
                                                                        >
                                                                            <Delete />
                                                                        </IconButton> : null
                                                                }</td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                        : null}
                                                    {charge.Payments.length > 0
                                                        ? charge.Payments.map((pay, adjIndex) => (
                                                            <tr
                                                                style={{
                                                                    backgroundColor: "#ddf7ec73",
                                                                    display: shouldHide ? "none" : "table-row"
                                                                }}
                                                                className={chargesList[index].collapsed ? undefined : styles.rowhidden}
                                                            >
                                                                <td></td>
                                                                <td></td>
                                                                <td>
                                                                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px", gap: "5px" }}>
                                                                        <PaidOutlined style={{ color: "green", fontSize: "18px" }} />
                                                                        <p style={{ fontSize: "12px", margin: "0" }}>{pay.varPmtReasonCodeDescription1}</p>
                                                                    </div>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>
                                                                    <p style={{ paddingLeft: "15px", margin: "0" }}>${pay.decPaymentAmount}</p>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                        : null}
                                                </Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ marginRight: "16px" }}>
                                <table className={styles.invTable} style={{ width: "100%" }}>
                              
                                    
                                    {/* Discount Row */}
                                    <tr>
                                    <th colSpan={5} style={{ width: "100%", textAlign: "left", backgroundColor:"white" }}>
                                        Discount
                                    </th>
                                    <th colSpan={5} style={{ width: "100%", textAlign: "center", backgroundColor:"white" }}>
                                        {
                                            (discountCharge.length > 0)?
                                            <div style={{display:"flex", gap:"5px", alignItems:"center", width:"max-content"}}>
                                                <p style={{fontSize:"14px", fontWeight:"600"}}>{"$ -"+discountCharge[0]?.Adjustments[0]?.decAdjustmentAmount}</p>
                                                <IconButton
                                                    color="error"
                                                    onClick={()=>{deleteDiscount()}}
                                                    aria-label="save"
                                                    // disabled={parseFloat(charge.remaining) > 0 ? false : true}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                            :     <IconButton
                                            color="primary"
                                            onClick={()=>{setIsOpenDiscount(true)}}
                                            aria-label="save"
                                            // disabled={parseFloat(charge.remaining) > 0 ? false : true}
                                        >
                                            <Add />
                                        </IconButton>
                                        }
                                    </th>
                                    </tr>
                                    
                                    <tr>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "25px", textAlign: "left" }}>
                                        Total
                                    </th>
                                        <th style={{ textAlign: "right", fontSize: "25px" }}>${parseFloat(remainingTotal.toString()).toFixed(2)}</th>
                                    </tr>
                                </table>
                                </div>
                        </div>
                    </div>

                    <Modal open={isOpenDiscount}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '500px' }}>
                            <div style={{ display: "flex", justifyContent: 'space-between', backgroundColor: '#f3f4f6', margin: '-15px -15px 0 -15px', padding: '1rem', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                                <div>
                                    <div style={{ height: "40px", display: "flex", alignItems: "center"}}>
                                        <div style={{ color: "#002867", fontSize: "24px", fontWeight: "600" }}>Give a discount</div>
                                    </div>
                                </div>
                                <div style={{ color: "#F9FAFB", borderRadius: "8px" }}>
                                    <div style={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <CloseButton onClick={() => setIsOpenDiscount(false)} />
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: '0 20px' }}>
                                <div>
                                    <div style={{ fontSize: "18px", marginBottom: "10px", backgroundColor:"#1976d226", color:"#002867", borderRadius:"4px", fontWeight:"600", padding:"10px", width:"fit-content" }}>Remaining Total: ${remainingTotal}</div>
                                    <input
                                        type="text"
                                        value={discountInput}
                                        onChange={handleInputChange}
                                        placeholder="Enter discount (e.g., 5 or 10%)"
                                        style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
                                    />
                                    <div style={{ fontSize: "18px", marginTop: "10px" }}>
                                        Dollar Value: ${dollarValue}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}>
                                    <Button color='primary' variant='contained' onClick={handleSaveDiscount} disabled={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <div style={{ display: "flex", justifyContent: "flex-end", margin: "0 20px 10px 0", gap: "10px" }}>
                        <Button color="default" variant="outlined" onClick={() => printInvoice()}>
                            Print Invoice
                        </Button>
                        <Button color="default" variant="outlined" onClick={() => handlePrintReceiptSimplified()}>
                            Print Receipt
                        </Button>
                        <Button color="default" variant="outlined" onClick={() => onHandlePrintReceipt()}>
                            Print/Email Detailed Receipt
                        </Button>
                        {props.isPassedInvoice !== true ? (
                            <Button color="success" variant="contained" disabled={false} onClick={handleAddTip}>
                                Add Tip
                            </Button>
                        ) : null}

                        <Button color="primary" variant="contained" disabled={(remainingTotal > 0)? false : true  } onClick={() => makePayment()}>
                            Make Payment
                        </Button>
                        <Payment
                            customerID={payCustomerID}
                            data={paymentItemList}
                            isOpenPaymentsModal={isOpenPaymentsModal}
                            setIsOpenPaymentsModal={(value) => setIsOpenPaymentsModal(value)}
                            clearSelected={(value) => {
                                if (value === true) {
                                    setSelectedCharge([])
                                }
                            }}
                            onSuccess={(value) => {
                                if (value === true) {
                                    console.log("payment done CUSIN")
                                    onHandlePrintReceipt()
                                    if (props.isPassedInvoice) {
                                        fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
                                    } else {
                                        fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
                                    }

                                    setSelectedCharge([])
                                    setSelectAllCharges(false)
                                    props.isSuccess(true)
                                }
                            }}
                            isSuccessFullPayment={(e) => {
                                console.log("full payment done CUSIN")
                                if (e === true) {
                                    onHandlePrintReceipt()
                                    if (props.isPassedInvoice) {
                                        console.log("Inv full")
                                        fetchInvoiceData(props.appointmentDetails.numInvoiceID, "inv")
                                    } else {
                                        fetchInvoiceData(props.appointmentDetails.numAppointmentID, "app")
                                    }

                                    setSelectedCharge([])
                                    setSelectAllCharges(false)
                                    props.isSuccessFullPayment(true)
                                }
                            }}
                            appointment={props.appointmentDetails}
                            passedByInv={props.isPassedInvoice}
                        />
                    </div>
                </div>
            </Modal>

            <div style={{ display: "none" }}>
                <div id="printInvoice" ref={componentRef}>
                    <div style={{ margin: "40px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "25px" }}>
                            <img style={{ width: "200px" }} src={`${process.env.REACT_APP_MEDIA_URL}invoice-mockup/images/pettech-logo.png`}></img>
                            <p style={{ fontSize: "40px", fontWeight: "600", color: "#002867" }}>INVOICE</p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "25px" }}>
                            <div>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Invoice No: </span>
                                    {chargesList[0] !== undefined ? chargesList[0].numMainInvoiceID : "--"}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Customer:</span> {chargesList[0] !== undefined ? chargesList[0].varSalutationName : null}
                                    {chargesList[0] !== undefined ? chargesList[0].varCustomerFirstName : null} {chargesList[0] !== undefined ? chargesList[0].varCustomerLastName : null}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Billing Address: </span>
                                    {billingAddress}
                                </p>
                            </div>
                            <div>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Date:</span> {shortDate(addedDate)}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Date Due:</span> {shortDate(dueDate)}
                                </p>
                            </div>
                        </div>

                        <div>
                            <table className={styles.invTablePrint} style={{ width: "100%" }}>
                                <tr>
                                    <th style={{ whiteSpace: "nowrap", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Order ID</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Description</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Unit Chrg</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Qty.</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Charge</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Adjustments</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Tax</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Total</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Payments</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Balance</th>
                                </tr>
                                {chargesList.map((charge, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td style={{ textAlign: "center" }}>
                                                    <strong>{charge.numOrderID}</strong>
                                                </td>
                                                <td>
                                                    <p style={{ margin: "0" }}>
                                                        <span style={charge.varServiceOrProduct === "S" ? { color: "#5178bb", fontWeight: "600" } : { color: "#88bb51", fontWeight: "600" }}>
                                                            ({charge.varServiceOrProduct}) &nbsp;
                                                        </span>
                                                        {charge.varServiceProductDescription}{" "}
                                                        {charge.numPetID === -1 ? null : charge.numPetID === null ? null : (
                                                            <span>
                                                                Pet: (<span>{charge.varPetName}</span>)
                                                            </span>
                                                        )}
                                                    </p>
                                                </td>
                                                <td style={{ textAlign: "right" }}>${charge.decChargeAmount}</td>
                                                <td style={{ textAlign: "center" }}>{charge.numQuantity}</td>

                                                <td style={{ textAlign: "right" }}>${charge.decChargeAmt}</td>
                                                <td style={{ textAlign: "right" }}>(${charge.totalAdjustments})</td>
                                                <td style={{ textAlign: "right" }}>${charge.decTaxAmt}</td>
                                                <td style={{ textAlign: "right" }}>${(charge?.decChargeAmt - charge?.totalAdjustments + charge?.decTaxAmt).toFixed(2)}</td>
                                                <td style={{ textAlign: "right" }}>(${charge.totalPayments})</td>
                                                <td style={{ textAlign: "right" }}>${parseFloat(charge.remaining).toFixed(2)}</td>
                                            </tr>
                                            {charge.Adjustments.length > 0
                                                ? charge.Adjustments.map((adj, adjIndex) => {
                                                      return (
                                                          <tr>
                                                              <td></td>
                                                              <td>
                                                                  <p style={{ margin: "0" }}>{adj.varPromotionDescription}</p>
                                                              </td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                      
                                                              <td>
                                                                  <p style={{ margin: "0", textAlign: "right" }}>${adj.decAdjustmentAmount}</p>
                                                              </td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                          </tr>
                                                      )
                                                  })
                                                : null}
                                        </>
                                    )
                                })}
                            </table>
                        </div>

                        <div style={{ marginTop: "15px" }}>
                            <table style={{ width: "100%" }}>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "20px", textAlign: "left", color: "#002867" }}>
                                        Total
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "20px", color: "#002867" }}>${parseFloat((totPaid + totRemain).toString()).toFixed(2)}</th>
                                </tr>
                            </table>
                        </div>

                        <div style={{ backgroundColor: "#f3f4f6", marginTop: "30px", borderRadius: "15px" }}>
                            <p style={{ fontSize: "12px", fontWeight: "600", width: "100%", padding: "5px", textAlign: "center" }}>Thank you for your Business</p>
                        </div>
                        <p style={{ textAlign: "center", borderRadius: "15px", padding: "5px", backgroundColor: "#f3f4f6", fontSize: "10px" }}>
                            Pet Tech, 39, Berry Hill Road, Syosett 11791&nbsp;&nbsp;|&nbsp;&nbsp;New York&nbsp;&nbsp;|&nbsp;&nbsp;(456) 789-0000&nbsp;&nbsp;|&nbsp;&nbsp;info@esypet.net
                        </p>
                    </div>
                </div>

                <div id="printReceipt" ref={componentRefReceipt}>
                    <div style={{ margin: "40px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "25px" }}>
                            <div style={{ display: "flex" }}>
                                <p style={{ color: "#2076FF", fontSize: "30px", fontWeight: "600" }}>ESy</p>
                                <p style={{ color: "#002867", fontSize: "30px", fontWeight: "600" }}>Pet</p>
                            </div>
                            {/* <img style={{ width: '200px' }} src={`${process.env.REACT_APP_MEDIA_URL}invoice-mockup/images/pettech-logo.png`}></img> */}
                            <p style={{ fontSize: "40px", fontWeight: "600", color: "#002867" }}>RECEIPT</p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "25px" }}>
                            <div>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Invoice No: </span>
                                    {chargesList[0] !== undefined ? chargesList[0].numMainInvoiceID : "--"}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Customer:</span> {chargesList[0] !== undefined ? chargesList[0].varSalutationName : null}
                                    {chargesList[0] !== undefined ? chargesList[0].varCustomerFirstName : null} {chargesList[0] !== undefined ? chargesList[0].varCustomerLastName : null}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Billing Address: </span>
                                    {billingAddress}
                                </p>
                            </div>
                            <div>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Date:</span> {shortDate(addedDate)}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Date Due:</span> {shortDate(dueDate)}
                                </p>
                            </div>
                        </div>

                        <div>
                            <table className={styles.invTablePrint} style={{ width: "100%" }}>
                                <tr>
                                    <th style={{ whiteSpace: "nowrap", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Order ID</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Description</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Unit Chrg</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Qty.</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Charge</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Adjustments</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Tax</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Total</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Payments</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Balance</th>
                                </tr>
                                {chargesList.map((charge, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td style={{ textAlign: "center" }}>
                                                    <strong>{charge.numOrderID}</strong>
                                                </td>
                                                <td>
                                                    <p style={{ margin: "0" }}>
                                                        <span style={charge.varServiceOrProduct === "S" ? { color: "#5178bb", fontWeight: "600" } : { color: "#88bb51", fontWeight: "600" }}>
                                                            ({charge.varServiceOrProduct}) &nbsp;
                                                        </span>
                                                        {charge.varServiceProductDescription}{" "}
                                                        {charge.numPetID === -1 ? null : charge.numPetID === null ? null : (
                                                            <span>
                                                                Pet: (<span>{charge.varPetName}</span>)
                                                            </span>
                                                        )}
                                                        {charge.numAppointmentID !== null && charge.numAppointmentID !== 0 ? (
                                                            <>
                                                                <span style={{ fontWeight: "500" }}>{" App:" + charge.numAppointmentID}</span>
                                                            </>
                                                        ) : null}
                                                    </p>
                                                </td>
                                                <td style={{ textAlign: "right" }}>${charge.decChargeAmount}</td>
                                                <td style={{ textAlign: "center" }}>{charge.numQuantity}</td>

                                                <td style={{ textAlign: "right" }}>${charge.decChargeAmt}</td>
                                                <td style={{ textAlign: "right" }}>(${charge.totalAdjustments})</td>
                                                <td style={{ textAlign: "right" }}>${charge.decTaxAmt}</td>
                                                <td style={{ textAlign: "right" }}>${(charge?.decChargeAmt - charge?.totalAdjustments + charge?.decTaxAmt).toFixed(2)}</td>
                                                <td style={{ textAlign: "right" }}>(${charge.totalPayments})</td>
                                                <td style={{ textAlign: "right" }}>${parseFloat(charge.remaining).toFixed(2)}</td>
                                            </tr>
                                            {charge.Adjustments.length > 0
                                                ? charge.Adjustments.map((adj, adjIndex) => {
                                                      return (
                                                          <tr style={{ backgroundColor: "#fbf0f0" }}>
                                                              <td></td>
                                                              <td>
                                                                  <p style={{ margin: "0", marginLeft: "10px" }}>{adj.varPromotionDescription}</p>
                                                              </td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                     
                                                              <td>
                                                                  <p style={{ margin: "0", textAlign: "right" }}>${adj.decAdjustmentAmount}</p>
                                                              </td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                          </tr>
                                                      )
                                                  })
                                                : null}

                                            {charge.Payments.length > 0
                                                ? charge.Payments.map((pay, adjIndex) => {
                                                      return (
                                                          <tr style={{ backgroundColor: "#f0fbf6" }}>
                                                              <td></td>
                                                              <td>
                                                                  <p style={{ margin: "0", marginLeft: "10px" }}>{pay.varPmtReasonCodeDescription1}</p>
                                                              </td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                              <td></td>
                                                              <td>
                                                                  <p style={{ textAlign: "right", margin: "0" }}>${pay.decPaymentAmount}</p>
                                                              </td>
                                                              <td></td>
                                                          </tr>
                                                      )
                                                  })
                                                : null}
                                        </>
                                    )
                                })}
                            </table>
                        </div>

                        <div style={{ marginTop: "15px" }}>
                            <table style={{ width: "100%" }}>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867" }}>
                                        Tax Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "14px", color: "#002867" }}>${parseFloat(totTax.toString()).toFixed(2)}</th>
                                </tr>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "15px", textAlign: "left", color: "#002867" }}>
                                        Total Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "15px", color: "#002867" }}>${parseFloat((totPaid + totRemain).toString()).toFixed(2)}</th>
                                </tr>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867" }}>
                                        Paid Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "14px", color: "#002867" }}>${parseFloat(totPaid.toString()).toFixed(2)}</th>
                                </tr>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "15px", textAlign: "left", color: "#002867" }}>
                                        Balance Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "15px", color: "#002867" }}>${parseFloat(totRemain.toString()).toFixed(2)}</th>
                                </tr>
                            </table>
                        </div>

                        <div style={{ backgroundColor: "#f3f4f6", marginTop: "30px", borderRadius: "15px" }}>
                            <p style={{ fontSize: "12px", fontWeight: "600", width: "100%", padding: "5px", textAlign: "center" }}>Thank you for your Business</p>
                        </div>
                        <p style={{ textAlign: "center", borderRadius: "15px", padding: "5px", backgroundColor: "#f3f4f6", fontSize: "10px" }}>
                            Pet Tech, 39, Berry Hill Road, Syosett 11791&nbsp;&nbsp;|&nbsp;&nbsp;New York&nbsp;&nbsp;|&nbsp;&nbsp;(456) 789-0000&nbsp;&nbsp;|&nbsp;&nbsp;info@esypet.net
                        </p>
                    </div>
                </div>

                <div id="printReceipt" ref={componentRefSimplified}>
                    <div style={{ margin: "40px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "25px" }}>
                            <img style={{ width: "200px" }} src={`${process.env.REACT_APP_MEDIA_URL}invoice-mockup/images/pettech-logo.png`}></img>
                            <p style={{ fontSize: "40px", fontWeight: "600", color: "#002867" }}>RECEIPT</p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "25px" }}>
                            <div>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Invoice No: </span>
                                    {chargesList[0] !== undefined ? chargesList[0].numMainInvoiceID : "--"}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Customer:</span> {chargesList[0] !== undefined ? chargesList[0].varSalutationName : null}
                                    {chargesList[0] !== undefined ? chargesList[0].varCustomerFirstName : null} {chargesList[0] !== undefined ? chargesList[0].varCustomerLastName : null}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Billing Address: </span>
                                    {billingAddress}
                                </p>
                            </div>
                            <div>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Date:</span> {shortDate(addedDate)}
                                </p>
                                <p style={{ fontSize: "16px" }}>
                                    <span style={{ fontWeight: "600" }}>Date Due:</span> {shortDate(dueDate)}
                                </p>
                            </div>
                        </div>

                        <div>
                            <table className={styles.invTablePrint} style={{ width: "100%" }}>
                                <tr>
                                    <th style={{ whiteSpace: "nowrap", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Order ID</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Description</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Unit Chrg</th>
                                    <th style={{ width: "100%", textAlign: "left", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Qty.</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Charge</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Adjustments</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Tax</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Total</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Payments</th>
                                    <th style={{ textAlign: "center", padding: "5px", fontSize: "12px", backgroundColor: "#ddebf8", color: "#002867" }}>Balance</th>
                                </tr>
                                {chargesList.map((charge, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td style={{ textAlign: "center" }}>
                                                    <strong>{charge.numOrderID}</strong>
                                                </td>
                                                <td>
                                                    <p style={{ margin: "0" }}>
                                                        <span style={charge.varServiceOrProduct === "S" ? { color: "#5178bb", fontWeight: "600" } : { color: "#88bb51", fontWeight: "600" }}>
                                                            ({charge.varServiceOrProduct}) &nbsp;
                                                        </span>
                                                        {charge.varServiceProductDescription}{" "}
                                                        {charge.numPetID === -1 ? null : charge.numPetID === null ? null : (
                                                            <span>
                                                                Pet: (<span>{charge.varPetName}</span>)
                                                            </span>
                                                        )}
                                                    </p>
                                                </td>
                                                <td style={{ textAlign: "right" }}>${charge.decChargeAmount}</td>
                                                <td style={{ textAlign: "center" }}>{charge.numQuantity}</td>

                                                <td style={{ textAlign: "right" }}>${charge.decChargeAmt}</td>
                                                <td style={{ textAlign: "right" }}>(${charge.totalAdjustments})</td>
                                                <td style={{ textAlign: "right" }}>${charge.decTaxAmt}</td>
                                                <td style={{ textAlign: "right" }}>${(charge?.decChargeAmt - charge?.totalAdjustments + charge?.decTaxAmt).toFixed(2)}</td>
                                                <td style={{ textAlign: "right" }}>(${charge.totalPayments})</td>
                                                <td style={{ textAlign: "right" }}>${parseFloat(charge.remaining).toFixed(2)}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </table>
                        </div>

                        <div style={{ marginTop: "15px" }}>
                            <table style={{ width: "100%" }}>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867" }}>
                                        Tax Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "14px", color: "#002867" }}>${parseFloat(totTax.toString()).toFixed(2)}</th>
                                </tr>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "15px", textAlign: "left", color: "#002867" }}>
                                        Total Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "15px", color: "#002867" }}>${parseFloat((totPaid + totRemain).toString()).toFixed(2)}</th>
                                </tr>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "14px", textAlign: "left", color: "#002867" }}>
                                        Paid Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "14px", color: "#002867" }}>${parseFloat(totPaid.toString()).toFixed(2)}</th>
                                </tr>
                                <tr style={{ backgroundColor: "#ddebf8" }}>
                                    <th colSpan={5} style={{ width: "100%", fontSize: "15px", textAlign: "left", color: "#002867" }}>
                                        Balance Amount
                                    </th>
                                    <th style={{ textAlign: "right", fontSize: "15px", color: "#002867" }}>${parseFloat(totRemain.toString()).toFixed(2)}</th>
                                </tr>
                            </table>
                        </div>

                        <div style={{ backgroundColor: "#f3f4f6", marginTop: "30px", borderRadius: "15px" }}>
                            <p style={{ fontSize: "12px", fontWeight: "600", width: "100%", padding: "5px", textAlign: "center" }}>Thank you for your Business</p>
                        </div>
                        <p style={{ textAlign: "center", borderRadius: "15px", padding: "5px", backgroundColor: "#f3f4f6", fontSize: "10px" }}>
                            Pet Tech, 39, Berry Hill Road, Syosett 11791&nbsp;&nbsp;|&nbsp;&nbsp;New York&nbsp;&nbsp;|&nbsp;&nbsp;(456) 789-0000&nbsp;&nbsp;|&nbsp;&nbsp;info@esypet.net
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerInvoices
