import moment from "moment"

export const addMinutes = (timestamp, minutesToAdd) => {
    return moment(timestamp).add(minutesToAdd, "minutes").format("YYYY-MM-DDThh:mm:ss")
}

export const calculateWeeklyTiming = (startTimestamp) => {
    //let updatedDate = moment(startTimestamp).set({ 'year': moment().year(), 'month': moment().month(), 'weekday': moment(startTimestamp).weekday() })
    let updatedDate = moment(startTimestamp).set({ year: moment(startTimestamp).year(), month: moment(startTimestamp).month(), weekday: moment(startTimestamp).weekday() })
    return updatedDate.format("YYYY-MM-DDTHH:mm:ss")
}

export const formatDateTime = (startTimestamp) => {
    let time = moment(startTimestamp)
    return time.format("YYYY-MM-DDTHH:mm:ss")
}

export const convertCalTime = (dateTime) => {
    const formattedTime = moment.tz(dateTime, "America/Chicago").format("YYYY-MM-DDTHH:mm:ss")
    return formattedTime
}

export const getMinutesDuration = (startTimestamp, endTimestamp) => {
    let st = moment(startTimestamp)
    let end = moment(endTimestamp)
    let duration = moment.duration(end.diff(st))
    let diff = duration.asMinutes()

    return diff
}

export const formatDateTimeScheduler = (timestamp) => {
    let timestampAfterTrim = timestamp.substring(0, timestamp.length - 10)
    let time = moment(timestampAfterTrim)

    return time.format("YYYY-MM-DDTHH:mm:ss")
}

export const dateAndTime = (inputDate) => {
    const dateObj = moment(inputDate)

    const formattedDate = dateObj.format("MMM D, YYYY")
    const formattedTime = dateObj.format("ddd [at] h:mm A")

    return {
        date: formattedDate,
        time: formattedTime,
    }
}

export const formatToLocal = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString()
}

export const getDayName = (inputDate) => {
    // Try to parse the input date string using Moment.js
    const momentDate = moment(inputDate, "YYYY-MM-DD")

    // Check if the parsed date is valid
    if (!momentDate.isValid()) {
        console.error("Invalid date")
        return null
    }

    // Get the abbreviated day name
    const abbreviatedDayName = momentDate.format("ddd")

    return abbreviatedDayName
}

export const formatDateDisplay = (dateTimeStr) => {
    // Parse the input date-time string using Moment.js
    const dateTime = moment(dateTimeStr)

    // Format the date and time string
    const formattedDateTime = dateTime.format("YY/MM/DD h.mmA")

    return formattedDateTime
}
