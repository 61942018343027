import { useEffect, useState } from "react"
import { Avatar, Button, CloseButton, Chip } from "../../components"
import { DetailedView, Modal } from "../../components/components"
import { ToastContainer, toast } from "react-toastify"
import { axiosPrivate } from "../../apis/axios"

const ViewCustomerApp = (props: { customerID; isOpenModal; setIsOpenModal: CallableFunction }) => {
    const [list, setList] = useState(null) as any
    const [newHeaders, setNewHeaders] = useState({}) as any
    const [isLoading, setIsLoading] = useState(false) as any

    async function getList(objectName) {
        const result = await axiosPrivate.get(`${objectName}/GetByID/${props.customerID}`)
        return result
    }
    async function getUpdatedHeaders(objectName) {
        const result = await axiosPrivate.get(`Label/${objectName}`)
        return result
    }

    useEffect(() => {
        if (props.isOpenModal === true) {
            getList("Customer")
                .then((response) => {
                    console.log("setList", response?.data?.customer)

                    setList(response?.data?.customer)
                    /* Get Label Configs */
                    getUpdatedHeaders("Customer")
                        .then((responseCustomer) => {
                            let customerHeaders = responseCustomer?.data

                            getUpdatedHeaders("Pet")
                                .then((responsePet) => {
                                    const header = {
                                        customer: customerHeaders,
                                        pet: responsePet?.data,
                                    }
                                    setNewHeaders(header)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                    setIsLoading(false)
                })
        }
    }, [props?.isOpenModal])

    return (
        <div>
            <Modal open={props.isOpenModal} backgroundColor={"#F9FAFB"}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>Customer Profile</p>
                        <CloseButton onClick={() => props.setIsOpenModal(false)} />
                    </div>

                    <div style={{ height: "500px", overflowY: "scroll" }}>
                        <DetailedView type={"customer"} labels={newHeaders} data={list} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ViewCustomerApp
