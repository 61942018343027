/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "numPetPersonalityID": 1,
    "varPetPersonalityName": "Aggressive with animals",
    "varPetPersonalityDescription": null,
    "bitPetPersonalityActive": true
*/

export const Personality: {} = {
    tableName: "1.3 - Pet Behaviors",
    hiddenColumns: ["numPersonalityID"],
    pdfTemplate: ["varPersonalityName", "varPersonalityDescription", "bitIsWarning", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varPersonalityName",
        },
        {
            Header: "Description",
            accessor: "varPersonalityDescription",
        },
        {
            Header: "Has Warning",
            accessor: "bitIsWarning",
            Cell: (props) => <p>{props?.cell?.value ? "Yes" : "No"}</p>,
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numPersonalityID",
            accessor: "numPersonalityID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numPersonalityID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varPersonalityName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPersonalityDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitIsWarning",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
