import { useEffect, useState } from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { Table } from "../../../components/components"
import { PetBreed } from "../../../components/Table/templates/Library/PetBreed"
import { Breed } from "../../../components/Table/templates/Library/Breed"
import { AddressType } from "../../../components/Table/templates/Library/AddressType"
import { Department } from "../../../components/Table/templates/Library/Department"
import { ContactType } from "../../../components/Table/templates/Library/ContactType"
import { PetColor } from "../../../components/Table/templates/Library/PetColor"
import { Personality } from "../../../components/Table/templates/Library/Personality"
import { PetType } from "../../../components/Table/templates/Library/PetType"
import { PetSize } from "../../../components/Table/templates/Library/PetSize"
import { PetVet } from "../../../components/Table/templates/Library/PetVet"
import { ZipCode } from "../../../components/Table/templates/Library/ZipCode"
import { ServiceItemsLibrary } from "../../../components/Table/templates/Library/ServiceItemsLibrary"
import { UserQuestion } from "../../../components/Table/templates/Library/UserQuestion"
import { Occupation } from "../../../components/Table/templates/Library/Occupation"
import { Specialization } from "../../../components/Table/templates/Library/Specialization"
import { MedicalCondition } from "../../../components/Table/templates/Library/MedicalCondition"
import { Salutation } from "../../../components/Table/templates/Library/Salutation"
import { ContactPerson } from "../../../components/Table/templates/Library/ContactPerson"
import { Vaccine } from "../../../components/Table/templates/Library/Vaccine"
import { salesItemsLibrary } from "../../../components/Table/templates/Library/salesItemsLibrary"
import { Blade } from "../../../components/Table/templates/Library/Blade"
import { Comb } from "../../../components/Table/templates/Library/Comb"
import { Brush } from "../../../components/Table/templates/Library/Brush"
import { Shampoo } from "../../../components/Table/templates/Library/Shampoo"
import { Style } from "../../../components/Table/templates/Library/Style"
import { WellnessCheckConcern } from "../../../components/Table/templates/Library/WellnessCheckConcern"

import { useParams } from "react-router-dom"
import { axiosPrivate } from "../../../apis/axios"
import { SalesItems } from "../../../components/Table/templates/Library/SalesItems"
import { WaiverDocument } from "../../../components/Table/templates/Library/WaiverDocument"
import { ServiceItems } from "../../../components/Table/templates/Library/ServiceItems"
import { ServiceGroup } from "../../../components/Table/templates/Library/ServiceGroup"
import { TimeOfDay } from "../../../components/Table/templates/Library/TimeOfDay"
import GeneralPriceList from "../../../components/Table/templates/Library/GeneralPriceList/GeneralPriceList"
import ValidatePermission from "../../../components/ValidatePermission/ValidatePermission"
import useAuth from "../../../hooks/useAuth"
import SpecialPriceList from "../../../components/Table/templates/Library/SpecialPriceList/SpecialPriceList"
import Packages from "../../../components/Table/templates/Library/Packages/Packages"


function Library() {
    const [isLoading, setIsLoading] = useState(false)

    let { name } = useParams() as any
    let { auth } = useAuth() as any

    const templates = {
        PetBreed: PetBreed,
        Breed: Breed,
        AddressType: AddressType,
        ZipCode: ZipCode,
        PetColor: PetColor,
        PetType: PetType,
        PetSize: PetSize,
        ServiceItems: ServiceItems,
        ServiceItemsLibrary: ServiceItemsLibrary,
        ServiceGroup: ServiceGroup,
        ContactType: ContactType,
        Department: Department,
        Personality: Personality,
        PetVet: PetVet,
        ScheduleEvents: AddressType,
        UserQuestion: UserQuestion,
        Occupation: Occupation,
        MedicalCondition: MedicalCondition,
        Specialization: Specialization,
        ContactPerson: ContactPerson,
        Salutation: Salutation,
        Vaccine: Vaccine,
        SalesItemsLibrary: salesItemsLibrary,
        SalesItems: SalesItems,
        Blade: Blade,
        Comb: Comb,
        Brush: Brush,
        Shampoo: Shampoo,
        Style: Style,
        WaiverDocument: WaiverDocument,
        TimeOfDay: TimeOfDay,
    }

    const permissions = {
        PetType: "07.04.01 - Settings / Library / 1.1 - Pet Types",
        PetSize: "07.04.02 - Settings / Library / 1.2 - Pet Sizes",
        Personality: "07.04.03 - Settings / Library / 1.3 - Pet Behaviors",
        PetColor: "07.04.04 - Settings / Library / 1.4 - Pet Colors",
        MedicalCondition: "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns",
        Style: "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles",
        Breed: "07.04.07 - Settings / Library / 1.7 - Pure Breeds",
        PetBreed: "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)",
        AddressType: "07.04.09 - Settings / Library / 2.1 - Address Types",
        ContactType: "07.04.10 - Settings / Library / 2.2 - Contact Types",
        Salutation: "07.04.11 - Settings / Library / 2.3 - Salutations",
        ContactPerson: "07.04.12 - Settings / Library / 2.4 - Contact Person Type",
        UserQuestion: "07.04.13 - Settings / Library / 2.5 - Security Questions",
        ZipCode: "07.04.14 - Settings / Library / 2.6 - Zip Codes",
        Department: "07.04.15 - Settings / Library / 3.1 - Departments",
        Occupation: "07.04.16 - Settings / Library / 3.2 - Job Roles",
        Specialization: "07.04.17 - Settings / Library / 3.3 - Groomer Specializations",
        Brush: "07.04.18 - Settings / Library / 4.1 - Brush Types",
        Blade: "07.04.19 - Settings / Library / 4.2 - Blade Sizes",
        Comb: "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes",
        Shampoo: "07.04.21 - Settings / Library / 4.4 - Shampoo Types",
        SalesItemsLibrary: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
        SalesItems: "07.04.23 - Settings / Library / 5.2 - Sales Items",
        ServiceItemsLibrary: "07.04.24 - Settings / Library / 5.3 - Service Items Library",
        TimeOfDay: "07.04.25 - Settings / Library / 5.4 - Time of Day",
        GeneralPriceList: "07.04.26 - Settings / Library / 5.5 - General Price List",
        SpecialPriceList: "07.04.30 - Settings / Library / 5.6 - Special Price List",
        Packages: "07.04.31 - Settings / Library / 5.7 - Packages",
        Vaccine: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
        PetVet: "07.04.28 - Settings / Library / 6.2 - Vets",
        WaiverDocument: "07.04.29 - Settings / Library / 6.3 - Waiver Documents",
    }

    const [data, setData] = useState([])

    async function getData(objectName, signal) {
        console.log(objectName)

        const result = await axiosPrivate.get(`${name}/GetAll`, {
            signal: signal,
        })
        return result.data[objectName.charAt(0).toLowerCase() + objectName.slice(1)]
    }

    const fetchData = (objectName, signal) => {
        setIsLoading(true)

        getData(objectName, signal)
            .then((res) => {
                setData(res)
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                    setIsLoading(false)
                    toast.error("Something went wrong!")
                    setData([])
                }
            })
    }

    // TODO - INCOMPLETE
    useEffect(() => {
        const controller = new AbortController()
        if (name !== "GeneralPriceList"  && name !== "SpecialPriceList" && name !== "Packages") {
            fetchData(name, controller.signal)
        }
        return () => {
            controller.abort()
        }
    }, [name])

    const genName = (objectName) => {
        return objectName.replace(/([a-z])([A-Z])/g, "$1 $2")
    }

    const reloadData = (e) => {
        if (e === true) {
            const controller = new AbortController()
            if (name !== "GeneralPriceList" && name !== "SpecialPriceList" && name !== "Packages") {
                fetchData(name, controller.signal)
            }
            return () => {
                controller.abort()
            }
        }
    }

    const getLibraryPermission = (name, option) => {
        return auth?.permissions?.[permissions?.[name]]?.[option]?.IsView
    }

    return (
        <>
        {name === "GeneralPriceList" ? (
            <ValidatePermission allowedModules={[permissions?.[name]]}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", margin: "0 3rem" }}>
                        <h2>5.5 - General Price List</h2>
                        {/* <GeneralPriceList
                            isDownloadPDF={getLibraryPermission(name, "Download PDF")}
                            importExport={getLibraryPermission(name, "Import / Export")}
                            canEdit={getLibraryPermission(name, "Edit")}
                            newAction={getLibraryPermission(name, "Add New")}
                        /> */}
                        <GeneralPriceList />
                    </div>
                </div>
            </ValidatePermission>
        ) : name === "SpecialPriceList" ? (
          
            <div style={{ display: "flex" }}>
                <div style={{ width: "100%", margin: "0 3rem" }}>
                    <h2>5.6 - Special Price List</h2>
                    <SpecialPriceList />
                </div>
            </div>
    
        ) : name === "Packages" ? (
          
            <div style={{ display: "flex" }}>
                <div style={{ width: "100%", margin: "0 3rem" }}>
                    <h2>5.7 - Packages</h2>
                    <Packages/>
                </div>
            </div>
    
        ) : (
            <div style={{ display: "flex" }}>
                <div style={{ width: "100%", margin: "0 3rem" }}>
                    <ValidatePermission allowedModules={[permissions?.[name]]}>
                        {templates?.[name] !== undefined ? (
                            <>
                                <h2>{templates?.[name].tableName}</h2>
                                <Table
                                    canEdit={getLibraryPermission(name, "Edit")}
                                    importExport={getLibraryPermission(name, "Import / Export")}
                                    objectName={name}
                                    loadingData={isLoading}
                                    activateGrid={false}
                                    downloadPDF={getLibraryPermission(name, "Download PDF")}
                                    template={templates[name]}
                                    tableData={data}
                                    boldHeaders={true}
                                    newAction={getLibraryPermission(name, "Add New")}
                                    saveSuccess={(e) => {
                                        reloadData(e);
                                    }}
                                />
                            </>
                        ) : null}
                    </ValidatePermission>
                </div>
            </div>
        )}
    </>
    )
}

export default Library
