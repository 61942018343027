import { useState } from "react"
import { Outlet } from "react-router-dom"

import { Menu } from "../../components/components"

const Directory = (props: { children? }) => {
    const [linkList] = useState([
        {
            name: "Customer",
            to: "customer",
            permissionModule: "03.01 - Directory / Customer",
        },
        {
            name: "Pet",
            to: "pet",
            permissionModule: "03.02 - Directory / Pet",
        },
        {
            name: "Salon",
            to: "salon",
            permissionModule: "03.03 - Directory / Salon",
        },
        {
            name: "Employee",
            to: "employee",
            permissionModule: "03.04 - Directory / Employee",
        },
        {
            name: "Appointments",
            to: "appointments",
            permissionModule: "03.05 - Directory / Appointments",
        },
        {
            name: "Booking Inquiry",
            to: "inquiry",
            permissionModule: "03.06 - Directory / Booking Inquiry",
        },
        {
            name: "Social Sharing",
            to: "new-booking-inquiry",
            permissionModule: "03.07 - Directory / New Booking Inquiry",
        },
    ])

    return (
        <div>
            <div style={{ display: "flex" }}>
                {linkList.length > 0 ? <Menu linkList={linkList} /> : <div>Loading...</div>}
                <div style={{ width: "100%", height: "calc(100vh - 90px)", overflowY: "scroll" }}>
                    <Outlet />
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Directory
