import { Button } from "../../../components"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../../../hooks/useAuth"
import { Avatar } from "../../../components"
import AdvancedDropDown from "../../../components/AdvancedDropDown/AdvancedDropDown"
import styles from "../Login.module.scss"
import { axiosPrivate } from "../../../apis/axios"
import { ToastContainer, toast } from "react-toastify"
import { CircularProgress } from "@mui/material"

const Welcome = (props) => {
    const navigate = useNavigate()

    const [visited, setVisited] = useState(false)
    const [loading, setLoading] = useState(false)

    const { auth, setAuth } = useAuth() as any

    const [locationList, setLocationList] = useState([]) as any
    const locationRef = useRef<any>()

    const [roleList, setRoleList] = useState([]) as any
    const roleRef = useRef<any>()

    const [location, setLocation] = useState(0)
    const [role, setRole] = useState(0)
    const [rolesSetup, setRolesSetup] = useState(false)

    const handleNavigate = () => {
        if (location > 0) {
            if (rolesSetup) {
                navigate("/", { replace: true })
            } else {
                toast.error("You do not have Roles assigned to you yet for this location! Contact Administrator.")
            }
        } else {
            toast.info("Please select a location to continue.")
        }
    }

    useEffect(() => {
        if (locationList?.length === 1 && locationList[0]?.location?.LocationID > 0) {
            setAuth((prev) => {
                return { ...prev, currentLocation: { label: locationList[0]?.location?.Name, value: locationList[0]?.location?.LocationID } }
            })
            setLocation(locationList[0]?.location?.LocationID)
        }
    }, [locationList])

    // Construct Tree V2 - Returns ALL OBJs
    const constructTree = (unsortedList) => {
        const transformedData = unsortedList.reduce((acc, curr) => {
            if (!acc[curr.varLocationName]) {
                acc[curr.varLocationName] = {
                    location: {
                        LocationID: curr.LocationID,
                        Name: curr.varLocationName,
                    },
                    groups: {},
                }
            }

            if (!acc[curr.varLocationName].groups[curr.numUserRoleGroupID]) {
                acc[curr.varLocationName].groups[curr.numUserRoleGroupID] = {
                    numUserRoleGroupID: curr.numUserRoleGroupID,
                    varUserRoleGroupName: curr.varUserRoleGroupName,
                    roles: {},
                }
            }

            if (!acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID]) {
                acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID] = {
                    numUserRoleID: curr.numUserRoleID,
                    varUserRoleName: curr.varUserRoleName,
                    IsActive: curr.IsActive,
                    modules: {},
                }
            }

            if (!acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID]) {
                acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID] = {
                    ModuleID: curr.ModuleID,
                    ModuleName: curr.ModuleName,
                    systemmodules: {},
                }
            }

            acc[curr.varLocationName].groups[curr.numUserRoleGroupID].roles[curr.numUserRoleID].modules[curr.ModuleID].systemmodules[curr.SystemModuleListID] = {
                SystemModuleListID: curr.SystemModuleListID,
                SystemModuleListName: curr.SystemModuleListName,
                settings: {
                    ID: curr.ID,
                    IsView: curr.IsView,
                    IsUpdate: curr.IsUpdate,
                    IsDelete: curr.IsDelete,
                    varEmployeeFirstName: curr.varEmployeeFirstName,
                    varEmployeeLastName: curr.varEmployeeLastName,
                },
            }

            return acc
        }, {})

        return transformedData
    }

    useEffect(() => {
        const hasVisited = localStorage.getItem("hasVisitedWelcome")

        if (hasVisited) {
            navigate("/booking", { replace: true })
        } else {
            localStorage.setItem("hasVisitedWelcome", "true")
            setVisited(true)
        }
    }, [navigate])

    async function getPermissions(employeeID) {
        console.log("emp id", employeeID)
        if (employeeID == "9999999") {
            return {
                data: [
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 1,
                        ModuleName: "01 - Dashboard",
                        SystemModuleListID: 1,
                        SystemModuleListName: "Dashboard",
                        ID: 1,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 10,
                        ModuleName: "02 - Schedule",
                        SystemModuleListID: 2,
                        SystemModuleListName: "Add New Customer",
                        ID: 2,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 10,
                        ModuleName: "02 - Schedule",
                        SystemModuleListID: 3,
                        SystemModuleListName: "Add New Pet",
                        ID: 3,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 11,
                        ModuleName: "05 - Groomer",
                        SystemModuleListID: 57,
                        SystemModuleListName: "Groomer",
                        ID: 43,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 13,
                        ModuleName: "06 - Reporting",
                        SystemModuleListID: 58,
                        SystemModuleListName: "Reporting",
                        ID: 44,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 14,
                        ModuleName: "07.01 - Settings / Label Configuration",
                        SystemModuleListID: 51,
                        SystemModuleListName: "Salon",
                        ID: 49,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 14,
                        ModuleName: "07.01 - Settings / Label Configuration",
                        SystemModuleListID: 52,
                        SystemModuleListName: "Pet",
                        ID: 48,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 14,
                        ModuleName: "07.01 - Settings / Label Configuration",
                        SystemModuleListID: 53,
                        SystemModuleListName: "Customer",
                        ID: 46,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 14,
                        ModuleName: "07.01 - Settings / Label Configuration",
                        SystemModuleListID: 54,
                        SystemModuleListName: "Employee",
                        ID: 47,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 15,
                        ModuleName: "03.01 - Directory / Customer",
                        SystemModuleListID: 22,
                        SystemModuleListName: "Download XLSX",
                        ID: 24,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 15,
                        ModuleName: "03.01 - Directory / Customer",
                        SystemModuleListID: 23,
                        SystemModuleListName: "Quick Message",
                        ID: 25,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 15,
                        ModuleName: "03.01 - Directory / Customer",
                        SystemModuleListID: 24,
                        SystemModuleListName: "Download PDF",
                        ID: 23,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 16,
                        ModuleName: "03.02 - Directory / Pet",
                        SystemModuleListID: 25,
                        SystemModuleListName: "Download XLSX",
                        ID: 27,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 16,
                        ModuleName: "03.02 - Directory / Pet",
                        SystemModuleListID: 26,
                        SystemModuleListName: "Quick Message",
                        ID: 28,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 16,
                        ModuleName: "03.02 - Directory / Pet",
                        SystemModuleListID: 27,
                        SystemModuleListName: "Download PDF",
                        ID: 26,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 17,
                        ModuleName: "03.03 - Directory / Salon",
                        SystemModuleListID: 28,
                        SystemModuleListName: "Download XLSX",
                        ID: 30,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 17,
                        ModuleName: "03.03 - Directory / Salon",
                        SystemModuleListID: 29,
                        SystemModuleListName: "Quick Message",
                        ID: 32,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 17,
                        ModuleName: "03.03 - Directory / Salon",
                        SystemModuleListID: 30,
                        SystemModuleListName: "Download PDF",
                        ID: 29,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 17,
                        ModuleName: "03.03 - Directory / Salon",
                        SystemModuleListID: 31,
                        SystemModuleListName: "Map View",
                        ID: 31,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 18,
                        ModuleName: "03.04 - Directory / Employee",
                        SystemModuleListID: 32,
                        SystemModuleListName: "Download XLSX",
                        ID: 34,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 18,
                        ModuleName: "03.04 - Directory / Employee",
                        SystemModuleListID: 33,
                        SystemModuleListName: "Quick Message",
                        ID: 36,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 18,
                        ModuleName: "03.04 - Directory / Employee",
                        SystemModuleListID: 34,
                        SystemModuleListName: "Download PDF",
                        ID: 33,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 18,
                        ModuleName: "03.04 - Directory / Employee",
                        SystemModuleListID: 35,
                        SystemModuleListName: "New Employee",
                        ID: 35,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 19,
                        ModuleName: "03.05 - Directory / Appointments",
                        SystemModuleListID: 36,
                        SystemModuleListName: "Download XLSX",
                        ID: 38,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 19,
                        ModuleName: "03.05 - Directory / Appointments",
                        SystemModuleListID: 37,
                        SystemModuleListName: "Quick Message",
                        ID: 39,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 19,
                        ModuleName: "03.05 - Directory / Appointments",
                        SystemModuleListID: 38,
                        SystemModuleListName: "Download PDF",
                        ID: 37,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 20,
                        ModuleName: "03.06 - Directory / Booking Inquiry",
                        SystemModuleListID: 56,
                        SystemModuleListName: "Calendar",
                        ID: 40,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 21,
                        ModuleName: "04 - Inquiry",
                        SystemModuleListID: 40,
                        SystemModuleListName: "Inquiry",
                        ID: 42,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 22,
                        ModuleName: "02.01 - Schedule / New Booking",
                        SystemModuleListID: 4,
                        SystemModuleListName: "View Map",
                        ID: 8,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 22,
                        ModuleName: "02.01 - Schedule / New Booking",
                        SystemModuleListID: 5,
                        SystemModuleListName: "Add/Edit Services",
                        ID: 5,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 22,
                        ModuleName: "02.01 - Schedule / New Booking",
                        SystemModuleListID: 6,
                        SystemModuleListName: "Add/Edit Products",
                        ID: 4,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 22,
                        ModuleName: "02.01 - Schedule / New Booking",
                        SystemModuleListID: 7,
                        SystemModuleListName: "Customer Invoices",
                        ID: 6,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 22,
                        ModuleName: "02.01 - Schedule / New Booking",
                        SystemModuleListID: 8,
                        SystemModuleListName: "Make Reservation",
                        ID: 7,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 9,
                        SystemModuleListName: "Edit Customer",
                        ID: 11,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 10,
                        SystemModuleListName: "Personal Details",
                        ID: 16,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 11,
                        SystemModuleListName: "Alerts and comments",
                        ID: 10,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 12,
                        SystemModuleListName: "Linked Accounts",
                        ID: 13,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 13,
                        SystemModuleListName: "Payment Details",
                        ID: 15,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 14,
                        SystemModuleListName: "Account Settings",
                        ID: 9,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 15,
                        SystemModuleListName: "Notifications",
                        ID: 14,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 23,
                        ModuleName: "02.02 - Schedule / Customer Profile",
                        SystemModuleListID: 16,
                        SystemModuleListName: "Invoices",
                        ID: 12,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 24,
                        ModuleName: "02.03 - Schedule / Pet Profile",
                        SystemModuleListID: 17,
                        SystemModuleListName: "Edit Pet",
                        ID: 17,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 24,
                        ModuleName: "02.03 - Schedule / Pet Profile",
                        SystemModuleListID: 18,
                        SystemModuleListName: "Personal Details",
                        ID: 20,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 24,
                        ModuleName: "02.03 - Schedule / Pet Profile",
                        SystemModuleListID: 19,
                        SystemModuleListName: "Medical / Vaccination",
                        ID: 18,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 24,
                        ModuleName: "02.03 - Schedule / Pet Profile",
                        SystemModuleListID: 20,
                        SystemModuleListName: "Ownership Transfer",
                        ID: 19,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 24,
                        ModuleName: "02.03 - Schedule / Pet Profile",
                        SystemModuleListID: 21,
                        SystemModuleListName: "Preferences",
                        ID: 21,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 25,
                        ModuleName: "07.02 - Settings / Import - Export Data",
                        SystemModuleListID: 41,
                        SystemModuleListName: "Salon",
                        ID: 53,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 25,
                        ModuleName: "07.02 - Settings / Import - Export Data",
                        SystemModuleListID: 42,
                        SystemModuleListName: "Pet",
                        ID: 52,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 25,
                        ModuleName: "07.02 - Settings / Import - Export Data",
                        SystemModuleListID: 43,
                        SystemModuleListName: "Customer",
                        ID: 50,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 25,
                        ModuleName: "07.02 - Settings / Import - Export Data",
                        SystemModuleListID: 44,
                        SystemModuleListName: "Employee",
                        ID: 51,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 26,
                        ModuleName: "07.03 - Settings / Dir - Lib Data Reset",
                        SystemModuleListID: 45,
                        SystemModuleListName: "Directories",
                        ID: 54,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 26,
                        ModuleName: "07.03 - Settings / Dir - Lib Data Reset",
                        SystemModuleListID: 46,
                        SystemModuleListName: "Libraries",
                        ID: 55,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 27,
                        ModuleName: "07.04 - Settings / Library",
                        SystemModuleListID: 60,
                        SystemModuleListName: "Library",
                        ID: 60,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 28,
                        ModuleName: "07.05 - Settings / User Roles",
                        SystemModuleListID: 61,
                        SystemModuleListName: "User Roles",
                        ID: 61,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 29,
                        ModuleName: "07.06 - Settings / Scheduling",
                        SystemModuleListID: 47,
                        SystemModuleListName: "Events",
                        ID: 56,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 29,
                        ModuleName: "07.06 - Settings / Scheduling",
                        SystemModuleListID: 48,
                        SystemModuleListName: "Schedule",
                        ID: 57,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 30,
                        ModuleName: "07.07 - Settings / Messaging",
                        SystemModuleListID: 49,
                        SystemModuleListName: "Create New Template",
                        ID: 58,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 30,
                        ModuleName: "07.07 - Settings / Messaging",
                        SystemModuleListID: 50,
                        SystemModuleListName: "Edit",
                        ID: 59,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 34,
                        ModuleName: "03 - Directory",
                        SystemModuleListID: 55,
                        SystemModuleListName: "Directory",
                        ID: 22,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 35,
                        ModuleName: "07 - Setting",
                        SystemModuleListID: 59,
                        SystemModuleListName: "Setting",
                        ID: 45,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 36,
                        ModuleName: "03.07 - Directory / New Booking Inquiry",
                        SystemModuleListID: 39,
                        SystemModuleListName: "Map View",
                        ID: 41,
                        IsView: true,
                        IsUpdate: true,
                        IsDelete: true,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 37,
                        ModuleName: "07.04.01 - Settings / Library / 1.1 - Pet Types",
                        SystemModuleListID: 91,
                        SystemModuleListName: "Add New",
                        ID: 99,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 37,
                        ModuleName: "07.04.01 - Settings / Library / 1.1 - Pet Types",
                        SystemModuleListID: 92,
                        SystemModuleListName: "Edit",
                        ID: 101,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 37,
                        ModuleName: "07.04.01 - Settings / Library / 1.1 - Pet Types",
                        SystemModuleListID: 93,
                        SystemModuleListName: "Import / Export",
                        ID: 102,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 37,
                        ModuleName: "07.04.01 - Settings / Library / 1.1 - Pet Types",
                        SystemModuleListID: 94,
                        SystemModuleListName: "Download PDF",
                        ID: 100,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 38,
                        ModuleName: "07.04.02 - Settings / Library / 1.2 - Pet Sizes",
                        SystemModuleListID: 95,
                        SystemModuleListName: "Add New",
                        ID: 103,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 38,
                        ModuleName: "07.04.02 - Settings / Library / 1.2 - Pet Sizes",
                        SystemModuleListID: 96,
                        SystemModuleListName: "Edit",
                        ID: 105,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 38,
                        ModuleName: "07.04.02 - Settings / Library / 1.2 - Pet Sizes",
                        SystemModuleListID: 97,
                        SystemModuleListName: "Import / Export",
                        ID: 106,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 38,
                        ModuleName: "07.04.02 - Settings / Library / 1.2 - Pet Sizes",
                        SystemModuleListID: 98,
                        SystemModuleListName: "Download PDF",
                        ID: 104,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 39,
                        ModuleName: "07.04.03 - Settings / Library / 1.3 - Pet Behaviors",
                        SystemModuleListID: 99,
                        SystemModuleListName: "Add New",
                        ID: 107,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 39,
                        ModuleName: "07.04.03 - Settings / Library / 1.3 - Pet Behaviors",
                        SystemModuleListID: 100,
                        SystemModuleListName: "Edit",
                        ID: 109,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 39,
                        ModuleName: "07.04.03 - Settings / Library / 1.3 - Pet Behaviors",
                        SystemModuleListID: 101,
                        SystemModuleListName: "Import / Export",
                        ID: 110,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 39,
                        ModuleName: "07.04.03 - Settings / Library / 1.3 - Pet Behaviors",
                        SystemModuleListID: 102,
                        SystemModuleListName: "Download PDF",
                        ID: 108,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 40,
                        ModuleName: "07.04.04 - Settings / Library / 1.4 - Pet Colors",
                        SystemModuleListID: 103,
                        SystemModuleListName: "Add New",
                        ID: 111,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 40,
                        ModuleName: "07.04.04 - Settings / Library / 1.4 - Pet Colors",
                        SystemModuleListID: 104,
                        SystemModuleListName: "Edit",
                        ID: 113,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 40,
                        ModuleName: "07.04.04 - Settings / Library / 1.4 - Pet Colors",
                        SystemModuleListID: 105,
                        SystemModuleListName: "Import / Export",
                        ID: 114,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 40,
                        ModuleName: "07.04.04 - Settings / Library / 1.4 - Pet Colors",
                        SystemModuleListID: 106,
                        SystemModuleListName: "Download PDF",
                        ID: 112,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 41,
                        ModuleName: "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns",
                        SystemModuleListID: 107,
                        SystemModuleListName: "Add New",
                        ID: 115,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 41,
                        ModuleName: "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns",
                        SystemModuleListID: 108,
                        SystemModuleListName: "Edit",
                        ID: 117,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 41,
                        ModuleName: "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns",
                        SystemModuleListID: 109,
                        SystemModuleListName: "Import / Export",
                        ID: 118,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 41,
                        ModuleName: "07.04.05 - Settings / Library / 1.5 - Pet Health Concerns",
                        SystemModuleListID: 110,
                        SystemModuleListName: "Download PDF",
                        ID: 116,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 42,
                        ModuleName: "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles",
                        SystemModuleListID: 111,
                        SystemModuleListName: "Add New",
                        ID: 131,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 42,
                        ModuleName: "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles",
                        SystemModuleListID: 112,
                        SystemModuleListName: "Edit",
                        ID: 133,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 42,
                        ModuleName: "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles",
                        SystemModuleListID: 113,
                        SystemModuleListName: "Import / Export",
                        ID: 134,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 42,
                        ModuleName: "07.04.06 - Settings / Library / 1.6 - Pet Grooming Styles",
                        SystemModuleListID: 114,
                        SystemModuleListName: "Download PDF",
                        ID: 132,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 43,
                        ModuleName: "07.04.07 - Settings / Library / 1.7 - Pure Breeds",
                        SystemModuleListID: 115,
                        SystemModuleListName: "Add New",
                        ID: 135,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 43,
                        ModuleName: "07.04.07 - Settings / Library / 1.7 - Pure Breeds",
                        SystemModuleListID: 116,
                        SystemModuleListName: "Edit",
                        ID: 137,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 43,
                        ModuleName: "07.04.07 - Settings / Library / 1.7 - Pure Breeds",
                        SystemModuleListID: 117,
                        SystemModuleListName: "Import / Export",
                        ID: 138,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 43,
                        ModuleName: "07.04.07 - Settings / Library / 1.7 - Pure Breeds",
                        SystemModuleListID: 118,
                        SystemModuleListName: "Download PDF",
                        ID: 136,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 44,
                        ModuleName: "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)",
                        SystemModuleListID: 119,
                        SystemModuleListName: "Add New",
                        ID: 139,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 44,
                        ModuleName: "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)",
                        SystemModuleListID: 120,
                        SystemModuleListName: "Edit",
                        ID: 141,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 44,
                        ModuleName: "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)",
                        SystemModuleListID: 121,
                        SystemModuleListName: "Import / Export",
                        ID: 142,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 44,
                        ModuleName: "07.04.08 - Settings / Library / 1.8 - All Breeds (Pure + Mixed)",
                        SystemModuleListID: 122,
                        SystemModuleListName: "Download PDF",
                        ID: 140,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 45,
                        ModuleName: "07.04.09 - Settings / Library / 2.1 - Address Types",
                        SystemModuleListID: 123,
                        SystemModuleListName: "Add New",
                        ID: 143,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 45,
                        ModuleName: "07.04.09 - Settings / Library / 2.1 - Address Types",
                        SystemModuleListID: 124,
                        SystemModuleListName: "Edit",
                        ID: 145,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 45,
                        ModuleName: "07.04.09 - Settings / Library / 2.1 - Address Types",
                        SystemModuleListID: 125,
                        SystemModuleListName: "Import / Export",
                        ID: 146,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 45,
                        ModuleName: "07.04.09 - Settings / Library / 2.1 - Address Types",
                        SystemModuleListID: 126,
                        SystemModuleListName: "Download PDF",
                        ID: 144,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 46,
                        ModuleName: "07.04.10 - Settings / Library / 2.2 - Contact Types",
                        SystemModuleListID: 127,
                        SystemModuleListName: "Add New",
                        ID: 147,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 46,
                        ModuleName: "07.04.10 - Settings / Library / 2.2 - Contact Types",
                        SystemModuleListID: 128,
                        SystemModuleListName: "Edit",
                        ID: 149,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 46,
                        ModuleName: "07.04.10 - Settings / Library / 2.2 - Contact Types",
                        SystemModuleListID: 129,
                        SystemModuleListName: "Import / Export",
                        ID: 150,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 46,
                        ModuleName: "07.04.10 - Settings / Library / 2.2 - Contact Types",
                        SystemModuleListID: 130,
                        SystemModuleListName: "Download PDF",
                        ID: 148,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 47,
                        ModuleName: "07.04.11 - Settings / Library / 2.3 - Salutations",
                        SystemModuleListID: 131,
                        SystemModuleListName: "Add New",
                        ID: 151,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 47,
                        ModuleName: "07.04.11 - Settings / Library / 2.3 - Salutations",
                        SystemModuleListID: 132,
                        SystemModuleListName: "Edit",
                        ID: 153,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 47,
                        ModuleName: "07.04.11 - Settings / Library / 2.3 - Salutations",
                        SystemModuleListID: 133,
                        SystemModuleListName: "Import / Export",
                        ID: 154,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 47,
                        ModuleName: "07.04.11 - Settings / Library / 2.3 - Salutations",
                        SystemModuleListID: 134,
                        SystemModuleListName: "Download PDF",
                        ID: 152,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 48,
                        ModuleName: "07.04.12 - Settings / Library / 2.4 - Contact Person Type",
                        SystemModuleListID: 135,
                        SystemModuleListName: "Add New",
                        ID: 155,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 48,
                        ModuleName: "07.04.12 - Settings / Library / 2.4 - Contact Person Type",
                        SystemModuleListID: 136,
                        SystemModuleListName: "Edit",
                        ID: 157,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 48,
                        ModuleName: "07.04.12 - Settings / Library / 2.4 - Contact Person Type",
                        SystemModuleListID: 137,
                        SystemModuleListName: "Import / Export",
                        ID: 158,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 48,
                        ModuleName: "07.04.12 - Settings / Library / 2.4 - Contact Person Type",
                        SystemModuleListID: 138,
                        SystemModuleListName: "Download PDF",
                        ID: 156,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 49,
                        ModuleName: "07.04.13 - Settings / Library / 2.5 - Security Questions",
                        SystemModuleListID: 139,
                        SystemModuleListName: "Add New",
                        ID: 159,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 49,
                        ModuleName: "07.04.13 - Settings / Library / 2.5 - Security Questions",
                        SystemModuleListID: 140,
                        SystemModuleListName: "Edit",
                        ID: 161,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 49,
                        ModuleName: "07.04.13 - Settings / Library / 2.5 - Security Questions",
                        SystemModuleListID: 141,
                        SystemModuleListName: "Import / Export",
                        ID: 162,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 49,
                        ModuleName: "07.04.13 - Settings / Library / 2.5 - Security Questions",
                        SystemModuleListID: 142,
                        SystemModuleListName: "Download PDF",
                        ID: 160,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 50,
                        ModuleName: "07.04.14 - Settings / Library / 2.6 - Zip Codes",
                        SystemModuleListID: 143,
                        SystemModuleListName: "Add New",
                        ID: 163,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 50,
                        ModuleName: "07.04.14 - Settings / Library / 2.6 - Zip Codes",
                        SystemModuleListID: 144,
                        SystemModuleListName: "Edit",
                        ID: 165,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 50,
                        ModuleName: "07.04.14 - Settings / Library / 2.6 - Zip Codes",
                        SystemModuleListID: 145,
                        SystemModuleListName: "Import / Export",
                        ID: 166,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 50,
                        ModuleName: "07.04.14 - Settings / Library / 2.6 - Zip Codes",
                        SystemModuleListID: 146,
                        SystemModuleListName: "Download PDF",
                        ID: 164,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 51,
                        ModuleName: "07.04.15 - Settings / Library / 3.1 - Departments",
                        SystemModuleListID: 147,
                        SystemModuleListName: "Add New",
                        ID: 167,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 51,
                        ModuleName: "07.04.15 - Settings / Library / 3.1 - Departments",
                        SystemModuleListID: 148,
                        SystemModuleListName: "Edit",
                        ID: 169,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 51,
                        ModuleName: "07.04.15 - Settings / Library / 3.1 - Departments",
                        SystemModuleListID: 149,
                        SystemModuleListName: "Import / Export",
                        ID: 170,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 51,
                        ModuleName: "07.04.15 - Settings / Library / 3.1 - Departments",
                        SystemModuleListID: 150,
                        SystemModuleListName: "Download PDF",
                        ID: 168,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 52,
                        ModuleName: "07.04.16 - Settings / Library / 3.2 - Job Roles",
                        SystemModuleListID: 151,
                        SystemModuleListName: "Add New",
                        ID: 171,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 52,
                        ModuleName: "07.04.16 - Settings / Library / 3.2 - Job Roles",
                        SystemModuleListID: 152,
                        SystemModuleListName: "Edit",
                        ID: 173,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 52,
                        ModuleName: "07.04.16 - Settings / Library / 3.2 - Job Roles",
                        SystemModuleListID: 153,
                        SystemModuleListName: "Import / Export",
                        ID: 174,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 52,
                        ModuleName: "07.04.16 - Settings / Library / 3.2 - Job Roles",
                        SystemModuleListID: 154,
                        SystemModuleListName: "Download PDF",
                        ID: 172,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 53,
                        ModuleName: "07.04.17 - Settings / Library / 3.3 - Groomer Specializations",
                        SystemModuleListID: 155,
                        SystemModuleListName: "Add New",
                        ID: 175,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 53,
                        ModuleName: "07.04.17 - Settings / Library / 3.3 - Groomer Specializations",
                        SystemModuleListID: 156,
                        SystemModuleListName: "Edit",
                        ID: 177,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 53,
                        ModuleName: "07.04.17 - Settings / Library / 3.3 - Groomer Specializations",
                        SystemModuleListID: 157,
                        SystemModuleListName: "Import / Export",
                        ID: 178,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 53,
                        ModuleName: "07.04.17 - Settings / Library / 3.3 - Groomer Specializations",
                        SystemModuleListID: 158,
                        SystemModuleListName: "Download PDF",
                        ID: 176,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 54,
                        ModuleName: "07.04.18 - Settings / Library / 4.1 - Brush Types",
                        SystemModuleListID: 159,
                        SystemModuleListName: "Add New",
                        ID: 179,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 54,
                        ModuleName: "07.04.18 - Settings / Library / 4.1 - Brush Types",
                        SystemModuleListID: 160,
                        SystemModuleListName: "Edit",
                        ID: 181,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 54,
                        ModuleName: "07.04.18 - Settings / Library / 4.1 - Brush Types",
                        SystemModuleListID: 161,
                        SystemModuleListName: "Import / Export",
                        ID: 182,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 54,
                        ModuleName: "07.04.18 - Settings / Library / 4.1 - Brush Types",
                        SystemModuleListID: 162,
                        SystemModuleListName: "Download PDF",
                        ID: 180,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 55,
                        ModuleName: "07.04.19 - Settings / Library / 4.2 - Blade Sizes",
                        SystemModuleListID: 163,
                        SystemModuleListName: "Add New",
                        ID: 183,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 55,
                        ModuleName: "07.04.19 - Settings / Library / 4.2 - Blade Sizes",
                        SystemModuleListID: 164,
                        SystemModuleListName: "Edit",
                        ID: 185,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 55,
                        ModuleName: "07.04.19 - Settings / Library / 4.2 - Blade Sizes",
                        SystemModuleListID: 165,
                        SystemModuleListName: "Import / Export",
                        ID: 186,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 55,
                        ModuleName: "07.04.19 - Settings / Library / 4.2 - Blade Sizes",
                        SystemModuleListID: 166,
                        SystemModuleListName: "Download PDF",
                        ID: 184,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 56,
                        ModuleName: "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes",
                        SystemModuleListID: 167,
                        SystemModuleListName: "Add New",
                        ID: 187,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 56,
                        ModuleName: "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes",
                        SystemModuleListID: 168,
                        SystemModuleListName: "Edit",
                        ID: 189,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 56,
                        ModuleName: "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes",
                        SystemModuleListID: 169,
                        SystemModuleListName: "Import / Export",
                        ID: 190,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 56,
                        ModuleName: "07.04.20 - Settings / Library / 4.3 - Comb Attachment Sizes",
                        SystemModuleListID: 170,
                        SystemModuleListName: "Download PDF",
                        ID: 188,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 57,
                        ModuleName: "07.04.21 - Settings / Library / 4.4 - Shampoo Types",
                        SystemModuleListID: 171,
                        SystemModuleListName: "Add New",
                        ID: 191,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 57,
                        ModuleName: "07.04.21 - Settings / Library / 4.4 - Shampoo Types",
                        SystemModuleListID: 172,
                        SystemModuleListName: "Edit",
                        ID: 193,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 57,
                        ModuleName: "07.04.21 - Settings / Library / 4.4 - Shampoo Types",
                        SystemModuleListID: 173,
                        SystemModuleListName: "Import / Export",
                        ID: 194,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 57,
                        ModuleName: "07.04.21 - Settings / Library / 4.4 - Shampoo Types",
                        SystemModuleListID: 174,
                        SystemModuleListName: "Download PDF",
                        ID: 192,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 58,
                        ModuleName: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
                        SystemModuleListID: 175,
                        SystemModuleListName: "Add New",
                        ID: 195,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 58,
                        ModuleName: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
                        SystemModuleListID: 176,
                        SystemModuleListName: "Edit",
                        ID: 197,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 58,
                        ModuleName: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
                        SystemModuleListID: 177,
                        SystemModuleListName: "Import / Export",
                        ID: 198,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 58,
                        ModuleName: "07.04.22 - Settings / Library / 5.1 - Sales Items Library",
                        SystemModuleListID: 178,
                        SystemModuleListName: "Download PDF",
                        ID: 196,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 59,
                        ModuleName: "07.04.23 - Settings / Library / 5.2 - Sales Items",
                        SystemModuleListID: 179,
                        SystemModuleListName: "Add New",
                        ID: 199,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 59,
                        ModuleName: "07.04.23 - Settings / Library / 5.2 - Sales Items",
                        SystemModuleListID: 180,
                        SystemModuleListName: "Edit",
                        ID: 201,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 59,
                        ModuleName: "07.04.23 - Settings / Library / 5.2 - Sales Items",
                        SystemModuleListID: 181,
                        SystemModuleListName: "Import / Export",
                        ID: 202,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 59,
                        ModuleName: "07.04.23 - Settings / Library / 5.2 - Sales Items",
                        SystemModuleListID: 182,
                        SystemModuleListName: "Download PDF",
                        ID: 200,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 60,
                        ModuleName: "07.04.24 - Settings / Library / 5.3 - Service Items Library",
                        SystemModuleListID: 183,
                        SystemModuleListName: "Add New",
                        ID: 203,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 60,
                        ModuleName: "07.04.24 - Settings / Library / 5.3 - Service Items Library",
                        SystemModuleListID: 184,
                        SystemModuleListName: "Edit",
                        ID: 205,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 60,
                        ModuleName: "07.04.24 - Settings / Library / 5.3 - Service Items Library",
                        SystemModuleListID: 185,
                        SystemModuleListName: "Import / Export",
                        ID: 206,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 60,
                        ModuleName: "07.04.24 - Settings / Library / 5.3 - Service Items Library",
                        SystemModuleListID: 186,
                        SystemModuleListName: "Download PDF",
                        ID: 204,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 61,
                        ModuleName: "07.04.25 - Settings / Library / 5.4 - Time of Day",
                        SystemModuleListID: 187,
                        SystemModuleListName: "Add New",
                        ID: 207,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 61,
                        ModuleName: "07.04.25 - Settings / Library / 5.4 - Time of Day",
                        SystemModuleListID: 188,
                        SystemModuleListName: "Edit",
                        ID: 209,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 61,
                        ModuleName: "07.04.25 - Settings / Library / 5.4 - Time of Day",
                        SystemModuleListID: 189,
                        SystemModuleListName: "Import / Export",
                        ID: 210,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 61,
                        ModuleName: "07.04.25 - Settings / Library / 5.4 - Time of Day",
                        SystemModuleListID: 190,
                        SystemModuleListName: "Download PDF",
                        ID: 208,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 62,
                        ModuleName: "07.04.26 - Settings / Library / 5.5 - General Price List",
                        SystemModuleListID: 191,
                        SystemModuleListName: "Add New",
                        ID: 211,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 62,
                        ModuleName: "07.04.26 - Settings / Library / 5.5 - General Price List",
                        SystemModuleListID: 192,
                        SystemModuleListName: "Edit",
                        ID: 213,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 62,
                        ModuleName: "07.04.26 - Settings / Library / 5.5 - General Price List",
                        SystemModuleListID: 193,
                        SystemModuleListName: "Import / Export",
                        ID: 214,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 62,
                        ModuleName: "07.04.26 - Settings / Library / 5.5 - General Price List",
                        SystemModuleListID: 194,
                        SystemModuleListName: "Download PDF",
                        ID: 212,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 63,
                        ModuleName: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
                        SystemModuleListID: 195,
                        SystemModuleListName: "Add New",
                        ID: 215,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 63,
                        ModuleName: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
                        SystemModuleListID: 196,
                        SystemModuleListName: "Edit",
                        ID: 217,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 63,
                        ModuleName: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
                        SystemModuleListID: 197,
                        SystemModuleListName: "Import / Export",
                        ID: 218,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 63,
                        ModuleName: "07.04.27 - Settings / Library / 6.1 - Vaccinations",
                        SystemModuleListID: 198,
                        SystemModuleListName: "Download PDF",
                        ID: 216,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 199,
                        SystemModuleListName: "Add New",
                        ID: 123,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 199,
                        SystemModuleListName: "Add New",
                        ID: 127,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 200,
                        SystemModuleListName: "Edit",
                        ID: 125,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 200,
                        SystemModuleListName: "Edit",
                        ID: 129,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 201,
                        SystemModuleListName: "Import / Export",
                        ID: 126,
                        IsView: false,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 201,
                        SystemModuleListName: "Import / Export",
                        ID: 130,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 202,
                        SystemModuleListName: "Download PDF",
                        ID: 124,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 64,
                        ModuleName: "07.04.28 - Settings / Library / 6.2 - Vets",
                        SystemModuleListID: 202,
                        SystemModuleListName: "Download PDF",
                        ID: 128,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 65,
                        ModuleName: "07.04.29 - Settings / Library / 6.3 - Waiver Documents",
                        SystemModuleListID: 203,
                        SystemModuleListName: "Add New",
                        ID: 119,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 65,
                        ModuleName: "07.04.29 - Settings / Library / 6.3 - Waiver Documents",
                        SystemModuleListID: 204,
                        SystemModuleListName: "Edit",
                        ID: 121,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 65,
                        ModuleName: "07.04.29 - Settings / Library / 6.3 - Waiver Documents",
                        SystemModuleListID: 205,
                        SystemModuleListName: "Import / Export",
                        ID: 122,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 65,
                        ModuleName: "07.04.29 - Settings / Library / 6.3 - Waiver Documents",
                        SystemModuleListID: 206,
                        SystemModuleListName: "Download PDF",
                        ID: 120,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 66,
                        ModuleName: "07.04.30 - Settings / Library / 5.6 - Special Price List",
                        SystemModuleListID: 207,
                        SystemModuleListName: "Add New",
                        ID: 294,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 66,
                        ModuleName: "07.04.30 - Settings / Library / 5.6 - Special Price List",
                        SystemModuleListID: 208,
                        SystemModuleListName: "Edit",
                        ID: 296,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 66,
                        ModuleName: "07.04.30 - Settings / Library / 5.6 - Special Price List",
                        SystemModuleListID: 209,
                        SystemModuleListName: "Import / Export",
                        ID: 297,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID: 1,
                        varLocationName: "Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID: 16,
                        varUserRoleGroupName: "Administrators",
                        numUserRoleID: 1,
                        varUserRoleName: "System Admins",
                        ModuleID: 66,
                        ModuleName: "07.04.30 - Settings / Library / 5.6 - Special Price List",
                        SystemModuleListID: 210,
                        SystemModuleListName: "Download PDF",
                        ID: 295,
                        IsView: true,
                        IsUpdate: false,
                        IsDelete: false,
                        varEmployeeFirstName: "Super",
                        varEmployeeLastName: "Admin",
                        varEmployeeImagePath: "",
                        IsActive: true,
                    },
                    {
                        LocationID:1,
                        varLocationName:"Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID:16,
                        varUserRoleGroupName:"Administrators",
                        numUserRoleID:1,
                        varUserRoleName:"System Admins",
                        ModuleID:67,
                        ModuleName:"07.04.31 - Settings / Library / 5.7 - Packages",
                        SystemModuleListID:212,
                        SystemModuleListName:"Add New",
                        ID:1299,
                        IsView:true,
                        IsUpdate:false,
                        IsDelete:false,
                        varEmployeeFirstName:"Admin",
                        varEmployeeLastName:"Admin",
                        varEmployeeImagePath:"",
                        IsActive:true
                     },
                     {
                        LocationID:1,
                        varLocationName:"Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID:16,
                        varUserRoleGroupName:"Administrators",
                        numUserRoleID:1,
                        varUserRoleName:"System Admins",
                        ModuleID:67,
                        ModuleName:"07.04.31 - Settings / Library / 5.7 - Packages",
                        SystemModuleListID:213,
                        SystemModuleListName:"Edit",
                        ID:1301,
                        IsView:true,
                        IsUpdate:false,
                        IsDelete:false,
                        varEmployeeFirstName:"Admin",
                        varEmployeeLastName:"Admin",
                        varEmployeeImagePath:"",
                        IsActive:true
                     },
                     {
                        LocationID:1,
                        varLocationName:"Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID:16,
                        varUserRoleGroupName:"Administrators",
                        numUserRoleID:1,
                        varUserRoleName:"System Admins",
                        ModuleID:67,
                        ModuleName:"07.04.31 - Settings / Library / 5.7 - Packages",
                        SystemModuleListID:214,
                        SystemModuleListName:"Import / Export",
                        ID:1302,
                        IsView:true,
                        IsUpdate:false,
                        IsDelete:false,
                        varEmployeeFirstName:"Admin",
                        varEmployeeLastName:"Admin",
                        varEmployeeImagePath:"",
                        IsActive:true
                     },
                     {
                        LocationID:1,
                        varLocationName:"Syosset - ESyPet Pet Grooming",
                        numUserRoleGroupID:16,
                        varUserRoleGroupName:"Administrators",
                        numUserRoleID:1,
                        varUserRoleName:"System Admins",
                        ModuleID:67,
                        ModuleName:"07.04.31 - Settings / Library / 5.7 - Packages",
                        SystemModuleListID:215,
                        SystemModuleListName:"Download PDF",
                        ID:1300,
                        IsView:true,
                        IsUpdate:false,
                        IsDelete:false,
                        varEmployeeFirstName:"Admin",
                        varEmployeeLastName:"Admin",
                        varEmployeeImagePath:"",
                        IsActive:true
                     }
                ],
                status: 200,
                statusText: "OK",
                headers: {
                    "content-length": "86345",
                    "content-type": "application/json; charset=utf-8",
                },
                config: {
                    transitional: {
                        silentJSONParsing: true,
                        forcedJSONParsing: true,
                        clarifyTimeoutError: false,
                    },
                    transformRequest: [null],
                    transformResponse: [null],
                    timeout: 0,
                    xsrfCookieName: "XSRF-TOKEN",
                    xsrfHeaderName: "X-XSRF-TOKEN",
                    maxContentLength: -1,
                    maxBodyLength: -1,
                    env: {
                        FormData: null,
                    },
                    headers: {
                        Accept: "application/json, text/plain, */*",
                    },
                    baseURL: `${process.env.REACT_APP_API_URL}`,
                    withCredentials: true,
                    method: "get",
                    url: "User/GetAllUserPermissionsByEmpIDandLocID?numLocationID=&numEmployeeID=1077",
                },
                request: {},
            }
        } else {
            const result = await axiosPrivate.get(`User/GetAllUserPermissionsByEmpIDandLocID?numLocationID=&numEmployeeID=${employeeID}`)
            console.log("superuser", result)
            return result
        }
    }

    const fetchPermissionObject = (employeeID) => {
        getPermissions(employeeID)
            .then((response) => {
                if (response?.data.length > 0) {
                    let permissionTree = constructTree(response?.data)
                    let constructLocations = [] as any

                    Object.keys(permissionTree)?.map((permission) => {
                        constructLocations.push(permissionTree[permission])
                    })

                    console.log("constructLocations", constructLocations)

                    setLocationList(constructLocations)
                    setRolesSetup(true)
                } else {
                    setRolesSetup(false)
                    toast.error("You do not have Roles assigned to you yet! Contact Administrator.")
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        fetchPermissionObject(auth?.employee?.numEmployeeID)
    }, [])

    useEffect(() => {
        roleRef.current?.handleClear()

        let makeRoles = [] as any
        let constructSettings = {} as any

        Object.keys(locationList).map((loc) => {
            if (locationList[loc]?.location?.LocationID === location) {
                let firstKey = Object.keys(locationList[loc]?.groups)[0]

                Object.keys(locationList[loc]?.groups[firstKey]?.roles).map((role) => {
                    Object.keys(locationList[loc]?.groups[firstKey]?.roles[role]?.modules).map((mod) => {
                        // console.log("testtt", locationList[loc]?.groups[firstKey]?.roles[role])

                        if (locationList[loc]?.groups[firstKey]?.roles[role]?.IsActive) {
                            let moduleObj = locationList[loc]?.groups[firstKey]?.roles[role]?.modules[mod]

                            if (constructSettings.hasOwnProperty(moduleObj?.ModuleName)) {
                                constructSettings[moduleObj?.ModuleName].push(moduleObj?.systemmodules)
                            } else {
                                constructSettings[moduleObj?.ModuleName] = [moduleObj?.systemmodules]
                            }
                        }
                    })

                    if (locationList[loc]?.groups[firstKey]?.roles[role]?.IsActive) {
                        makeRoles.push({
                            varUserRoleName: locationList[loc]?.groups[firstKey]?.roles[role]?.varUserRoleName,
                            numUserRoleID: locationList[loc]?.groups[firstKey]?.roles[role]?.numUserRoleID,
                        })
                    }
                })
            }
        })

        setRoleList(makeRoles)

        // console.log("settings", constructSettings)
        // console.log("makeRoles", makeRoles)
        let finalSettings = {} as any

        const calculateSetting = (lastObj) => {
            let finalSet = {} as any
            Object.keys(lastObj).map((setting, index) => {
                let lastSetting = {} as any
                Object.keys(lastObj[setting]).map((set) => {
                    // console.log(lastObj[setting][set], index)

                    if (Object.keys(lastSetting).length > 0) {
                        const isView = lastObj[setting][set]["IsView"]
                        const isUpdate = lastObj[setting][set]["IsUpdate"]
                        const isDelete = lastObj[setting][set]["IsDelete"]

                        lastSetting["IsView"] = lastSetting["IsView"] || isView
                        lastSetting["IsUpdate"] = lastSetting["IsUpdate"] || isUpdate
                        lastSetting["IsDelete"] = lastSetting["IsDelete"] || isDelete
                    } else {
                        lastSetting = lastObj[setting][set]
                    }
                })

                finalSet[Object.keys(lastObj)[index]] = lastSetting
            })
            return finalSet
        }

        Object.keys(constructSettings).map((mods, index) => {
            let mainComp = {} as any

            Object.keys(constructSettings[mods]).map((settting) => {
                Object.keys(constructSettings[mods][settting]).map((setKey) => {
                    let settingKey = constructSettings[mods][settting][setKey]

                    if (mainComp.hasOwnProperty(settingKey?.SystemModuleListName)) {
                        mainComp[settingKey?.SystemModuleListName].push(settingKey?.settings)
                    } else {
                        mainComp[settingKey?.SystemModuleListName] = [settingKey?.settings]
                    }
                })
            })

            finalSettings[Object.keys(constructSettings)[index]] = calculateSetting(mainComp)

            // console.log("settings_last", Object.keys(constructSettings)[index], calculateSetting(mainComp))
        })

        console.log("finalSettings", finalSettings)

        setAuth((prev) => {
            return { ...prev, permissions: finalSettings, permissionModules: Object.keys(finalSettings) }
        })

        if (locationList?.length === 1 && locationList[0]?.location?.LocationID > 0) {
            handleNavigate()
        }
    }, [location])

    const handleEmailClick = () => {
        const recipient = "administrator@esypet.net"
        const subject = "Role Assignment Request"
        const body = "Body Here"
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
        window.location.href = mailtoLink
    }

    return (
        <>
            <ToastContainer />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <div style={{ border: "4px solid #6767672b", borderRadius: "50%" }}>
                    <Avatar
                        img={auth?.employee?.varEmployeeImagePath === null ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${auth?.employee?.varEmployeeImagePath}`}
                        className={"large"}
                        isVip={props.data?.bitIsVip}
                        labelType={"largeLabel"}
                    />
                </div>
                <h1 style={{ color: "#004BC3", textAlign: "center" }}>
                    {auth?.employee?.varEmployeeFirstName} {auth?.employee?.varEmployeeLastName}
                    <p style={{ fontSize: "15px", fontWeight: "500", textAlign: "center", width: "100%" }}>
                        {!!roleList?.length
                            ? roleList?.map((role, ind) => {
                                  return (
                                      <span>
                                          {role?.varUserRoleName}
                                          {roleList.length - 1 === ind ? "" : " | "}
                                      </span>
                                  )
                              })
                            : "-"}
                    </p>
                </h1>

                {loading ? (
                    <CircularProgress />
                ) : rolesSetup ? (
                    <div style={{ width: "33%", display: "flex", gap: "15px", flexDirection: "column" }}>
                        <div>
                            <p style={{ fontSize: "15px", fontWeight: "500", display: "flex", alignItems: "center" }}>Select Location</p>
                            <div style={{ width: "100%" }}>
                                <AdvancedDropDown
                                    data={locationList.map((i) => ({
                                        label: i?.location?.Name,
                                        value: i?.location?.LocationID,
                                    }))}
                                    onChange={(e) => {
                                        setLocation(e.value)
                                        setAuth((prev) => {
                                            return { ...prev, currentLocation: { label: e.label, value: e.value } }
                                        })
                                    }}
                                    passByID={true}
                                    value={location}
                                    ref={locationRef}
                                    placeHolder={"Select Location"}
                                />
                            </div>
                        </div>
                        {visited && (
                            <Button className={styles.loginBtn} variant="contained" color="primary" onClick={() => handleNavigate()}>
                                Continue to Profile
                            </Button>
                        )}
                    </div>
                ) : (
                    <div style={{ width: "33%", display: "flex", gap: "15px", flexDirection: "column" }}>
                        <p style={{ textAlign: "center", margin: 0, padding: 0, fontWeight: 500, fontSize: "16px" }}>You do not have Roles assigned to you yet!</p>
                        <p style={{ textAlign: "center", margin: 0, padding: 0, fontWeight: 500, fontSize: "16px" }}> Contact Administrator.</p>
                        <Button className={styles.loginBtn} variant="contained" color="primary" onClick={() => handleEmailClick()}>
                            Contact Administrator
                        </Button>
                    </div>
                )}

                <div style={{ color: "#6b7280", marginTop: "24px", fontSize: "12px", textAlign: "center" }}>
                    Copyright©{" "}
                    <a href="https://www.esyntaxis.com/" target="_blank">
                        ESyntaxis Corporation
                    </a>
                    . ESyPet Version {process.env.REACT_APP_VERSION}
                </div>
            </div>
        </>
    )
}

export default Welcome
