
import React, { useEffect, useState } from "react"
import { Button, Modal } from "../components"
import CloseButton from "../Modal/CloseButton/CloseButton"
import { axiosPrivate } from "../../apis/axios"
import { toast } from "react-toastify"
import { Box, TextField, Tooltip } from "@mui/material"
import { CopyAll, OpenInNewOutlined, Verified } from "@mui/icons-material"
import { shortDate } from "../../utils/DateFormatter"
import GroomerNote from "./GroomerNote"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import moment from "moment"

function AddGroomerNote(props: { isOpenAddGroomerNoteModal; setIsOpenAddGroomerNoteModal: CallableFunction; bookingDetails; type; NoteDetails?; isSuccess: CallableFunction }) {
    console.log("noteXX", props.NoteDetails)
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [groomerNotesList, setGroomerNotesList] = useState([]) as any;
    const [isLoading, setIsLoading] = useState(false);
    const [groomerNote, setGroomerNote] = useState<any>({})
    const [isOpenGroomerNoteShow, setIsOpenGroomerNoteShow] = useState(false)
    const [isOpenNoteImages, setIsOpenNoteImages] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState('')
    const [noteType, setNoteType] = useState('')
console.log("BD", props.bookingDetails)
    async function getGroomerNotes() {
        setIsLoading(true)
        let data  = [
            {
              "numPetID": props?.bookingDetails?.Pet?.PetID,
              "numEmployeeID": null,
              "numLocationID": props?.bookingDetails?.LocationID,
              "fromDate": (startDate === "")? null : startDate,
              "toDate": (endDate === "")? null : endDate,
              "serviceID": props?.bookingDetails?.Services[0]?.numChargeID

            }
          ]
          console.log("sent data",data )
        await axiosPrivate
            .post(`GroomerBatherNote/GetAllGroomerNoteByPetID`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                
                if (result.status == 200) {
                    console.log(result?.data)
                    setGroomerNotesList(result?.data)
                    setIsLoading(false)

                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
    }

    useEffect(()=>{
        if(props.isOpenAddGroomerNoteModal === true){
              getGroomerNotes()
        }
    },[props.isOpenAddGroomerNoteModal])

    const resetFilters = () => {
        setStartDate('')
        setEndDate('')
        getGroomerNotes()
    }
    useEffect(()=>{console.log("gl", groomerNotesList)},[groomerNotesList])

    const openGroomerNote = () => {
        setNoteType("add")
        setGroomerNote({})
        setIsOpenGroomerNoteShow(true)

    }

    const openGroomerNoteCopy = (index) => {
        setGroomerNote(groomerNotesList[index])
        setNoteType("copy")
        setIsOpenGroomerNoteShow(true)
    }

    const handleClick = (note,index) => {
        if (note?.BeforeImgPath !== null || note?.AfterImagePath !== null) {
            console.log("Opening modal with images");
            setSelectedImageIndex(index)
            setIsOpenNoteImages(true)
        } else {
            toast.info("No images to available")
        }
    };

    return (
        <div>
            <Modal open={props.isOpenAddGroomerNoteModal}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column", width: "700px" }}>
                    <div style={{ backgroundColor: "#fff", maxWidth: "800px", width: "100%", borderRadius: "10px" }}>
                        <div style={{ padding: "20px" }}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <p style={{ fontWeight: 600, fontSize: "20px", width: "100%" }}>Groomer Note Templates</p>
                                <CloseButton onClick={() => props.setIsOpenAddGroomerNoteModal(false)} />
                            </div>
                        </div>
                        <div style={{display:"flex", gap:"20px", alignItems:"center", marginBottom:"10px"}}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label="Start Date"
                                    type="date"
                                    value={startDate}
                                    size="small"
                                    onChange={(e) => setStartDate(e.target.value)}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    sx={{ marginRight: 2 }}
                                />
                                <Box>to</Box>
                                <TextField
                                    label="End Date"
                                    type="date"
                                    size="small"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    sx={{ marginLeft: 2 }}
                                />
                                </Box>
                                <div style={{display:"flex", gap:"10px"}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            getGroomerNotes()
                                        }}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        color="default"
                                        variant="outlined"
                                        onClick={() => {
                                            resetFilters()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={() => {
                                            openGroomerNote()
                                        }}
                                    >
                                        Add New
                                    </Button>
                            </div>
                        </div>
                       
                        <Modal open={isOpenNoteImages} backgroundColor={"#F9FAFB"}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: "350px", alignItems: "center" }}>
                                    <CloseButton onClick={() => setIsOpenNoteImages(false)} />
                                </div>
                                    {
                                        <ImgComparisonSlider
                                            style={{width:"fit-content"}}>
                                                <img style={{ maxHeight: "400px", objectFit: "cover" }} alt="404" slot="first" src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${groomerNotesList[selectedImageIndex]?.BeforeImgPath}`} />
                                                <img style={{ maxHeight: "400px", objectFit: "cover" }} alt="404" slot="second" src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${groomerNotesList[selectedImageIndex]?.AfterImagePath}`} />
                                        </ImgComparisonSlider>  
                                    } 
                            </div>
                        </Modal>
        
                        <div style={{ padding: "20px", backgroundColor: "#F9FAFB", height: "500px", overflowY: "scroll" }}>
                            {   (isLoading)? <p>Loading...</p> : 
                                (groomerNotesList.length > 0)? 
                                    groomerNotesList.map((note,index)=>{
                                        return (
                                            <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                backgroundColor: "#F9FAFB",
                                                boxShadow: "rgb(237 243 249) 1px 4px 1px",                                               
                                                borderRadius: "8px",
                                                padding: "10px",
                                                alignItems: "center",
                                                marginBottom:"10px"
                                            }}
                                        >
                                            <div style={{display:"flex", gap:"10px"}}>
                                            <div 
                                                    onClick={() => handleClick(note, index)} 
                                                    style={{ display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "8px", overflow: "hidden", border: "1px solid #ddd", width: "150px", height: "150px", cursor: "pointer" }}
                                                >
                                                    {note?.BeforeImgPath !== null ? (
                                                        <img
                                                            style={{ width: "100%", height: "50%", objectFit: "cover", borderBottom: "1px solid #ddd" }}
                                                            src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${note?.BeforeImgPath}`}
                                                            alt="Before"
                                                        />
                                                    ) : (
                                                        <div style={{ width: "100%", height: "50%", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ddd" }}>
                                                            N/A
                                                        </div>
                                                    )}
                                                    {note?.AfterImagePath !== null ? (
                                                        <img
                                                            style={{ width: "100%", height: "50%", objectFit: "cover" }}
                                                            src={`${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${note?.AfterImagePath}`}
                                                            alt="After"
                                                        />
                                                    ) : (
                                                        <div style={{ width: "100%", height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            N/A
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>{shortDate(note?.GroomerNoteCreatedDate)}</p>
                                                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>{note?.varGroomerComment}</p>
                                                    <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>{note?.varEmployeeFirstName} {note?.varEmployeeLastName}</p>
                                                    <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>Location: {note?.varLocationName}</p>
                                                    {
                                                        (note?.IsPreferred)?
                                                        <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                                                            <Verified style={{color:"#edb80b", fontSize:"20px"}}/>
                                                            <p style={{color:"#d3a71a", fontWeight:"600", margin:"0"}}>Customer's Pick</p>
                                                        </div> :null
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <Tooltip 
                                                    style={{ paddingLeft: '5px' }}
                                                    title={
                                                        <>
                                                            <p style={{ fontSize: '14px', color: '#fff', fontWeight: '600' }}>Copy</p>
                                                        </>                                                                                                                                                                                                                                                                   
                                                    }
                                                >
                                                    <Button
                                                        color="transparent"
                                                        variant="text"
                                                        onClick={() => {
                                                            openGroomerNoteCopy(index)
                                                        }}
                                                    >
                                                        <CopyAll style={{ color: "#2076FF", fontSize: "30px" }} />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        )
                                })
                                :
                                <p style={{color:"gray"}}>No groomer notes found</p>
                            }
                        </div>

                        
                        {Object.keys(props?.bookingDetails).length !== 0 ? (
                                                <GroomerNote
                                                    bookingDetails={props?.bookingDetails}
                                                    isOpenGroomerNoteModal={isOpenGroomerNoteShow}
                                                    setIsOpenGroomerNoteModal={(value) => setIsOpenGroomerNoteShow(value)}
                                                    type={noteType}
                                                    NoteDetails={groomerNote}
                                                    isSuccess={(e) => {
                                                        if(e === true){
                                                            props.isSuccess(true)
                                                        }
                                                    }}
                                                />
                                            ) : null}
   
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AddGroomerNote
