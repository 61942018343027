import { useEffect, useState } from "react"
import { Chip } from "../../../Chip/Chip"
import { shortDate } from "../../../../utils/DateFormatter"
import { formatPhone } from "../../../../utils/PhoneFormatter"
import styles from "./PersonalDetails.module.scss"

/* Display Components */
import DisplayCustomer from "./DisplayMode/DisplayCustomer"
import DisplayEmployee from "./DisplayMode/DisplayEmployee"
import DisplayLocation from "./DisplayMode/DisplayLocation"
import DisplayPetProfile from "./DisplayMode/DisplayPetProfile"

/* Edit Components */
import EditCustomer from "./EditMode/EditCustomer"
import EditEmployee from "./EditMode/EditEmployee"
import EditLocation from "./EditMode/EditLocation"
import EditPetProfile from "./EditMode/EditPetProfile"
import { generateSince } from "../../../../utils/GenerateJoinedDate"
import { isActive } from "../../../../utils/permissions"

const PersonalDetails = (props: { type; data; index?; labels?; mode?: boolean }) => {
    const formatAddress = (type, defaultAddressList, addressType) => {
        let addressList = [] as any

        if (type === "customer") {
            if (defaultAddressList != undefined) {
                defaultAddressList.map((address) => {
                    if (addressType === "primary" && address?.bitIsPrimary) {
                        addressList.push({
                            addressID: address?.numCustomerAddressID,
                            addressType: address?.varAddressType,
                            addressLineOne: address?.varCustomerAddress1,
                            addressLineTwo: address?.varCustomerAddress2,
                            addressNotes: address?.varCustomerAddressNotes,
                            addressCity: address?.varCustomerCity,
                            addressState: address?.varCustomerState,
                            addressZip: address?.varCustomerZip,
                            addressCustomNotes: address?.varCustomerAddressNotes,
                            addressIsDefault: address?.bitIsPrimaryDefault,
                            addressIsPrimary: address?.bitIsPrimary,
                            addressIsBilling: address?.bitIsBilling,
                            bitActive: address?.bitActive,
                        })
                    } else if (addressType === "billing" && address?.bitIsBilling) {
                        addressList.push({
                            addressID: address?.numCustomerAddressID,
                            addressType: address?.varAddressType,
                            addressLineOne: address?.varCustomerAddress1,
                            addressLineTwo: address?.varCustomerAddress2,
                            addressNotes: address?.varCustomerAddressNotes,
                            addressCity: address?.varCustomerCity,
                            addressState: address?.varCustomerState,
                            addressZip: address?.varCustomerZip,
                            addressCustomNotes: address?.varCustomerAddressNotes,
                            addressIsDefault: address?.bitIsBillingDefault,
                            addressIsPrimary: address?.bitIsPrimary,
                            addressIsBilling: address?.bitIsBilling,
                            bitActive: address?.bitActive,
                        })
                    }
                })
            }
        }

        return addressList
    }

    const headingMapper = (dataValueIndex: string, type: string, objectType: string, valueType?: string) => {
        let labelName: string = ""
        let labelIsRequired: boolean = false

        if (props?.labels != undefined) {
            if (Object.keys(props?.labels).length > 0) {
                let labelIndex = props?.labels[objectType]?.[type][dataValueIndex]?.varLabelValue

                let findIndex = props?.labels[objectType]?.[type].findIndex((value) => {
                    return value.varTblLabelName == dataValueIndex
                })

                labelName = props?.labels[objectType]?.[type][findIndex]?.varLabelValue
                labelIsRequired = props?.labels[objectType]?.[type][findIndex]?.bitRequired
            }
        }

        if (valueType === "isRequired") {
            return labelIsRequired
        } else {
            return labelName
        }
    }

    const generateCustomFields = (objectType: string) => {
        let dataList = [] as any

        if (props?.labels != undefined) {
            if (Object.keys(props?.labels).length > 0) {
                props?.labels[objectType]?.custom.map((customField) => {
                    if (customField?.bitVisible) {
                        dataList.push({
                            required: customField?.bitRequired,
                            type: customField?.varLabelType,
                            key: customField?.varLabelValue,
                            value: props?.data[customField?.varTblLabelName],
                            fieldName: customField?.varTblLabelName,
                        })
                    }
                })
            }
        }

        return dataList
    }

    const customerTemplate = [
        {
            heading: "System Reference",
            layout: "text",
            data: [
                {
                    key: headingMapper("numCustomerID", "standard", "customer"),
                    value: props?.data?.numCustomerID,
                },
                {
                    key: headingMapper("varCustomerBC", "standard", "customer"),
                    value: props?.data?.varCustomerBC,
                },
                {
                    key: headingMapper("bitActive", "standard", "customer"),
                    value: <Chip size="small" color={props?.data?.bitActive ? "success" : "error"} label={props?.data?.bitActive ? "Active" : "Inactive"} />,
                },
            ],
        },
        {
            heading: "Contact Details",
            layout: "text",
            data: [
                {
                    key: headingMapper("varCustomerMobilePhone", "standard", "customer"),
                    value: formatPhone(props?.data?.varCustomerMobilePhone),
                },
                {
                    key: headingMapper("varCustomerHomePhone", "standard", "customer"),
                    value: formatPhone(props?.data?.varCustomerHomePhone),
                },
                {
                    key: headingMapper("varCustomerWorkPhone", "standard", "customer"),
                    value: formatPhone(props?.data?.varCustomerWorkPhone),
                },
                {
                    key: headingMapper("varCustomerEmail", "standard", "customer"),
                    value: props?.data?.varCustomerEmail,
                },
            ],
        },
        {
            heading: "Emergency contact",
            layout: "text",
            data: [
                {
                    key: headingMapper("numContactPersonID", "standard", "customer"),
                    value: props?.data?.varContactPerson,
                },
                {
                    key: headingMapper("varEmergContactFirstName", "standard", "customer"),
                    value: props?.data?.varEmergContactFirstName,
                },
                {
                    key: headingMapper("varEmergContactLastName", "standard", "customer"),
                    value: props?.data?.varEmergContactLastName,
                },
                {
                    key: headingMapper("varEmergContactNumber", "standard", "customer"),
                    value: props?.data?.varEmergContactNumber,
                },
            ],
        },
        {
            heading: "Primary address",
            layout: "address",
            data: formatAddress("customer", props?.data?.customerAddress, "primary"),
        },
        {
            heading: "Billing address",
            layout: "address",
            data: formatAddress("customer", props?.data?.customerAddress, "billing"),
        },
        {
            heading: "Custom Fields",
            layout: "text",
            data: generateCustomFields("customer"),
        },
    ]

    // const deepClone = JSON.parse(JSON.stringify(props.data));
    const deepClone = props.data

    const customerTemplateEdit = [
        {
            layout: "imageUpload",
            data: [
                {
                    id: deepClone?.numCustomerID,
                    key: "actual",
                    value: deepClone?.varCustomerImagePath,
                },
            ],
        },
        {
            heading: "Basic details",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numSalutationID", "standard", "customer"),
                            key: "numSalutationID",
                            value: deepClone?.numSalutationID,
                            objectName: "Salutation",
                            isCustom: false,
                            isRequired: headingMapper("numSalutationID", "standard", "customer", "isRequired"),
                            sequence: "",
                            isButton: isActive(14),
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "advanced",
                            label: headingMapper("varCustomerFirstName", "standard", "customer"),
                            isRequired: headingMapper("varCustomerFirstName", "standard", "customer", "isRequired"),
                            key: "varCustomerFirstName",
                            value: deepClone?.varCustomerFirstName,
                        },
                        {
                            type: "text",
                            inputType: "advanced",
                            label: headingMapper("varCustomerLastName", "standard", "customer"),
                            isRequired: headingMapper("varCustomerLastName", "standard", "customer", "isRequired"),
                            key: "varCustomerLastName",
                            value: deepClone?.varCustomerLastName,
                        },
                    ],
                },
            ],
        },
        {
            heading: "Contact details",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varCustomerMobilePhone", "standard", "customer"),
                            isRequired: headingMapper("varCustomerMobilePhone", "standard", "customer", "isRequired"),
                            key: "varCustomerMobilePhone",
                            value: formatPhone(deepClone?.varCustomerMobilePhone),
                        },
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varCustomerHomePhone", "standard", "customer"),
                            isRequired: headingMapper("varCustomerHomePhone", "standard", "customer", "isRequired"),
                            key: "varCustomerHomePhone",
                            value: formatPhone(deepClone?.varCustomerHomePhone),
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varCustomerWorkPhone", "standard", "customer"),
                            isRequired: headingMapper("varCustomerWorkPhone", "standard", "customer", "isRequired"),
                            key: "varCustomerWorkPhone",
                            value: formatPhone(deepClone?.varCustomerWorkPhone),
                        },
                        {
                            type: "text",
                            inputType: "email",
                            label: headingMapper("varCustomerEmail", "standard", "customer"),
                            isRequired: headingMapper("varCustomerEmail", "standard", "customer", "isRequired"),
                            key: "varCustomerEmail",
                            value: deepClone?.varCustomerEmail,
                        },
                    ],
                },
            ],
        },
        {
            heading: "Emergency contacts",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varEmergContactFirstName", "standard", "customer"),
                            isRequired: headingMapper("varEmergContactFirstName", "standard", "customer", "isRequired"),
                            key: "varEmergContactFirstName",
                            value: deepClone?.varEmergContactFirstName,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varEmergContactLastName", "standard", "customer"),
                            isRequired: headingMapper("varEmergContactLastName", "standard", "customer", "isRequired"),
                            key: "varEmergContactLastName",
                            value: deepClone?.varEmergContactLastName,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numContactPersonID", "standard", "customer"),
                            key: "numContactPersonID",
                            value: deepClone?.numContactPersonID,
                            objectName: "ContactPerson",
                            isRequired: headingMapper("numContactPersonID", "standard", "customer", "isRequired"),
                            isCustom: false,
                            sequence: "",
                            isButton: isActive(15),
                        },
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varEmergContactNumber", "standard", "customer"),
                            isRequired: headingMapper("varEmergContactNumber", "standard", "customer", "isRequired"),
                            key: "varEmergContactNumber",
                            value: deepClone?.varEmergContactNumber,
                        },
                    ],
                },
            ],
        },
        {
            heading: "Location details",
            layout: "address",
            data: deepClone?.customerAddress,
        },
        {
            heading: "Dynamic list",
            layout: "dynamic",
            data: generateCustomFields("customer"),
        },
    ]

    const staffTemplate = [
        {
            heading: "General",
            layout: "text",
            data: [
                {
                    key: "Employee Age",
                    value: generateSince(props?.data?.dtEmployeeDOB),
                },
                {
                    key: headingMapper("dtEmployeeDOB", "standard", "employee"),
                    value: shortDate(props?.data?.dtEmployeeDOB),
                },
            ],
        },
        {
            heading: "System Reference",
            layout: "text",
            data: [
                {
                    key: headingMapper("numEmployeeID", "standard", "employee"),
                    value: props?.data?.numEmployeeID,
                },
                {
                    key: headingMapper("varEmployeeRFID", "standard", "employee"),
                    value: props?.data?.varEmployeeRFID,
                },
                {
                    key: headingMapper("varEmployeeBC", "standard", "employee"),
                    value: props?.data?.varEmployeeBC,
                },
                {
                    key: headingMapper("bitActive", "standard", "employee"),
                    value: <Chip size="small" color={props?.data?.bitActive ? "success" : "error"} label={props?.data?.bitActive ? "Active" : "Inactive"} />,
                },
            ],
        },
        {
            heading: "Contact Details",
            layout: "text",
            data: [
                {
                    key: headingMapper("varEmployeeMobilePhone", "standard", "employee"),
                    value: formatPhone(props?.data?.varEmployeeMobilePhone),
                },
                {
                    key: headingMapper("varEmployeeHomePhone", "standard", "employee"),
                    value: formatPhone(props?.data?.varEmployeeHomePhone),
                },
                {
                    key: headingMapper("varEmployeeWorkPhone", "standard", "employee"),
                    value: formatPhone(props?.data?.varEmployeeWorkPhone),
                },
            ],
        },
        {
            heading: "Department",
            layout: "text",
            data: [
                {
                    key: headingMapper("numEmployeeDepartmentID", "standard", "employee"),
                    value: props?.data?.varDepartment,
                },
            ],
        },
        {
            heading: "Salon",
            layout: "text",
            data: [
                {
                    key: "Default Salon name",
                    value: props?.data?.varEmployeeDefultLocation,
                },
            ],
        },
        {
            heading: "Emergency contact",
            layout: "text",
            data: [
                {
                    key: headingMapper("numContactPersonID", "standard", "employee"),
                    value: props?.data?.varContactPerson,
                },
                {
                    key: headingMapper("varEmergContactFirstName", "standard", "employee"),
                    value: props?.data?.varEmergContactFirstName != undefined || props?.data?.varEmergContactFirstName != null ? props?.data?.varEmergContactFirstName : "",
                },
                {
                    key: headingMapper("varEmergContactLastName", "standard", "employee"),
                    value: props?.data?.varEmergContactLastName != undefined || props?.data?.varEmergContactLastName != null ? props?.data?.varEmergContactLastName : "",
                },
                {
                    key: headingMapper("numContactTypeID", "standard", "employee"),
                    value: props?.data?.varContactType,
                },
                {
                    key: headingMapper("varEmergContactNumber", "standard", "employee"),
                    value: props?.data?.varEmergContactNumber,
                },
            ],
        },
        {
            heading: "Personal address",
            layout: "address",
            data: {
                addressType: props?.data?.varAddressType,
                addressLineOne: props?.data?.varEmployeeAddress1,
                addressLineTwo: props?.data?.varEmployeeAddress2,
                addressNotes: props?.data?.varEmployeeAddressNote,
                addressCity: props?.data?.varEmployeeCity,
                addressState: props?.data?.varEmployeeState,
                addressZip: props?.data?.varEmployeeZip,
            } as any,
        },
        {
            heading: "Custom Fields",
            layout: "text",
            data: generateCustomFields("employee"),
        },
    ]

    const staffTemplateEdit = [
        {
            layout: "imageUpload",
            data: [
                {
                    id: deepClone?.numEmployeeID,
                    key: "actual",
                    value: deepClone?.varEmployeeImagePath,
                },
            ],
        },
        {
            heading: "Basic details",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numSalutationID", "standard", "employee"),
                            key: "numSalutationID",
                            value: deepClone?.numSalutationID,
                            objectName: "Salutation",
                            isRequired: headingMapper("numSalutationID", "standard", "employee", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                        {
                            type: "advanced",
                            label: headingMapper("numOccupationID", "standard", "employee"),
                            key: "numOccupationID",
                            value: deepClone?.numOccupationID,
                            objectName: "Occupation",
                            isRequired: headingMapper("numOccupationID", "standard", "employee", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "advanced",
                            label: headingMapper("varEmployeeFirstName", "standard", "employee"),
                            isRequired: headingMapper("varEmployeeFirstName", "standard", "employee", "isRequired"),
                            key: "varEmployeeFirstName",
                            value: deepClone?.varEmployeeFirstName,
                        },
                        {
                            type: "text",
                            inputType: "advanced",
                            label: headingMapper("varEmployeeLastName", "standard", "employee"),
                            isRequired: headingMapper("varEmployeeLastName", "standard", "employee", "isRequired"),
                            key: "varEmployeeLastName",
                            value: deepClone?.varEmployeeLastName,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "date",
                            inputType: "date",
                            label: headingMapper("dtEmployeeDOB", "standard", "employee"),
                            isRequired: headingMapper("dtEmployeeDOB", "standard", "employee", "isRequired"),
                            key: "dtEmployeeDOB",
                            value: shortDate(deepClone?.dtEmployeeDOB),
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Contact details",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varEmployeeMobilePhone", "standard", "employee"),
                            isRequired: headingMapper("varEmployeeMobilePhone", "standard", "employee", "isRequired"),
                            key: "varEmployeeMobilePhone",
                            value: formatPhone(deepClone?.varEmployeeMobilePhone),
                        },
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varEmployeeHomePhone", "standard", "employee"),
                            isRequired: headingMapper("varEmployeeHomePhone", "standard", "employee", "isRequired"),
                            key: "varEmployeeHomePhone",
                            value: formatPhone(deepClone?.varEmployeeHomePhone),
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varEmployeeWorkPhone", "standard", "employee"),
                            isRequired: headingMapper("varEmployeeWorkPhone", "standard", "employee", "isRequired"),
                            key: "varEmployeeWorkPhone",
                            value: formatPhone(deepClone?.varEmployeeWorkPhone),
                        },
                        {
                            type: "text",
                            inputType: "email",
                            label: headingMapper("varEmployeeEmail", "standard", "employee"),
                            isRequired: headingMapper("varEmployeeEmail", "standard", "employee", "isRequired"),
                            key: "varEmployeeEmail",
                            value: deepClone?.varEmployeeEmail,
                        },
                    ],
                },
            ],
        },
        {
            heading: "Department",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numEmployeeDepartmentID", "standard", "employee"),
                            key: "numEmployeeDepartmentID",
                            value: deepClone?.numEmployeeDepartmentID,
                            objectName: "Department",
                            isRequired: headingMapper("numEmployeeDepartmentID", "standard", "employee", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Salon",
            layout: "advancedList",
            data: {
                key: "employeeLocation",
                value: deepClone?.employeeLocation,
                objectName: "Location",
            },
        },
        {
            heading: "Emergency contacts",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numContactPersonID", "standard", "employee"),
                            key: "numContactPersonID",
                            value: deepClone?.numContactPersonID,
                            objectName: "ContactPerson",
                            isRequired: headingMapper("numContactPersonID", "standard", "employee", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varEmergContactFirstName", "standard", "employee"),
                            isRequired: headingMapper("varEmergContactFirstName", "standard", "employee", "isRequired"),
                            key: "varEmergContactFirstName",
                            value: deepClone?.varEmergContactFirstName,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varEmergContactLastName", "standard", "employee"),
                            isRequired: headingMapper("varEmergContactLastName", "standard", "employee", "isRequired"),
                            key: "varEmergContactLastName",
                            value: deepClone?.varEmergContactLastName,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numContactTypeID", "standard", "employee"),
                            key: "numContactTypeID",
                            value: deepClone?.numContactTypeID,
                            objectName: "ContactType",
                            isRequired: headingMapper("numContactTypeID", "standard", "employee", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varEmergContactNumber", "standard", "employee"),
                            isRequired: headingMapper("varEmergContactNumber", "standard", "employee", "isRequired"),
                            key: "varEmergContactNumber",
                            value: deepClone?.varEmergContactNumber,
                        },
                    ],
                },
            ],
        },
        {
            heading: "Personal Address",
            layout: "address",
            data: {
                varAddressType: deepClone?.varAddressType,
                varEmployeeAddress1: deepClone?.varEmployeeAddress1,
                varEmployeeAddress2: deepClone?.varEmployeeAddress2,
                varEmployeeAddressNote: deepClone?.varEmployeeAddressNote,
                varEmployeeCity: deepClone?.varEmployeeCity,
                varEmployeeState: deepClone?.varEmployeeState,
                varEmployeeZip: deepClone?.varEmployeeZip,
            } as any,
        },
        {
            heading: "Dynamic list",
            layout: "dynamic",
            data: generateCustomFields("employee"),
        },
    ]

    const petProfileTemplate = [
        {
            heading: "General",
            layout: "text",
            data:
                props?.type === "petProfile"
                    ? [
                          {
                              key: headingMapper("bitActive", "standard", "pet"),
                              value: <Chip size="small" color={props?.data?.bitActive ? "success" : "error"} label={props?.data?.bitActive ? "Active" : "Inactive"} />,
                          },
                          {
                              key: headingMapper("numPetTypeID", "standard", "pet"),
                              value: props?.data?.varPetType,
                          },
                          {
                              key: "Breed type",
                              value: props?.data?.varPetBreedType,
                          },
                          {
                              key: headingMapper("numPetBreedID", "standard", "pet"),
                              value: props?.data?.varPetBreed,
                          },
                          {
                              key: "Primary breed",
                              value: props?.data?.varPrimaryBreed,
                          },
                          {
                              key: "Secondary breed",
                              value: props?.data?.varSecondaryBreed,
                          },
                          {
                              key: headingMapper("varPetSex", "standard", "pet"),
                              value: props?.data?.varPetSex === "M" ? "Male" : "Female",
                          },
                          {
                              key: headingMapper("numPetColorID", "standard", "pet"),
                              value: props?.data?.varPetColor,
                          },
                          {
                              key: headingMapper("numPetWeight", "standard", "pet"),
                              value: props?.data?.numPetWeight,
                          },
                          {
                              key: headingMapper("varPetBirthDate", "standard", "pet"),
                              value: shortDate(props?.data?.varPetBirthDate),
                          },
                          {
                              key: headingMapper("varPetAge", "standard", "pet"),
                              value: props?.data?.varPetAge,
                          },
                          {
                              key: headingMapper("varPetSpay", "standard", "pet"),
                              value: props?.data?.varPetSpay ? "Yes" : "No",
                          },
                      ]
                    : [],
        },
        {
            heading: "Pet Description",
            layout: "text",
            data:
                props?.type === "petProfile"
                    ? [
                          {
                              key: headingMapper("varPetDescription", "standard", "pet"),
                              value: props?.data?.varPetDescription,
                          },
                      ]
                    : [],
        },
        {
            heading: "Temperament test status",
            layout: "text",
            data:
                props?.type === "petProfile"
                    ? [
                          {
                              key: headingMapper("varTemperamentTestStatus", "standard", "pet"),
                              value: props?.data?.varTemperamentTestStatus,
                          },
                          {
                              key: headingMapper("varTemperamentTestedLocation", "standard", "pet"),
                              value: props?.data?.varTemperamentTestedLocation,
                          },
                          {
                              key: headingMapper("dtTemperamentTestedDate", "standard", "pet"),
                              value: shortDate(props?.data?.dtTemperamentTestedDate),
                          },
                      ]
                    : [],
        },
        {
            heading: "Personality",
            layout: "personality",
            data: props?.type === "petProfile" ? props?.data?.petPersonality : [],
        },
        {
            heading: "Reference",
            layout: "text",
            data:
                props?.type === "petProfile"
                    ? [
                          {
                              key: headingMapper("numPetID", "standard", "pet"),
                              value: props?.data?.numPetID,
                          },
                          {
                              key: headingMapper("varPetNumber", "standard", "pet"),
                              value: props?.data?.varPetNumber,
                          },
                          {
                              key: headingMapper("varPetBC", "standard", "pet"),
                              value: props?.data?.varPetBC,
                          },
                          {
                              key: headingMapper("varPetRFID", "standard", "pet"),
                              value: props?.data?.varPetRFID,
                          },
                      ]
                    : [],
        },
        {
            heading: "Veterinarian",
            layout: "text",
            data:
                props?.type === "petProfile"
                    ? [
                          {
                              key: "Name",
                              value: props?.data?.petVet == undefined ? "" : props?.data?.petVet.varPetVetName,
                          },
                          {
                              key: "Work",
                              value: props?.data?.petVet == undefined ? "" : formatPhone(props?.data?.petVet.varPetVetPhone1),
                          },
                          {
                              key: "Mobile",
                              value: props?.data?.petVet == undefined ? "" : formatPhone(props?.data?.petVet.varPetVetPhone2),
                          },
                          {
                              key: "Email",
                              value: props?.data?.petVet == undefined ? "" : props?.data?.petVet.varPetVetEmail,
                          },
                      ]
                    : [],
        },
        {
            heading: "Custom Fields",
            layout: "custom",
            data: generateCustomFields("pet"),
        },
    ]

    const petProfileBookTemplate = [
        {
            heading: "General",
            layout: "text",
            data:
                props?.type === "pet"
                    ? [
                          {
                              key: headingMapper("bitActive", "standard", "pet"),
                              value: (
                                  <Chip
                                      size="small"
                                      color={
                                          (
                                              props.data?.customerPet != undefined && props.data?.customerPet.length > 0
                                                  ? props.data?.customerPet[props.index === undefined ? 0 : props.index].bitActive
                                                  : false
                                          )
                                              ? "success"
                                              : "error"
                                      }
                                      label={(props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].bitActive : false) ? "Active" : "Inactive"}
                                  />
                              ),
                          },
                          {
                              key: headingMapper("numPetTypeID", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetType : "",
                          },
                          {
                              key: "Breed type",
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetBreedType : "",
                          },
                          {
                              key: headingMapper("numPetBreedID", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetBreed : "",
                          },
                          {
                              key: "Primary breed",
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPrimaryBreed : "",
                          },
                          {
                              key: "Secondary breed",
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varSecondaryBreed : "",
                          },
                          {
                              key: headingMapper("varPetSex", "standard", "pet"),
                              value: (props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetSex : "") == "M" ? "Male" : "Female",
                          },
                          {
                              key: headingMapper("numPetColorID", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetColor : "",
                          },
                          {
                              key: headingMapper("numPetWeight", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetWeight : "",
                          },
                          {
                              key: headingMapper("varPetBirthDate", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? shortDate(props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetBirthDate) : "",
                          },
                          {
                              key: headingMapper("varPetAge", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetAge : "",
                          },
                          {
                              key: headingMapper("varPetSpay", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? (props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetSpay ? "Yes" : "No") : "",
                          },
                      ]
                    : [],
        },
        {
            heading: "Pet Description",
            layout: "text",
            data:
                props?.type === "pet"
                    ? [
                          {
                              key: headingMapper("varPetDescription", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetDescription : "",
                          },
                      ]
                    : [],
        },
        {
            heading: "Temperament test status",
            layout: "text",
            data:
                props?.type === "pet"
                    ? [
                          {
                              key: headingMapper("varTemperamentTestStatus", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varTemperamentTestStatus : "",
                          },
                          {
                              key: headingMapper("varTemperamentTestedLocation", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varTemperamentTestedLocation : "",
                          },
                          {
                              key: headingMapper("dtTemperamentTestedDate", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? shortDate(props.data?.customerPet[props.index === undefined ? 0 : props.index].dtTemperamentTestedDate) : "",
                          },
                      ]
                    : [],
        },
        {
            heading: "Personality",
            layout: "personality",
            data: props?.type === "pet" ? (props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].petPersonality : []) : [],
        },
        {
            heading: "Reference",
            layout: "text",
            data:
                props?.type === "pet"
                    ? [
                          {
                              key: headingMapper("numPetID", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetID : "",
                          },
                          {
                              key: headingMapper("varPetNumber", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetNumber : "",
                          },
                          {
                              key: headingMapper("varPetBC", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetBC : "",
                          },
                          {
                              key: headingMapper("varPetRFID", "standard", "pet"),
                              value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetRFID : "",
                          },
                      ]
                    : [],
        },
        {
            heading: "Veterinarian",
            layout: "text",
            data:
                props?.type === "pet"
                    ? [
                          {
                              key: "Name",
                              value:
                                  props.data?.customerPet.length > 0
                                      ? props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet != null
                                          ? props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet.varPetVetName
                                          : ""
                                      : "",
                          },
                          {
                              key: "Work",
                              value:
                                  props.data?.customerPet.length > 0
                                      ? props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet != null
                                          ? formatPhone(props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet.varPetVetPhone1)
                                          : ""
                                      : "",
                          },
                          {
                              key: "Mobile",
                              value:
                                  props.data?.customerPet.length > 0
                                      ? props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet != null
                                          ? formatPhone(props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet.varPetVvarPetVetPhone2etName)
                                          : ""
                                      : "",
                          },
                          {
                              key: "Email",
                              value:
                                  props.data?.customerPet.length > 0
                                      ? props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet != null
                                          ? props.data?.customerPet[props.index === undefined ? 0 : props.index].petVet.varPetVetEmail
                                          : ""
                                      : "",
                          },
                      ]
                    : [],
        },
        {
            heading: "Custom Fields",
            layout: "custom",
            data: generateCustomFields("pet"),
        },
    ]

    const salonTemplate = [
        {
            heading: "General",
            layout: "text",
            data: [
                {
                    key: headingMapper("varLocationName", "standard", "salon"),
                    value: props?.data?.varLocationName,
                },
                {
                    key: headingMapper("varLocationAddress1", "standard", "salon"),
                    value: props?.data?.varLocationAddress1,
                },
                {
                    key: headingMapper("varLocationAddress2", "standard", "salon"),
                    value: props?.data?.varLocationAddress2,
                },
                {
                    key: headingMapper("varLocationNeighborhood", "standard", "salon"),
                    value: props?.data?.varLocationNeighborhood,
                },
                {
                    key: headingMapper("varLocationCity", "standard", "salon"),
                    value: props?.data?.varLocationCity,
                },
                {
                    key: headingMapper("varLocationCountry", "standard", "salon"),
                    value: props?.data?.varLocationCountry,
                },
                {
                    key: headingMapper("varLocationPhone", "standard", "salon"),
                    value: formatPhone(props?.data?.varLocationPhone),
                },
                {
                    key: headingMapper("varLocationFax", "standard", "salon"),
                    value: formatPhone(props?.data?.varLocationFax),
                },
                {
                    key: headingMapper("varLocationEmail", "standard", "salon"),
                    value: props?.data?.varLocationEmail,
                },
            ],
        },
        {
            heading: "Geo Tagging",
            layout: "text",
            data: [
                {
                    key: headingMapper("varLocationName", "standard", "salon"),
                    value: (
                        <a target="_blank" href={`http://maps.google.com/maps?q=loc:${props?.data?.varLocationLatitude},${props?.data?.varLocationLongitude}`}>
                            Copy Link
                        </a>
                    ),
                },
                {
                    key: headingMapper("varLocationLatitude", "standard", "salon"),
                    value: props?.data?.varLocationLatitude,
                },
                {
                    key: headingMapper("varLocationLongitude", "standard", "salon"),
                    value: props?.data?.varLocationLongitude,
                },
                {
                    key: headingMapper("varLocationAltitude", "standard", "salon"),
                    value: props?.data?.varLocationAltitude,
                },
            ],
        },
        {
            heading: "Location Identification",
            layout: "text",
            data: [
                {
                    key: headingMapper("numLocationID", "standard", "salon"),
                    value: props?.data?.numLocationID,
                },
                {
                    key: "Barcode",
                    value: props?.data?.varLocationBC,
                },
                {
                    key: headingMapper("varLocationRFID", "standard", "salon"),
                    value: props?.data?.varLocationRFID,
                },
            ],
        },
        {
            heading: "Facilities",
            layout: "text",
            data: [
                {
                    key: "Grooming",
                    value: props?.data?.bitGroomingService ? <p style={{ margin: 0 }}>Yes</p> : <p style={{ margin: 0 }}>No</p>,
                },
                {
                    key: "Daycare",
                    value: props?.data?.numDayCareSlots === null ? "Slots N/A" : props?.data?.numDayCareSlots + " Slots",
                },
                {
                    key: "Boarding",
                    value: (
                        <div style={{ display: "flex", gap: ".5rem" }}>
                            <p>{props?.data?.numBoardingCages === null ? "Cages N/A" : props?.data?.numBoardingCages + " Cages"}</p>
                            <p> | </p>
                            <p>{props?.data?.numBoardingRuns === null ? "Runs N/A" : props?.data?.numBoardingRuns + " Runs"}</p>
                        </div>
                    ),
                },
            ],
        },
        {
            heading: "",
            layout: "map",
            data: [
                {
                    lat: parseFloat(props?.data?.varLocationLatitude),
                    lng: parseFloat(props?.data?.varLocationLongitude),
                    name: props?.data?.varLocationName,
                },
            ],
        },
    ]

    const salonTemplateEdit = [
        {
            layout: "imageUpload",
            data: [
                {
                    id: deepClone?.numLocationID,
                    key: "actual",
                    value: deepClone?.varLocationImagePath,
                },
            ],
        },
        {
            heading: "General",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationName", "standard", "salon"),
                            isRequired: headingMapper("varLocationName", "standard", "salon", "isRequired"),
                            key: "varLocationName",
                            value: deepClone?.varLocationName,
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationAddress1", "standard", "salon"),
                            isRequired: headingMapper("varLocationAddress1", "standard", "salon", "isRequired"),
                            key: "varLocationAddress1",
                            value: deepClone?.varLocationAddress1,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationAddress2", "standard", "salon"),
                            isRequired: headingMapper("varLocationAddress2", "standard", "salon", "isRequired"),
                            key: "varLocationAddress2",
                            value: deepClone?.varLocationAddress2,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationNeighborhood", "standard", "salon"),
                            isRequired: headingMapper("varLocationNeighborhood", "standard", "salon", "isRequired"),
                            key: "varLocationNeighborhood",
                            value: deepClone?.varLocationNeighborhood,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationCity", "standard", "salon"),
                            isRequired: headingMapper("varLocationCity", "standard", "salon", "isRequired"),
                            key: "varLocationCity",
                            value: deepClone?.varLocationCity,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationCounty", "standard", "salon"),
                            isRequired: headingMapper("varLocationCounty", "standard", "salon", "isRequired"),
                            key: "varLocationCounty",
                            value: deepClone?.varLocationCounty,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationState", "standard", "salon"),
                            isRequired: headingMapper("varLocationState", "standard", "salon", "isRequired"),
                            key: "varLocationState",
                            value: deepClone?.varLocationState,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "zip",
                            label: headingMapper("varLocationZip", "standard", "salon"),
                            isRequired: headingMapper("varLocationZip", "standard", "salon", "isRequired"),
                            key: "varLocationZip",
                            value: deepClone?.varLocationZip,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationCountry", "standard", "salon"),
                            isRequired: headingMapper("varLocationCountry", "standard", "salon", "isRequired"),
                            key: "varLocationCountry",
                            value: deepClone?.varLocationCountry,
                        },
                    ],
                },
            ],
        },
        {
            heading: "",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varLocationPhone", "standard", "salon"),
                            isRequired: headingMapper("varLocationPhone", "standard", "salon", "isRequired"),
                            key: "varLocationPhone",
                            value: formatPhone(deepClone?.varLocationPhone),
                        },
                        {
                            type: "text",
                            inputType: "tel",
                            label: headingMapper("varLocationFax", "standard", "salon"),
                            isRequired: headingMapper("varLocationFax", "standard", "salon", "isRequired"),
                            key: "varLocationFax",
                            value: formatPhone(deepClone?.varLocationFax),
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationEmail", "standard", "salon"),
                            isRequired: headingMapper("varLocationEmail", "standard", "salon", "isRequired"),
                            key: "varLocationEmail",
                            value: deepClone?.varLocationEmail,
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Geo Tagging",
            layout: "mapSelect",
            data: [
                {
                    column: [
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationGoogleMapAddress", "standard", "salon"),
                            isRequired: headingMapper("varLocationGoogleMapAddress", "standard", "salon", "isRequired"),
                            key: "varLocationGoogleMapAddress",
                            value: deepClone?.varLocationGoogleMapAddress,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationLatitude", "standard", "salon"),
                            isRequired: headingMapper("varLocationLatitude", "standard", "salon", "isRequired"),
                            key: "varLocationLatitude",
                            value: deepClone?.varLocationLatitude,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationLongitude", "standard", "salon"),
                            isRequired: headingMapper("varLocationLongitude", "standard", "salon", "isRequired"),
                            key: "varLocationLongitude",
                            value: deepClone?.varLocationLongitude,
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varLocationAltitude", "standard", "salon"),
                            isRequired: headingMapper("varLocationAltitude", "standard", "salon", "isRequired"),
                            key: "varLocationAltitude",
                            value: deepClone?.varLocationAltitude,
                        },
                    ],
                },
            ],
        },
        {
            heading: "Facilities",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "text",
                            inputType: "number",
                            label: headingMapper("numDayCareSlots", "standard", "salon"),
                            isRequired: headingMapper("numDayCareSlots", "standard", "salon", "isRequired"),
                            key: "numDayCareSlots",
                            value: deepClone?.numDayCareSlots,
                        },
                        {
                            type: "text",
                            inputType: "number",
                            label: headingMapper("numBoardingCages", "standard", "salon"),
                            isRequired: headingMapper("numBoardingCages", "standard", "salon", "isRequired"),
                            key: "numBoardingCages",
                            value: deepClone?.numBoardingCages,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "text",
                            inputType: "number",
                            label: headingMapper("numBoardingRuns", "standard", "salon"),
                            isRequired: headingMapper("numBoardingRuns", "standard", "salon", "isRequired"),
                            key: "numBoardingRuns",
                            value: deepClone?.numBoardingRuns,
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Services",
            layout: "checkboxes",
            data: [
                {
                    row: [
                        {
                            label: headingMapper("bitGroomingService", "standard", "salon"),
                            key: "bitGroomingService",
                            value: deepClone?.bitGroomingService,
                        },
                        {
                            label: headingMapper("bitBoardingService", "standard", "salon"),
                            key: "bitBoardingService",
                            value: deepClone?.bitBoardingService,
                        },
                        {
                            label: headingMapper("bitDaycareService", "standard", "salon"),
                            key: "bitDaycareService",
                            value: deepClone?.bitDaycareService,
                        },
                        {
                            label: headingMapper("bitSittingService", "standard", "salon"),
                            key: "bitSittingService",
                            value: deepClone?.bitSittingService,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: headingMapper("bitTrainingService", "standard", "salon"),
                            key: "bitTrainingService",
                            value: deepClone?.bitTrainingService,
                        },
                        {
                            label: headingMapper("bitMobileGroomingService", "standard", "salon"),
                            key: "bitMobileGroomingService",
                            value: deepClone?.bitMobileGroomingService,
                        },
                        {
                            label: headingMapper("bitStoreProductService", "standard", "salon"),
                            key: "bitStoreProductService",
                            value: deepClone?.bitStoreProductService,
                        },
                    ],
                },
            ],
        },
        {
            heading: "Pet types",
            layout: "externalCheckboxes",
            data: deepClone?.locationPetType,
        },
        {
            heading: "Dynamic list",
            layout: "dynamic",
            data: generateCustomFields("salon"),
        },
    ]

    const petProfileTemplateEdit = [
        {
            layout: "imageUpload",
            data: [
                {
                    id: deepClone?.varPetImagePath,
                    key: "actual",
                    value: deepClone?.varPetImagePath,
                },
            ],
        },
        {
            heading: "",
            layout: "main",
            data: [
                {
                    label: headingMapper("varPetName", "standard", "pet"),
                    isRequired: headingMapper("varPetName", "standard", "pet", "isRequired"),
                    key: "varPetName",
                    value: deepClone?.varPetName,
                },
                {
                    label: headingMapper("varPetDescription", "standard", "pet"),
                    isRequired: headingMapper("varPetDescription", "standard", "pet", "isRequired"),
                    key: "varPetDescription",
                    value: deepClone?.varPetDescription,
                },
            ],
        },
        // {
        //     heading: '',
        //     layout: 'breedType',
        //     data: [
        //         {
        //             label: headingMapper('varPetName', 'standard', 'pet'),
        //             isRequired: headingMapper('varPetName', 'standard', 'pet', "isRequired"),
        //             key: 'varPetName',
        //             value: deepClone?.varPetName
        //         },
        //         {
        //             label: headingMapper('varPetDescription', 'standard', 'pet'),
        //             isRequired: headingMapper('varPetDescription', 'standard', 'pet', "isRequired"),
        //             key: 'varPetDescription',
        //             value: deepClone?.varPetDescription
        //         }
        //     ]
        // },
        {
            heading: "General",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numPetTypeID", "standard", "pet"),
                            key: "numPetTypeID",
                            value: deepClone?.numPetTypeID,
                            objectName: "PetType",
                            isRequired: headingMapper("numPetTypeID", "standard", "pet", "isRequired"),
                            isCustom: false,
                            sequence: "",
                            isButton: isActive(33),
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Breed",
            layout: "breeds",
            data: [],
        },
        {
            heading: "",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advancedCustom",
                            label: headingMapper("varPetSex", "standard", "pet"),
                            key: "varPetSex",
                            isRequired: headingMapper("numPetTypeID", "standard", "pet", "isRequired"),
                            value: deepClone?.varPetSex,
                            dataList: [
                                {
                                    value: "M",
                                    text: "Male",
                                },
                                {
                                    value: "F",
                                    text: "Female",
                                },
                            ],
                        },
                        {
                            type: "advanced",
                            label: headingMapper("numPetColorID", "standard", "pet"),
                            key: "numPetColorID",
                            value: deepClone?.numPetColorID,
                            objectName: "PetColor",
                            isRequired: headingMapper("numPetColorID", "standard", "pet", "isRequired"),
                            isCustom: false,
                            sequence: "",
                            isButton: isActive(34),
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numPetSizeID", "standard", "pet"),
                            key: "numPetSizeID",
                            value: deepClone?.numPetSizeID,
                            objectName: "PetSize",
                            isRequired: headingMapper("numPetSizeID", "standard", "pet", "isRequired"),
                            isCustom: false,
                            sequence: "",
                            isButton: isActive(35),
                        },
                        {
                            type: "text",
                            inputType: "false",
                            label: headingMapper("numPetWeight", "standard", "pet"),
                            isRequired: headingMapper("numPetWeight", "standard", "pet", "isRequired"),
                            key: "numPetWeight",
                            value: deepClone?.numPetWeight,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "date",
                            inputType: "date",
                            label: headingMapper("varPetBirthDate", "standard", "pet"),
                            isRequired: headingMapper("varPetBirthDate", "standard", "pet", "isRequired"),
                            key: "varPetBirthDate",
                            value: shortDate(deepClone?.varPetBirthDate),
                        },
                        {
                            type: "text",
                            inputType: "false",
                            label: headingMapper("varPetAge", "standard", "pet"),
                            isRequired: headingMapper("varPetAge", "standard", "pet", "isRequired"),
                            key: "varPetAge",
                            value: deepClone?.varPetAge,
                        },
                    ],
                },
            ],
        },
        {
            heading: "",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advancedCustom",
                            label: headingMapper("varPetSpay", "standard", "pet"),
                            key: "varPetSpay",
                            isRequired: headingMapper("varPetSpay", "standard", "pet", "isRequired"),
                            value: deepClone?.varPetSpay,
                            dataList: [
                                {
                                    value: true,
                                    text: "Yes",
                                },
                                {
                                    value: false,
                                    text: "No",
                                },
                            ],
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Personality",
            layout: "personality",
            data: deepClone?.petPersonality,
        },
        {
            heading: "Temperament test status",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advancedCustom",
                            label: headingMapper("varTemperamentTestStatus", "standard", "pet"),
                            key: "varTemperamentTestStatus",
                            value: deepClone?.varTemperamentTestStatus,
                            isRequired: headingMapper("varTemperamentTestStatus", "standard", "pet", "isRequired"),
                            dataList: [
                                {
                                    value: "Passed",
                                    text: "Passed",
                                },
                                {
                                    value: "Not Passed",
                                    text: "Not Passed",
                                },
                            ],
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varTemperamentTestedLocation", "standard", "pet"),
                            isRequired: headingMapper("varTemperamentTestedLocation", "standard", "pet", "isRequired"),
                            key: "varTemperamentTestedLocation",
                            value: deepClone?.varTemperamentTestedLocation,
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "date",
                            inputType: "date",
                            label: headingMapper("dtTemperamentTestedDate", "standard", "pet"),
                            isRequired: headingMapper("dtTemperamentTestedDate", "standard", "pet", "isRequired"),
                            key: "dtTemperamentTestedDate",
                            value: shortDate(deepClone?.dtTemperamentTestedDate),
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Veterinarian",
            layout: "vet",
            data: {
                main: {
                    type: "advanced",
                    label: headingMapper("numPetVetID", "standard", "pet"),
                    key: "numPetVetID",
                    value: deepClone?.numPetVetID,
                    objectName: "PetVet",
                    isRequired: headingMapper("numPetVetID", "standard", "pet", "isRequired"),
                    isCustom: false,
                    sequence: "",
                    isButton: isActive(37),
                },
                sub: [
                    {
                        type: "customText",
                        inputType: "text",
                        label: "Work",
                        isRequired: false,
                        value: formatPhone(deepClone?.petVet?.varPetVetPhone1),
                    },
                    {
                        type: "customText",
                        inputType: "text",
                        label: "Mobile",
                        isRequired: false,
                        value: formatPhone(deepClone?.petVet?.vraPetVetPhone2),
                    },
                    {
                        type: "customText",
                        inputType: "email",
                        label: "Email",
                        isRequired: false,
                        value: deepClone?.petVet?.varPetVetEmail,
                    },
                ],
            },
        },
        {
            heading: "Dynamic list",
            layout: "dynamic",
            data: generateCustomFields("pet"),
        },
    ]

    const petProfileTemplateBookEdit = [
        {
            layout: "imageUpload",
            data: [
                {
                    id: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetImagePath : "",
                    key: "actual",
                    value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetImagePath : "",
                },
            ],
        },
        {
            heading: "",
            layout: "main",
            data: [
                {
                    label: headingMapper("varPetName", "standard", "pet"),
                    isRequired: headingMapper("varPetName", "standard", "pet", "isRequired"),
                    key: "varPetName",
                    value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetName : "",
                },
                {
                    label: headingMapper("varPetDescription", "standard", "pet"),
                    isRequired: headingMapper("varPetDescription", "standard", "pet", "isRequired"),
                    key: "varPetDescription",
                    value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetDescription : "",
                },
            ],
        },

        {
            heading: "General",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numPetTypeID", "standard", "pet"),
                            key: "numPetTypeID",
                            value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.numPetTypeID : "",
                            objectName: "PetType",
                            isRequired: headingMapper("numPetTypeID", "standard", "pet", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Breed",
            layout: "breeds",
            data: [],
        },
        {
            heading: "",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advancedCustom",
                            label: headingMapper("varPetSex", "standard", "pet"),
                            key: "varPetSex",
                            isRequired: headingMapper("varPetSex", "standard", "pet", "isRequired"),
                            value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetSex : "",
                            dataList: [
                                {
                                    value: "M",
                                    text: "Male",
                                },
                                {
                                    value: "F",
                                    text: "Female",
                                },
                            ],
                        },
                        {
                            type: "advanced",
                            label: headingMapper("numPetColorID", "standard", "pet"),
                            key: "numPetColorID",
                            value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.numPetColorID : "",
                            objectName: "PetColor",
                            isRequired: headingMapper("numPetColorID", "standard", "pet", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "advanced",
                            label: headingMapper("numPetSizeID", "standard", "pet"),
                            key: "numPetSizeID",
                            value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.numPetSizeID : "",
                            objectName: "PetSize",
                            isRequired: headingMapper("numPetSizeID", "standard", "pet", "isRequired"),
                            isCustom: false,
                            sequence: "",
                        },
                        {
                            type: "text",
                            inputType: "false",
                            label: headingMapper("numPetWeight", "standard", "pet"),
                            isRequired: headingMapper("numPetWeight", "standard", "pet", "isRequired"),
                            key: "numPetWeight",
                            value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.numPetWeight : "",
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "date",
                            inputType: "date",
                            label: headingMapper("varPetBirthDate", "standard", "pet"),
                            isRequired: headingMapper("varPetBirthDate", "standard", "pet", "isRequired"),
                            key: "varPetBirthDate",
                            value:
                                deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0
                                    ? shortDate(deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetBirthDate)
                                    : "",
                        },
                        {
                            type: "text",
                            inputType: "number",
                            label: headingMapper("varPetAge", "standard", "pet"),
                            isRequired: headingMapper("varPetAge", "standard", "pet", "isRequired"),
                            key: "varPetAge",
                            value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetAge : "",
                        },
                    ],
                },
            ],
        },
        {
            heading: "",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advancedCustom",
                            label: headingMapper("varPetSpay", "standard", "pet"),
                            key: "varPetSpay",
                            isRequired: headingMapper("varPetSpay", "standard", "pet", "isRequired"),
                            value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varPetSpay : "",
                            dataList: [
                                {
                                    value: true,
                                    text: "Yes",
                                },
                                {
                                    value: false,
                                    text: "No",
                                },
                            ],
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Personality",
            layout: "personality",
            data: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.petPersonality : "",
        },
        {
            heading: "Temperament test status",
            layout: "basic",
            data: [
                {
                    row: [
                        {
                            type: "advancedCustom",
                            label: headingMapper("varTemperamentTestStatus", "standard", "pet"),
                            key: "varTemperamentTestStatus",
                            isRequired: headingMapper("varTemperamentTestStatus", "standard", "pet", "isRequired"),
                            value:
                                deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0
                                    ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varTemperamentTestStatus
                                    : "",
                            dataList: [
                                {
                                    value: "Passed",
                                    text: "Passed",
                                },
                                {
                                    value: "Not Passed",
                                    text: "Not Passed",
                                },
                            ],
                        },
                        {
                            type: "text",
                            inputType: "text",
                            label: headingMapper("varTemperamentTestedLocation", "standard", "pet"),
                            isRequired: headingMapper("varTemperamentTestedLocation", "standard", "pet", "isRequired"),
                            key: "varTemperamentTestedLocation",
                            value:
                                deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0
                                    ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.varTemperamentTestedLocation
                                    : "",
                        },
                    ],
                },
                {
                    row: [
                        {
                            type: "date",
                            inputType: "date",
                            label: headingMapper("dtTemperamentTestedDate", "standard", "pet"),
                            isRequired: headingMapper("dtTemperamentTestedDate", "standard", "pet", "isRequired"),
                            key: "dtTemperamentTestedDate",
                            value:
                                deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0
                                    ? shortDate(deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.dtTemperamentTestedDate)
                                    : "",
                        },
                        {
                            type: "blank",
                        },
                    ],
                },
            ],
        },
        {
            heading: "Veterinarian",
            layout: "vet",
            data: {
                main: {
                    type: "advanced",
                    label: headingMapper("numPetVetID", "standard", "pet"),
                    key: "numPetVetID",
                    value: deepClone?.customerPet != undefined && deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index]?.numPetVetID : "",
                    objectName: "PetVet",
                    isRequired: headingMapper("numPetVetID", "standard", "pet", "isRequired"),
                    isCustom: false,
                    sequence: "",
                },
                sub: [
                    {
                        type: "customText",
                        inputType: "text",
                        label: "Work",
                        isRequired: false,
                        value: formatPhone(deepClone?.petVet?.varPetVetPhone1),
                    },
                    {
                        type: "customText",
                        inputType: "text",
                        label: "Mobile",
                        isRequired: false,
                        value: formatPhone(deepClone?.petVet?.vraPetVetPhone2),
                    },
                    {
                        type: "customText",
                        inputType: "email",
                        label: "Email",
                        isRequired: false,
                        value: deepClone?.petVet?.varPetVetEmail,
                    },
                ],
            },
        },
        {
            heading: "Dynamic list",
            layout: "dynamic",
            data: generateCustomFields("pet"),
        },
    ]

    return (
        <>
            {props.type === "customer" ? (
                props?.mode ? (
                    <EditCustomer customerTemplate={customerTemplateEdit} data={deepClone} />
                ) : (
                    <DisplayCustomer customerTemplate={customerTemplate} />
                )
            ) : props.type === "staff" ? (
                props?.mode ? (
                    <EditEmployee employeeTemplate={staffTemplateEdit} data={deepClone} />
                ) : (
                    <DisplayEmployee employeeTemplate={staffTemplate} />
                )
            ) : props.type === "pet" ? (
                props?.mode ? (
                    <EditPetProfile
                        petProfileTemplate={petProfileTemplateBookEdit}
                        data={deepClone?.customerPet.length > 0 ? deepClone?.customerPet[props.index === undefined ? 0 : props.index] : ""}
                    />
                ) : (
                    <DisplayPetProfile petProfileTemplate={petProfileBookTemplate} />
                )
            ) : // petTemplate.map(section => {
            //     return <>
            //         <p className={styles.heading}>{section.heading}</p>

            //         {
            //             (section.layout == 'text') ?
            //                 <table cellSpacing="0" className={styles.table}>
            //                 {
            //                     section.data.map(pairs => {
            //                         return <tr>
            //                             {
            //                                 (pairs.key === '') ?
            //                                     null
            //                                 :
            //                                     <td>{pairs.key}</td>
            //                             }
            //                             <td>{pairs.value}</td>
            //                         </tr>

            //                     })
            //                 }
            //                 </table>
            //             : null
            //         }

            //         <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>
            //     </>
            // })
            props.type === "petProfile" ? (
                props?.mode ? (
                    <EditPetProfile petProfileTemplate={petProfileTemplateEdit} data={deepClone} />
                ) : (
                    <DisplayPetProfile petProfileTemplate={petProfileTemplate} />
                )
            ) : props.type === "salon" ? (
                props?.mode ? (
                    <EditLocation locationTemplate={salonTemplateEdit} data={deepClone} />
                ) : (
                    <DisplayLocation locationTemplate={salonTemplate} />
                )
            ) : null}
        </>
    )
}

export default PersonalDetails
